import { SbBlokData, storyblokEditable } from "@storyblok/js"

import { BlokImage } from "../.."
import Markdown from "../../../components/content/Markdown"
import PrimaryCard from "../../../components/cards/PrimaryCard"

interface IOurPromise extends SbBlokData {
  header: string
  markdown: string
  blocks: Array<
    {
      icon: BlokImage
      header: string
      markdown: string
    } & SbBlokData
  >
  customClasses: string
}

export default function OurPromise({ blok }: { blok: IOurPromise }) {
  const { header, markdown, blocks, customClasses } = blok

  return (
    <section className="page-padding" id="our-promise">
      <article
        className={`py-14 px-4 md:px-12 lg:px-20 xl:px-32 flex flex-col items-center text-left lg:text-center bg-gray3 ${customClasses}`}
      >
        <h3 className="font-montserrat font-normal text-4xl mb-6">{header}</h3>
        <Markdown className="mb-6">{markdown}</Markdown>
        <section className="grid grid-cols-1 lg:grid-cols-2 xl:px-20 gap-y-5">
          {blocks &&
            blocks.map(({ icon, header, markdown }, i) => (
              <PrimaryCard
                icon={icon}
                markdown={markdown}
                header={header}
                key={`ourPromise/${i}`}
                className="prose-h4:text-2xl prose-h4:mb-1 prose-p:text-base text-left bg-transparent p-0 lg:p-4"
              />
            ))}
        </section>
      </article>
    </section>
  )
}
