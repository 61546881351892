import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import ShippingForm from "../../components/form/ShippingForm"
import { BlokImage } from ".."

export interface ICalculator extends SbBlokData {
  heading: string
  details: string
  imageAltText: string
  buttonLabel?: string
  image?: BlokImage
}

export default function Calculator({ blok }: { blok: ICalculator }) {
  const { heading, details, imageAltText, buttonLabel, image } = blok

  return (
    <section
      className="relative page-padding py-16 w-full grid grid-cols-1 md:grid-cols-2 auto-rows-auto gap-x-16 bg-orange1"
      {...storyblokEditable(blok)}
    >
      <article>
        <h3 className="relative mb-6 block font-montserrat font-medium text-blue7 text-4xl text-left align-bottom">
          {heading}
        </h3>
        <p className="mb-5">{details}</p>
        <Image
          className="w-full h-auto mb-8 rounded-lg"
          src={image?.filename ?? "/images/calculator.jpg"}
          alt={image?.alt ?? imageAltText}
          height={201}
          width={338}
        />
      </article>
      <ShippingForm
        section="calculator"
        className="h-fit my-auto px-7 py-11 rounded-lg flex flex-col gap-4 bg-white drop-shadow-xl"
        buttonLabel={buttonLabel}
      />
    </section>
  )
}
