import React from "react"
import { useRouter } from "next/router"
import Image from "next/image"
import PageNav, { NavigationType } from "../../storyblok/sections/PageNav"
import { BlokImage } from "../../storyblok/types"
import ShippingForm from "../../components/form/ShippingForm"
import RatingCard from "../../components/review-rating-card"
import Breadcrumb, { BreadcrumbItem } from "../../components/breadcrumbs"

import OverlayCard, {
  OverlayReview,
} from "../../components/header-overlay-card"
import HeroStats, { IHeroStatsProps } from "../../components/hero-stats"
import { readImage } from "../../app/utils/read-image"

export interface PageHeader2Props {
  heading: string
  details: string
  image: BlokImage
  smBgImage: BlokImage
  buttonLabel: string
  hasSelectCategory?: boolean
  hasPickupInput?: boolean
  hasDeliveryInput?: boolean
  selectCategoryPlaceholder?: string
  buttonLink?: string
  categoryOptions: { label: string; value: string }[]
  unsubscribeText?: string
  caption1?: string
  caption2?: string
  navItems: NavigationType["navItems"]
  categories: NavigationType["categories"]
  isNavHidden?: boolean
  ctaRoute?: string
  items?: BreadcrumbItem[]
  feedback?: string
  overlayReview?: OverlayReview[]
  statsHeading?: string
  stats: IHeroStatsProps["stats"]
}

const PageHeader2 = ({ blok }: { blok: PageHeader2Props }) => {
  const { query } = useRouter()
  const param = (query?.kw as string) || ""

  const {
    heading,
    details,
    image,
    buttonLabel,
    hasSelectCategory,
    hasPickupInput,
    hasDeliveryInput,
    categoryOptions,
    selectCategoryPlaceholder = "What are you shipping",
    buttonLink,
    unsubscribeText,
    navItems,
    categories,
    isNavHidden,
    ctaRoute,
    items,
    feedback = "What our users say",
    overlayReview,
    smBgImage,
    statsHeading,
    stats,
  } = blok

  const backgroundStyle = {
    backgroundImage:
      "linear-gradient(125.43deg, rgba(236, 242, 255, 0) 9.01%, #EBF1FC 53.54%)",
  }

  const CompanyStats = () => {
    return (
      <section id="stats" className="px-8 md:p-8 m-auto flex flex-col">
        <h3 className="mb-6 md:mb-12 text-lg lg:text-[28px] font-montserrat font-medium lg:text-center text-gray14">
          {statsHeading}
        </h3>

        <div>
          <HeroStats stats={stats} />
        </div>
      </section>
    )
  }
  const HeroForm = () => {
    return (
      <div className="xl:max-w-[1400px] m-auto">
        <article className="relative w-full md:max-w-[546px] z-10 mt-16 mx-auto lg:mx-0  md:mt-0 lg:pt-16">
          {items && <Breadcrumb items={items} />}
          <h1 className="font-montserrat text-[27px] leading-[40px] md:text-5xl text-white lg:text-blue7 md:font-semibold md:font-montserrat">
            {param ? param : heading}
          </h1>
          <h2 className="md:max-w-[455px] text-lg mb-4 md:mb-6 md:text-2xl font-normal text-white lg:text-gray14">
            {details}
          </h2>
          <ShippingForm
            section="pageHeader"
            hasSelectCategory={hasSelectCategory}
            hasPickupInput={hasPickupInput}
            hasDeliveryInput={hasDeliveryInput}
            categoryOptions={categoryOptions}
            selectCategoryPlaceholder={selectCategoryPlaceholder}
            buttonLink={buttonLink}
            buttonLabel={buttonLabel}
            unsubscribeText={unsubscribeText}
            variant="overlay"
          />
          <aside className="mt-3 sm:mt-6">
            <RatingCard />
          </aside>
        </article>
      </div>
    )
  }
  return (
    <>
      <PageNav
        noBlok
        route={ctaRoute || buttonLink}
        buttonLabel={buttonLabel}
        navigationData={{ navItems, categories, isNavHidden }}
      />
      <section id="mobile" className="block lg:hidden">
        <div className="xs:h-[720px] sm:h-[750px] md:h-[72vh] lg:h-[88vh] relative">
          <div className="lg:hidden absolute inset-0 bg-[#000] opacity-70 z-10 xs:h-[720px] sm:h-[750px] md:h-[72vh] lg:h-[88vh]" />
          <Image
            src={readImage(smBgImage)}
            alt={smBgImage.alt}
            fill
            objectFit="cover"
            objectPosition="center"
            layout="fill"
            loading="eager"
          />

          <div className="mx-4 sm:mx-6 pb-4 pt-10 sm:pt-20 md:pt-24">
            <HeroForm />
          </div>
        </div>
        <div className="p-8">
          <p className="font-montserrat m-0 font-medium text-gray14 text-lg">
            {feedback}
          </p>
          <div dir="ltr">
            <OverlayCard
              overlayReview={overlayReview}
              className="snap-x scroll-ps-6 overflow-x-auto pb-10"
            />
          </div>
        </div>
        <CompanyStats />
      </section>
      <section id="desktop" className="hidden lg:block">
        <div
          style={backgroundStyle}
          className={`bg-cover bg-no-repeat bg-center relative w-full h-[90vh] flex`}
        >
          <div className="lg:w-1/2 m-auto flex">
            <HeroForm />
          </div>
          <div className="lg:w-1/2 relative">
            <div className="relative  h-full">
              <Image
                src={readImage(image)}
                alt={image.alt}
                fill
                objectFit="cover"
                objectPosition="center"
                layout="fill"
              />
            </div>
            <OverlayCard feedback={feedback} overlayReview={overlayReview} />
          </div>
        </div>
        <div className="max-w-[1400px] m-auto">
          <CompanyStats />
        </div>
      </section>
    </>
  )
}

export default PageHeader2
