import classNames from "classnames"
import { SbBlokData } from "@storyblok/react"
import { createUseStyles } from "react-jss"
import { marked } from "marked"

import BlockQuoteIcon from "../../../assets/icons/blockquote.svg"
import ListIcon from "../../../assets/icons/list-style-arrow.svg"

import { mediaQueries } from "../../../styles/breakpoints"

export interface IPillarsArticle extends SbBlokData {
  header: string
  markdown: string
  headerClasses?: string
  id?: string
}
const useStyles = createUseStyles(
  {
    container: {
      maxWidth: "1300px",
      margin: "auto",
      padding: "3.5rem 2rem 1.5rem",
      [mediaQueries.tablet]: {
        padding: "1.5rem 2rem",
      },
    },
    article: {
      "& h2": {
        lineHeight: 1.2,
      },
      "& h3": {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: 1.25,
        marginBottom: 32,
        [mediaQueries.desktop]: {
          lineHeight: 1.2,
        },
      },
      "& h4": {
        fontWeight: 400,
        fontSize: 32,
        lineHeight: 1.25,
        marginBottom: 32,
        [mediaQueries.desktop]: {
          lineHeight: 0.842,
          margin: "40px 0 24px",
        },
      },
      "& h5": {
        fontWeight: 400,
        fontSize: 24,
        lineHeight: 1.25,
        margin: "40px 0 24px",
      },
      "& ul": {
        marginLeft: 15,
        [mediaQueries.tablet]: {
          marginLeft: 40,
        },
      },
      "& li": {
        marginBottom: 16,
      },
      "& li:before": {
        content: `url(${ListIcon.src})`,
        top: 6,
        position: "relative",
        left: -36,
        marginRight: -24,
      },
      "& p": {
        fontSize: 16,
        lineHeight: 1.5,
        "& img": {
          borderRadius: 6,
          width: "100%",
          margin: "40px auto",
        },
      },
      "& blockquote": {
        margin: "40px auto",
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.5,
        maxWidth: 300,
        [mediaQueries.desktop]: {
          maxWidth: 424,
        },
      },
      "& blockquote:before": {
        content: `url(${BlockQuoteIcon.src})`,
        top: 16,
        left: -30,
        position: "relative",
      },
      "& a": {
        color: "#2268DE",
      },
      "& a:hover": {
        textDecoration: "underline",
      },
    },
  },
  { link: true },
)

export default function PillarsArticle({ blok }: { blok: IPillarsArticle }) {
  const { header, markdown, headerClasses, id } = blok
  const article = marked.parse(markdown)
  const classes = useStyles()
  return (
    <section
      id={id}
      className={classNames("w-full md:px-12 lg:px-20", classes.container)}
    >
      {header && <h3 className={headerClasses}>{header}</h3>}
      {markdown && (
        <article
          className={classNames("md:px-40 xl:px-52", classes.article)}
          dangerouslySetInnerHTML={{ __html: article }}
        />
      )}
    </section>
  )
}
