import { SbBlokData } from "@storyblok/react"
import { HTMLAttributes, FC } from "react"
import BreadcrumbComponent from "../../components/breadcrumbs"
import { BreadcrumbItem as BreadcrumbItemType } from "../../components/breadcrumbs"

export interface IBreadcrumb extends SbBlokData {
  items: BreadcrumbItemType[]
}
export type BreadcrumbItemProps = BreadcrumbItemType &
  HTMLAttributes<HTMLElement>

export const BreadcrumbItem: FC<BreadcrumbItemProps> = (props) => {
  return <div {...props} />
}

const Breadcrumb = ({ blok }: { blok: IBreadcrumb }) => {
  const { items } = blok

  return <BreadcrumbComponent items={items} />
}

export default Breadcrumb
