import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { AiOutlineCheckCircle } from "react-icons/ai"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import Details from "../../../components/content/Details"
import Markdown from "../../../components/content/Markdown"

interface IPreparingForCarDelivery extends SbBlokData {
  markdown: string
  details: {
    summary: string
    content: string
  }[]
}

export default function PreparingForCarDelivery({
  blok,
}: {
  blok: IPreparingForCarDelivery
}) {
  const { markdown, details } = blok

  return (
    <section
      className="relative page-padding w-full flex flex-col lg:flex-row gap-20 bg-gray3"
      {...storyblokEditable(blok)}
    >
      <Image
        className="h-fit w-full max-w-xs mx-auto lg:max-w-none lg:w-1/3"
        src="/images/preparing-for-car-delivery.webp"
        alt="Car delivery - Car movers handover keys after shipping a car"
        height={376}
        width={300}
      />
      <article className="lg:w-2/3">
        <h3 className="mb-6 font-montserrat text-blue7 text-4xl">
          Preparing for your car delivery
        </h3>
        <p className="mb-12 text-2xl">
          While the transporter will handle most aspects of the delivery
          process, you need to prepare your vehicle for the trip. Here is a
          checklist to use to prepare for shipping a car.
        </p>
        <ul className="p-0 text-xl flex flex-col gap-6">
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Remove all personal belongings from the vehicle. Most car delivery
            services don’t allow you to put luggage or other items in the car
            during transport.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Clean the interior of the car and wash the exterior.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Check the tire pressure.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Disable the car alarm.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Don’t fill the gas tank because it adds weight. However, there
            should be around a quarter tank of gas in the car.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Evaluate your car for any leaks; if you have any, try to fix them.
            If they can’t be fixed, be sure to make a note and tell the
            transporter.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Take pictures of your car before pick up — if there’s any damage
            during the trip you’ll need these photos to prove fault.
          </li>
        </ul>
      </article>
    </section>
  )
}
