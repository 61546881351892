import Image from "next/image"
import { HTMLAttributes } from "react"
import { BlokImage } from "../../storyblok"
import Markdown from "../content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IPlainCardProps extends HTMLAttributes<HTMLElement> {
  markdown: string
  icon?: BlokImage
}

export default function PlainCard({
  markdown,
  icon,
  className,
  ...props
}: IPlainCardProps) {
  return (
    <article
      className={
        "p-6 sm:p-8 rounded-xl bg-white text-black text-sm drop-shadow-[0_2px_8px_rgba(0,0,0,0.05)] " +
        className
      }
      {...props}
    >
      {icon?.id ? (
        <Image
          className="w-9 h-9 mb-10 rounded-full"
          src={readImage(icon)}
          alt={icon.name}
          height={72}
          width={72}
        />
      ) : null}
      <Markdown className="max-w-none prose-headings:font-montserrat prose-h4:text-xl prose-h4:text-black prose-h4:font-semibold">
        {markdown}
      </Markdown>
    </article>
  )
}
