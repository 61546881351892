import * as React from "react"
import Image from "next/image"
import AssuranceGuaranteeSVG from "../assets/icons/assurance-quarantee.svg"

export const AssuranceGuarantee = () => {
  return (
    <div className="relative pl-16">
      <span className="w-14 h-14 absolute left-0 top-0">
        <Image
          src={AssuranceGuaranteeSVG}
          alt="assurance guarantee"
          width={56}
          height={56}
        />
      </span>
      <div className="text-base xl:text-lg">
        <p className="text-neutral-600">All transports are covered under our</p>
        {/*  eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          target="_blank"
          href="https://help.citizenshipper.com/hc/en-us/articles/360041203251-CitizenShipper-Booking-Assurance-Guarantee"
          className="text-primary-500 font-semibold hover:underline"
        >
          Booking Assurance Guarantee.
        </a>
      </div>
    </div>
  )
}
