export const includesAll = (arr, props) => {
  let result = true

  for (const prop of props) {
    if (!arr.includes(prop)) {
      result = false
      break
    }
  }

  return result
}

export const shuffleArray = (array) => {
  const arr = array.slice()
  for (let i = arr.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    ;[arr[i], arr[j]] = [arr[j], arr[i]]
  }
  return arr
}
