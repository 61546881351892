import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from "../.."
import PrimaryCard, {
  IPrimaryCardProps,
} from "../../../components/cards/PrimaryCard"
import { readImage } from "../../../app/utils/read-image"

interface ICheapestAutoTransport extends SbBlokData {
  heading: string
  details: string
  image?: BlokImage
  cards: Array<IPrimaryCardProps & SbBlokData>
}

export default function CheapestAutoTransport({
  blok,
}: {
  blok: ICheapestAutoTransport
}) {
  const { heading, details, image, cards } = blok

  return (
    <section
      className="relative page-padding w-full flex flex-col bg-gray11"
      {...storyblokEditable(blok)}
    >
      <h3 className="mb-6 font-montserrat text-blue7 text-4xl text-center">
        {heading}
      </h3>
      <p className="text-xl text-center">{details}</p>

      <div className="relative my-20 mx-auto h-96 w-full lg:w-1/2">
        {image && (
          <Image
            className="object-cover rounded-lg"
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
          />
        )}
      </div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {cards.map((card, i) => (
          <PrimaryCard
            markdown={card.markdown}
            icon={card.icon}
            key={`cheapestAutoTransport/${i}`}
            component="primaryCard"
          />
        ))}
      </section>
    </section>
  )
}
