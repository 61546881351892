import {
  storyblokEditable,
  StoryblokComponent,
  SbBlokData,
} from "@storyblok/react"

export interface IPage extends SbBlokData {
  body: Array<SbBlokData>
}

const Page = ({ blok }: { blok: IPage }) => (
  <main {...storyblokEditable(blok)}>
    {blok.body.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </main>
)

export default Page
