import { FC } from "react"
import { SbBlokData } from "@storyblok/react"
import { marked } from "marked"
import Image from "next/image"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

export interface IProductServices extends SbBlokData {
  blok: {
    image: BlokImage
    items: Array<
      {
        title: string
        markdown: string
      } & SbBlokData
    >
    customClasses: string
  }
}
const Services: FC<IProductServices> = (props) => {
  const { image, items, customClasses } = props.blok
  return (
    <section
      id="services"
      className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl w-full mx-auto mt-2 md:mt-20 pt-10 md:pt-20 align-start px-6 lg:px-0"
    >
      <article className={`px-4 text-left ${customClasses}`}>
        <Image
          src={readImage(image)}
          alt={image.alt || "pet transport"}
          className="rounded-lg object-cover w-full md:w-[581px] md:h-[417px]"
          width={581}
          height={417}
          loading="lazy"
        />
      </article>
      <section className="grid grid-cols-1 gap-3">
        {items?.map(({ title, markdown }, i) => (
          <div
            key={i}
            className={`grid md:grid-cols-[70px_1fr] grid-cols-[50px_1fr]`}
          >
            <div
              className={`text-gold text-3xl md:text-5xl md:mr-7 font-merriweather `}
            >
              {i + 1}
            </div>
            <div>
              {title && (
                <h4 className="text-2xl text-inherit mb-1 font-merriweather">
                  {title}
                </h4>
              )}
              <div
                dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }}
                className="max-w-none font-inter prose-h4:text-xl leading-6 prose-h4:text-black prose-h4:font-normal prose-p:text-[16px]"
              />
            </div>
          </div>
        ))}
      </section>
    </section>
  )
}
export default Services
