import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

import colors from "../styles/colors"
import { mediaQueries } from "../styles/breakpoints"
import { fontWeight } from "../styles/fonts-constants"

const useStyles = createUseStyles({
  buttonWrap: {
    display: "flex",
    justifyContent: "center",
    marginTop: 40,
    marginBottom: 40,
    width: "100%",
  },
  button: {
    flexGrow: 1,
    fontFamily: "'Roboto', sans-serif",
    fontWeight: fontWeight.normal,
    fontSize: "18px",

    position: "relative",
    display: "block",
    height: "auto",
    textAlign: "center",
    verticalAlign: "middle",
    borderRadius: 6,
    color: colors.white1,
    backgroundColor: colors.blue8,
    zIndex: "auto",
    padding: "14px 30px",
    "&:hover": {
      pointer: "cursor",
      textDecoration: "none",
      color: colors.white1,
      backgroundColor: colors.blue7,
    },
    [mediaQueries.tablet]: {
      flexGrow: 0,
      width: 400,
      fontSize: 22,
    },
  },
  buttonDarkBlue: {
    extend: "button",
    backgroundColor: colors.blue7,
    "&:hover": {
      color: colors.white1,
      backgroundColor: colors.blue15,
    },
  },

  backgroundHighlight: {
    position: "absolute",
    bottom: 10,
    left: 0,
    width: "100%",
    [mediaQueries.tablet]: {
      width: 410,
      left: 150,
    },
    [mediaQueries.desktop]: {
      width: 410,
      left: 270,
    },
    [mediaQueries.largeDesktop]: {
      left: 350,
      width: 420,
    },
  },
})

const CtaButton = (props) => {
  const { title, link, darkBlueTheme } = props

  const classes = useStyles()

  return (
    <>
      <div
        className={classes.buttonWrap}
        style={{
          justifyContent: props?.align ? props?.align : "center",
        }}
      >
        <a
          className={darkBlueTheme ? classes.buttonDarkBlue : classes.button}
          href={link}
        >
          {title}
        </a>
      </div>
    </>
  )
}

CtaButton.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  darkBlueTheme: PropTypes.bool,
}

export default CtaButton
