import React from "react"
import { createUseStyles } from "react-jss"

import PawIcon from "../assets/icons/3-paws.svg"
import TrophyIcon from "../assets/icons/trophy.svg"
import Image from "next/legacy/image"

import colors from "../styles/colors"
import { mediaQueries } from "../styles/breakpoints"
import { Container, Row, Col } from "../components/layout/grid"

const bannerContent = {
  id: 1,
  title: "We’re leading the pack",
  icon: TrophyIcon,
  text:
    "CitizenShipper is the first and only shipping platform to offer a Pet Protection Plan for every journey.",
}

const useStyles = createUseStyles({
  root: {
    boxShadow: "0px 1px 6px rgba(0,0,0,0.05)",
    width: "95%",
    margin: "auto",
    padding: 0,
    borderRadius: 6,

    [mediaQueries.tablet]: {
      padding: "24px 20px",
      width: "100%",
    },
    [mediaQueries.desktop]: {
      padding: "36px 64px",
    },
  },

  title: {
    color: colors.blue4,
    fontSize: 20,
    lineHeight: "32px",
    fontFamily: "var(--font-montserrat), sans-serif",
    textAlign: "center",
    width: "100%",
    fontWeight: "600",
    marginTop: "10px",
    [mediaQueries.tablet]: {
      fontSize: 30,
      fontWeight: "500",
      textAlign: "left",
    },
    [mediaQueries.desktop]: {
      fontSize: 36,
      marginBottom: 16,
    },
  },
  coverages: {
    width: "90%",
    margin: "auto",
    display: "flex",
    padding: "20px 0",
    alignItems: "center",
    flexDirection: "column",

    [mediaQueries.tablet]: {
      width: "90%",
      flexDirection: "row",
      marginTop: 36,
    },
    [mediaQueries.desktop]: {
      width: "70%",
      flexDirection: "row",
      marginTop: 36,
    },
  },
  text: {
    fontSize: 16,
    textAlign: "center",
    [mediaQueries.tablet]: {
      flexDirection: "row",
      fontSize: 20,
      lineHeight: "28px",
      textAlign: "left",
    },
    [mediaQueries.desktop]: {
      fontSize: 24,
      lineHeight: "38px",
    },
  },
  paw: {
    marginBottom: "30px",
  },

  paws: {
    display: "none",
    [mediaQueries.tablet]: {
      display: "flex",
      width: "70px",
      position: "absolute",
      right: "50px",
      top: "-40px",
    },
  },

  petProtectionWrapper: {
    margin: "auto",

    [mediaQueries.tablet]: {
      width: "95%",
    },
    [mediaQueries.desktop]: {
      width: "95%",
    },
    [mediaQueries.largeDesktop]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  petProtectionCol: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  bannerImage: {
    width: "80px",
    [mediaQueries.tablet]: {
      marginBottom: 20,
      marginRight: 30,
      width: "300px",
    },
  },
})

const PetTransportBanner = ({ title, content }) => {
  const classes = useStyles()
  return (
    <Container className={classes.petProtectionWrapper}>
      <Row>
        <Col sm={12} className={classes.root}>
          <div className={classes.coverages}>
            <div className={classes.bannerImage}>
              <Image
                src={bannerContent.icon}
                alt={"altText"}
                objectFit={"contain"}
              />
            </div>
            <div className={classes.coverage}>
              <div style={{ display: "flex", position: "relative" }}>
                <h3 className={classes.title}>
                  {title ? title : bannerContent.title}
                </h3>
                <span className={classes.paws}>
                  <Image
                    src={PawIcon}
                    alt={"altText"}
                    width={70}
                    height={70}
                    objectFit={"contain"}
                  />
                </span>
              </div>
              <p className={classes.text}>
                {content ? content : bannerContent.text}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}
export default PetTransportBanner
