import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from ".."
import Markdown from "../../components/content/Markdown"
import hero_bg from "../../assets/images/hero_bg.webp"
import { readImage } from "../../app/utils/read-image"
// import bg_2 from "/images/bg_2.webp"

export interface IIntroduction extends SbBlokData {
  heading: string
  markdown: string
  image?: BlokImage
}

export default function Introduction({ blok }: { blok: IIntroduction }) {
  const { heading, markdown, image } = blok

  return (
    <section
      className="relative page-padding w-full flex xl:gap-40 lg:gap-32 md:gap-24 bg-cover bg-[url('/images/bg_2.webp')]"
      {...storyblokEditable(blok)}
    >
      <Image
        className="-mb-1 bg-cover bg-no-repeat -z-10"
        src="/images/bg_2.webp"
        alt="CitizenShipper"
        fill
        priority
      />
      {image && (
        <div className="hidden lg:block relative min-h-[24rem] rounded-lg grow w-2/5">
          <Image
            className="object-cover rounded-lg"
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
          />
        </div>
      )}
      <article className="lg:w-3/5 w-full flex flex-col justify-center">
        <h3 className="mb-5 font-montserrat font-medium text-4xl text-blue7 ">
          {heading}
        </h3>
        {image && (
          <div className="lg:hidden relative h-72 w-full my-8">
            <Image
              className="object-contain"
              src={readImage(image)}
              alt={image.description || image.alt}
              fill
            />
          </div>
        )}
        <Markdown className="prose-p:text-xl prose-p:font-normal prose-p:font-roboto">
          {markdown}
        </Markdown>
      </article>
    </section>
  )
}
