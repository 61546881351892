import React from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"
import classNames from "classnames"

const useStyles = createUseStyles({
  text: {
    display: "flex",
    color: "#29313B",
    fontSize: 16,
    fontWeight: 500,
    width: "100%",
    marginBottom: 8,
  },
})

const Label = ({ children, className }) => {
  const classes = useStyles()
  return (
    <span
      className={classNames({
        [classes.text]: true,
        [className]: className,
      })}
    >
      {children}
    </span>
  )
}

Label.propTypes = {
  children: PropTypes.node,
}

export default Label
