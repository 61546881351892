import Image from "next/image"
import { HTMLAttributes } from "react"
import classNames from "classnames"
import { marked } from "marked"
import { BlokImage } from "../../storyblok"
// import Markdown from "../content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IPartnersSectionProps extends HTMLAttributes<HTMLElement> {
  blok: {
    heading: string
    buttonText?: string
    link?: string
    image?: BlokImage
    isImageRight: boolean
    hasBackground: boolean
    headerText: string
    subtext: string
  }
}

export default function PartnersSection({
  blok: {
    image,
    buttonText,
    headerText,
    subtext,
    link,
    isImageRight = false,
    hasBackground = false,

    ...props
  },
}: IPartnersSectionProps) {
  return (
    <section
      className={classNames(
        "w-full mx-auto my-12 md:my-16 px-8",
        hasBackground && "bg-blue7 bg-opacity-10",
      )}
    >
      <article
        className="max-w-[1400px] flex flex-col md:flex-row justify-center gap-8 md:gap-16 mx-auto py-10 md:py-20"
        {...props}
      >
        <div
          className={classNames(
            "flex justify-center",
            isImageRight && "md:order-last",
          )}
        >
          {image && (
            <Image
              src={readImage(image)}
              alt={image.alt}
              className="rounded-lg object-cover w-full md:w-[452px] md:h-[551px] "
              width={452}
              height={551}
            />
          )}
        </div>

        <div className="flex flex-col justify-center">
          <div>
            <h2 className="text-3xl xl:text-[36px] font-merriweather max-w-md mb-6 leading-[54px] -mt-2">
              {headerText}
            </h2>

            <p className="text-xl xl:text-2xl mb-2 md:mb-12 pb-6 max-w-xl">
              {subtext}
            </p>

            {buttonText && (
              <a
                href={link}
                className="inline-block mt-5 md:w-auto px-10 md:px-12 rounded-md py-3 md:py-4 text-center bg-blue7 hover:bg-blue-700 text-white hover:text-white text-md md:text-lg hover:no-underline"
              >
                {buttonText}
              </a>
            )}
          </div>
        </div>
      </article>
    </section>
  )
}
