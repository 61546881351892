import { FC } from "react"
import Image from "next/image"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

export interface Props {
  blok: {
    image: BlokImage
    authorName: string
    authorTitle: string
    review: string
  }
}
const AuthorReview: FC<Props> = (props) => {
  const { image, authorName, authorTitle, review } = props.blok
  return (
    <section
      id="review"
      className="border-secondary-300 border rounded-lg lg:rounded-[70px] p-2 max-w-[1244px] mx-auto mt-2"
    >
      <div className="bg-secondary-300 font-inter rounded-lg lg:rounded-[70px] py-24">
        <div className="md:grid md:grid-cols-8 md:gap-10 lg:gap-20 font-inter justify-center">
          <div className="col-start-2 col-end-4 mb-4 md:mb-0 mx-auto">
            <div className="rounded-full">
              <Image
                src={readImage(image)}
                alt={image.alt}
                height={271}
                width={200}
                className="w-[142px] h-[192px] object-cover md:w-[200px] md:h-[271px] text-center mx-auto rounded-full"
              />
            </div>
          </div>

          <div className="col-start-4 col-span-4 p-6">
            <p className="text-2xl mb-6">{review}</p>
            <p className="text-lg mb-0 uppercase">{authorName}</p>
            <span className="opacity-80 text-lg uppercase">{authorTitle}</span>
          </div>
        </div>
      </div>
    </section>
  )
}
export default AuthorReview
