import { FormEvent, useState } from "react"
import dynamic from "next/dynamic"
import { HiOutlineMail, HiOutlineCube } from "react-icons/hi"
import store from "store"

import EmailInput from "../../components/form/EmailInput"
import Select, { ISelectProps } from "../../components/form/Select"
import { GoogleAddressData } from "../../components/form/types"
import {
  BoatIcon,
  BoxIcon,
  CarIcon,
  FreightIcon,
  FurnitureIcon,
  HomeIcon,
  MotorcycleIcon,
  PawIcon,
  TractorIcon,
} from "../../components/svgs"
import { EMAIL_STORAGE } from "../../constants/storage"

import PickupIcon from "../../assets/icons/arrow-diagonal-thick-up.svg"
import DeliveryIcon from "../../assets/icons/arrow-diagonal-thick-down.svg"

import { generateFormQueryParams } from "../../services/links"
import BookingAssuranceGuaranteeBadge from "../../components/booking-assurance-guarantee-badge"
import { env } from "../../config"

const PlaceInputAutocomplete = dynamic(
  () => import("../../components/form/place-input-autocomplete"),
  {
    ssr: false,
  },
)

const selectOptions: ISelectProps["options"] = [
  "All Categories",
  {
    value: "animals",
    label: "Pets and animals",
    icon: <PawIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "motorcycles",
    label: "Motorcycles",
    icon: <MotorcycleIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "vehicles",
    label: "Vehicles",
    icon: <CarIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "boats",
    label: "Boats and water vehicles",
    icon: <BoatIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "household-items",
    label: "Household items",
    icon: <FurnitureIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "apartment-home-moves",
    label: "Apartment and home moves",
    icon: <HomeIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "heavy-equipment",
    label: "Heavy equipment and industrial",
    icon: <TractorIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "freight",
    label: "Freight",
    icon: <FreightIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "others",
    label: "All other items",
    icon: <BoxIcon className="h-6 w-6 stroke-blue14" />,
  },
]

export interface HeroShippingFormBlok {
  hasSelectCategory?: boolean
  hasPickupInput?: boolean
  hasDeliveryInput?: boolean
  categoryOptions?: { label: string; value: string }[]
  selectCategoryPlaceholder?: string
  hasEmail?: boolean
  buttonLink?: string
  buttonLabel?: string
  footer?: string
  unsubscribeText?: string
}

const HeroShippingForm = ({ blok }: { blok: HeroShippingFormBlok }) => {
  const {
    hasSelectCategory,
    hasPickupInput,
    selectCategoryPlaceholder = "What are you shipping",
    hasDeliveryInput,
    categoryOptions,
    hasEmail,
    buttonLabel,
    buttonLink = `/shipments/new`,
    footer,
    unsubscribeText = "We’ll keep your email safe and you can unsubscribe any time.",
  } = blok
  const selectPlaceholder: ISelectProps["placeholder"] = {
    label: selectCategoryPlaceholder,
    icon: <HiOutlineCube className="h-6 w-6 stroke-blue14" />,
  }

  const [selectInvalid, setSelectInvalid] = useState<boolean>(false)
  const [data, setData] = useState<{
    delivery: GoogleAddressData | null
    pickup: GoogleAddressData | null
  }>({
    delivery: null,
    pickup: null,
  })
  const [errors, setErrors] = useState<Record<string, null | string>>({
    delivery: "",
    pickup: "",
    email: "",
  })
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const form = new FormData(e.currentTarget)
    const email = form.get("HomepageHeader/email")
    const select = form.get("HomepageHeader/select")

    if (!select) {
      setSelectInvalid(true)
      return
    }
    setSelectInvalid(false)

    let queryParams: Record<string, string | number> = {}

    if (hasPickupInput && data.pickup != null) {
      queryParams.pickupFA = data.pickup.formatted_address
      queryParams.pickupSC = data.pickup.state_code
      queryParams.pickupCt = data.pickup.city
      queryParams.pickupLat = data.pickup.lat
      queryParams.pickupLng = data.pickup.lng
      queryParams.pickupZip = data.pickup.zip ?? ""
    }

    if (hasDeliveryInput && data.delivery != null) {
      queryParams.deliveryFA = data.delivery.formatted_address
      queryParams.deliverySC = data.delivery.state_code
      queryParams.deliveryCt = data.delivery.city
      queryParams.deliveryLat = data.delivery.lat
      queryParams.deliveryLng = data.delivery.lng
      queryParams.deliveryZip = data.delivery.zip ?? ""
    }

    if (email) {
      queryParams.email = email.toString()
      store.set(EMAIL_STORAGE, email.toString())
    }

    const formQueryParams = generateFormQueryParams({
      query: queryParams,
    })

    window.location.assign(
      `${env(
        "NEXT_PUBLIC_WEBSITE_HOST",
      )}${buttonLink}/${select}${formQueryParams}`,
    )
  }

  const handleLocationChange = (type: string, location: string) => {
    setData((previousData) => {
      return {
        ...previousData,
        [type]: location,
      }
    })
  }

  const handleLocationError = (type: string, error: string) => {
    setErrors((previousErrors) => {
      return {
        ...previousErrors,
        [type]: error,
      }
    })
  }
  const options = categoryOptions || selectOptions
  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-4">
      {hasSelectCategory && (
        <Select
          options={options}
          placeholder={selectPlaceholder}
          name="HomepageHeader/select"
          className="font-montserrat"
          invalid={selectInvalid}
        />
      )}
      {hasPickupInput && (
        <div className="relative">
          <PlaceInputAutocomplete
            value={data.pickup}
            icon={PickupIcon.src}
            label="Pickup location"
            name="HomepageHeader/pickup"
            onChange={(location: string) =>
              handleLocationChange("pickup", location)
            }
            onError={(error: string) => handleLocationError("pickup", error)}
            error={errors.pickup}
          />
        </div>
      )}
      {hasDeliveryInput && (
        <div className="relative">
          <PlaceInputAutocomplete
            value={data.delivery}
            icon={DeliveryIcon.src}
            label="Delivery location"
            name="HomepageHeader/delivery"
            onChange={(location: string) =>
              handleLocationChange("delivery", location)
            }
            onError={(error: string) => handleLocationError("delivery", error)}
            error={errors.delivery}
          />
        </div>
      )}

      {hasEmail && (
        <EmailInput
          label="Email"
          name="HomepageHeader/email"
          className="bg-blue16 font-montserrat"
        >
          <HiOutlineMail className="h-6 w-6 stroke-blue14" />
        </EmailInput>
      )}
      <p
        className="m-0 p-0 text-center text-sm text-gray9"
        dangerouslySetInnerHTML={{ __html: unsubscribeText }}
      ></p>
      <button
        type="submit"
        className="w-full m-auto py-5 rounded bg-blue7 hover:bg-blue8 text-white text-xl text-[22px] font-montserrat font-bold"
      >
        {buttonLabel || "Get Free Estimate"}
      </button>
      <BookingAssuranceGuaranteeBadge footer={footer} />
    </form>
  )
}
export default HeroShippingForm
