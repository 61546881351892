import { SbBlokData } from "@storyblok/react"
import { marked } from "marked"

import FAQ from "../../../sections/faq-expand-collapse"

export interface IPillarsFAQ extends SbBlokData {
  id?: string
  blocks: Array<{
    header: string
    markdown: string
    id: string
  }> &
    SbBlokData
}

export default function PillarsFAQ({ blok }: { blok: IPillarsFAQ }) {
  const { id, blocks } = blok
  const data =
    blocks &&
    blocks.map((block) => ({
      ...block,
      title: block.header,
      text: marked.parse(block.markdown),
    }))

  return (
    <section id={id}>
      <FAQ data={data} />
    </section>
  )
}
