import React, { FC, useEffect, useState } from "react"

import { BlokImage } from "../../storyblok"
import Image from "next/image"
import StarRating from "../star-rating-v2"
import Verifications from "../verifications"
import star from "../../assets/vector/star-new.svg"
import { shuffleArray } from "../../util/array"

type Image = {
  src: string
  alt: string
}

interface Item {
  content: string
  name: string
  rating: string
  image: BlokImage | Image
  shipmentsComplete: string
  longContent: boolean
}
interface Props {
  items: Item[]
}

const MeetDriversContent: FC<Props> = ({ items }) => {
  const [viewFullReview, setViewFullReview] = useState(false)

  const item = shuffleArray(items)[0]

  return (
    <>
      {item && (
        <article className="md:flex gap-x-12 items-start mb-10 transition-opacity duration-500 ease-in-out px-4">
          <div className="w-full h-full md:h-96 relative rounded-3xl md:w-1/2">
            <Image
              className="object-contain rounded-3xl"
              src={
                (item.image as BlokImage).filename ?? (item.image as Image)?.src
              }
              alt={item.image.alt}
              width={357}
              height={357}
            />
          </div>

          <section
            className={`ml-auto w-full md:w-1/2  ${
              viewFullReview
                ? ""
                : `${item.longContent ? "md:h-[400px]" : "md:min-h-[300px]"} `
            }`}
          >
            <div>
              <div className="text-xl font-medium mb-2 mt-5 md:mt-2 text-secondary-900">
                {item.name}
              </div>

              <StarRating rating={parseInt(item.rating)} star={star} />
            </div>
            <h5 className="mb-2.5 mt-2 text-sm font-light">
              {item.shipmentsComplete} shipments so far
            </h5>
            <Verifications />
            <p className="my-4 mb-2.5 text-lg font-medium text-secondary-900">
              Recent Review
            </p>

            <p
              className={`mb-8 text-base ${
                viewFullReview ? "" : "line-clamp-8 lg:line-clamp-5"
              } lg:min-w-[573px]`}
            >
              {item.content}
            </p>
            <div>
              {item.longContent && (
                <p
                  onClick={() => {
                    setViewFullReview(!viewFullReview)
                  }}
                  className="text-blue7 cursor-pointer"
                >
                  {" "}
                  {viewFullReview ? "Show less" : "Read full Review"}
                </p>
              )}
            </div>
          </section>
        </article>
      )}
    </>
  )
}

export default MeetDriversContent
