import { SbBlokData } from "@storyblok/react"
import { BlokImage } from "../.."
import MeetDrivers from "../../../sections/meet-drivers"

export interface IMeetTheDriversV2 extends SbBlokData {
  title: string
  petProtection?: string
  isNonPets?: boolean
  theme: "homepageTheme" | "default"
  drivers: Array<{
    shipments: string
    image: BlokImage
    content: string
    hasLongContent: boolean
    ratingCompany: string
    ratingValue: number
  }> &
    SbBlokData
}
const MeetTheDriversV2 = ({ blok }: { blok: IMeetTheDriversV2 }) => {
  const {
    drivers,
    theme,
    title,
    isNonPets = false,
    petProtection = "$1,000 Pet Protection Plan - Included with all pet transports",
  } = blok
  const driversData =
    drivers?.map((item) => ({
      ...item,
      longContent: item.hasLongContent,
      image: { ...item.image, src: item.image?.filename },
      rating: { company: item.ratingCompany, value: Number(item.ratingValue) },
    })) ?? []

  return (
    <MeetDrivers
      theme={theme}
      drivers={driversData}
      title={title}
      isNonPets={isNonPets}
      petProtection={petProtection}
    />
  )
}

export default MeetTheDriversV2
