interface IShipmentCard {
  pickupLocation: string
  deliveryLocation: string
  mileage: string | number
  transporter: string
  cost: string | number
}

export default function ShipmentCard({
  pickupLocation,
  deliveryLocation,
  mileage,
  transporter,
  cost,
}: IShipmentCard) {
  return (
    <section className="p-4 border border-gray2 bg-white flex flex-col">
      <h5 className="text-blue7 text-base font-medium">
        Shipment from {pickupLocation} to {deliveryLocation} ({mileage}mi)
      </h5>
      <p className="my-4 text-base font-medium">Won by {transporter} for</p>
      <p className="h-14 w-16 m-0 mt-auto rounded-full bg-yellow4 text-gray1 flex items-center justify-center">
        ${cost}
      </p>
    </section>
  )
}
