"use client"

import { useForm, Controller } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "zod"
import { HiOutlineCube } from "react-icons/hi"

import { generateFormQueryParams } from "../../services/links"

import { Select } from "./select"
import { Email } from "./email"
import { Button } from "../button"

import { PawIcon } from "../../assets/icons/PawIcon"
import { MotorcycleIcon } from "../../assets/icons/MotorcycleIcon"
import { CarIcon } from "../../assets/icons/CarIcon"
import { BoatIcon } from "../../assets/icons/BoatIcon"
import { FurnitureIcon } from "../../assets/icons/FurnitureIcon"
import { HomeIcon } from "../../assets/icons/HomeIcon"
import { TractorIcon } from "../../assets/icons/TractorIcon"
import { FreightIcon } from "../../assets/icons/FreightIcon"
import { BoxIcon } from "../../assets/icons/BoxIcon"
import { Heading } from "../heading"
import { Paragraph } from "../paragraph"
import { AssuranceGuarantee } from "../assurance-guarantee"
import { EMAIL_STORAGE } from "../../constants/storage"
import { env } from "../../../config"

const shippingOptions = [
  {
    value: "animals",
    label: "Pets and animals",
    icon: <PawIcon />,
  },
  {
    value: "motorcycles",
    label: "Motorcycles",
    icon: <MotorcycleIcon />,
  },
  {
    value: "vehicles",
    label: "Vehicles",
    icon: <CarIcon />,
  },
  {
    value: "boats",
    label: "Boats and water vehicles",
    icon: <BoatIcon />,
  },
  {
    value: "household-items",
    label: "Household items",
    icon: <FurnitureIcon />,
  },
  {
    value: "apartment-home-moves",
    label: "Apartment and home moves",
    icon: <HomeIcon />,
  },
  {
    value: "heavy-equipment",
    label: "Heavy equipment and industrial",
    icon: <TractorIcon />,
  },
  {
    value: "freight",
    label: "Freight",
    icon: <FreightIcon />,
  },
  {
    value: "others",
    label: "All other items",
    icon: <BoxIcon />,
  },
]

const shipmentSchema = z.object({
  category: z.string({
    required_error: "Category is required",
    invalid_type_error: "Category is required",
  }),
  email: z.string().email(),
})

export type ShippingFormProps = {
  title: string
  description: string
  unsubscribe: string
  buttonLabel: string
}

export const ShippingForm = (props: ShippingFormProps) => {
  const { handleSubmit, control } = useForm<{
    category: null | string
    email: string
  }>({
    resolver: zodResolver(shipmentSchema),
    mode: "onSubmit",
    defaultValues: {
      category: null,
      email: "",
    },
  })

  const onSubmit = handleSubmit(
    (data: Record<string, any>) => {
      const formQueryParams = generateFormQueryParams({
        query: {
          email: data.email ? data.email : "",
        },
      })

      localStorage.setItem(EMAIL_STORAGE, data.email)
      const route = `${env("APP_HOST") ?? ""}/shipments/new/${
        data.category
      }${formQueryParams}`

      window.location.assign(route)
    },
    (errors: any) => {
      console.log(errors)
    },
  )
  return (
    <div className="pt-4 md:p-4 space-y-7 rounded-lg max-w-2xl md:shadow-md mx-auto lg:mx-0 lg:max-w-lg lg:shadow-2xl bg-white">
      <form onSubmit={onSubmit} className="grid grid-cols-1 gap-4">
        <Heading component={"h1"} variant="h2">
          {props.title}
        </Heading>
        <Paragraph variant="large">{props.description}</Paragraph>
        <Controller
          control={control}
          name="category"
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return (
              <div>
                <Select
                  value={field.value}
                  ref={field.ref}
                  id="category"
                  onChange={field.onChange}
                  placeholder="What are you shipping?"
                  items={shippingOptions}
                  defaultIcon={
                    <HiOutlineCube size={24} className="w-6 text-neutral-600" />
                  }
                  error={error}
                />
              </div>
            )
          }}
        />
        <Controller
          control={control}
          name="email"
          render={({ field, fieldState }) => {
            const error = fieldState.error
              ? fieldState.error.message
              : undefined

            return (
              <div>
                <Email
                  value={field.value}
                  ref={field.ref}
                  onChange={field.onChange}
                  label="Email"
                  error={error}
                  id="email"
                />
              </div>
            )
          }}
        />
        <p className="text-sm text-neutral-600 text-center px-1">
          {props.unsubscribe}
        </p>
        <div>
          <Button fullWidth onClick={onSubmit}>
            {props.buttonLabel}
          </Button>
        </div>
      </form>
      <AssuranceGuarantee />
    </div>
  )
}
