import * as React from "react"
import classNames from "classnames"
import { RxEnvelopeClosed } from "react-icons/rx"

type EmailProps = {
  value: string
  onChange: (value: string) => void
  label: string
  error?: string
  id?: string
}

export const Email = React.forwardRef<HTMLInputElement, EmailProps>(
  function EmailInput(props: EmailProps, ref) {
    return (
      <div className="grid grid-cols-1 gap-1">
        <div className="relative">
          <span className="absolute z-10 left-4 top-1/2 transform -translate-y-1/2">
            <RxEnvelopeClosed size={24} className="text-neutral-600" />
          </span>
          <input
            type="email"
            ref={ref}
            className={classNames(
              "p-4 rounded pl-12 w-full relative flex items-center space-x-2 text-left text-base bg-primary-100 focus:outline-none",
              "border-b-2 peer",
              props.error != null
                ? "border-red-500 text-red-500"
                : "border-transparent text-neutral-600 focus:border-black",
            )}
            value={props.value}
            onChange={(event) => props.onChange(event.target.value)}
            aria-label="Email"
            id={props.id}
          />
          <label
            className={classNames(
              "absolute left-12 top-1/2 transform -translate-y-1/2 transition peer-focus:-translate-y-7",
              props.error != null && "text-red-500",
              props.value !== "" && "-translate-y-7",
            )}
            htmlFor={props.id}
          >
            {props.label}
          </label>
        </div>
        {props.error != null && (
          <span className="text-red-500 text-sm block">{props.error}</span>
        )}
      </div>
    )
  },
)
