"use client"

import * as React from "react"
import { useSelect } from "downshift"
import classNames from "classnames"
import { HiChevronDown } from "react-icons/hi"

type SelectItem<T extends string | number | boolean> = {
  value: T
  label: string
  icon?: JSX.Element
}

type SelectProps<T extends string | number | boolean> = {
  placeholder: string
  id: string
  value: T | null
  onChange: (value: T) => void
  items: SelectItem<T>[]
  defaultIcon?: JSX.Element
  error?: string
  label: string
}

const SelectInput = <T extends string | number | boolean>(
  props: SelectProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  const valueToItem = (value: T | null) => {
    if (value == null) {
      return null
    }

    return props.items.find((item) => item.value === props.value)
  }

  const itemToString = (item: SelectItem<T> | null) => {
    return item?.label ?? ""
  }

  const itemToIcon = (item: SelectItem<T> | null) => {
    if (item == null) {
      return props.defaultIcon
    }

    return item.icon ?? props.defaultIcon
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    id: props.id,
    items: props.items,
    itemToString: itemToString,
    selectedItem: valueToItem(props.value),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem != null) {
        props.onChange(selectedItem.value)
      }
    },
  })

  return (
    <div className="relative">
      <div>
        <label className="uppercase text-gray16 text-sm inline-flex font-inter mb-3.5">
          {" "}
          <span className="mr-2">{itemToIcon(selectedItem)}</span>
          {props.label}
        </label>
        <div
          className={classNames(
            "w-full border-b border-gray-950",
            props.error != null
              ? "border-red-500"
              : "border-transparent focus:border-black",
          )}
        >
          <button
            type="button"
            className={classNames(
              "h-[59px] border-b border-gray-950 p-4 pr-8 w-full relative flex items-center space-x-2 text-left uppercase text-gray16 text-sm lg:text-base bg-gray50 focus:outline-none",
            )}
            {...getToggleButtonProps({
              ref,
              "aria-label": "Select Input",
            })}
          >
            <span className={classNames(props.error != null && "text-red-500")}>
              {selectedItem ? itemToString(selectedItem) : props.placeholder}
            </span>
            <span className="absolute right-2 top-1/2 transform -translate-y-1/2">
              <svg
                className={classNames("", isOpen === false && "rotate")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.031 9.53055L12.531 17.0306C12.4614 17.1003 12.3787 17.1556 12.2876 17.1933C12.1966 17.2311 12.099 17.2505 12.0004 17.2505C11.9019 17.2505 11.8043 17.2311 11.7132 17.1933C11.6222 17.1556 11.5394 17.1003 11.4698 17.0306L3.96979 9.53055C3.82906 9.38982 3.75 9.19895 3.75 8.99993C3.75 8.80091 3.82906 8.61003 3.96979 8.4693C4.11052 8.32857 4.30139 8.24951 4.50042 8.24951C4.69944 8.24951 4.89031 8.32857 5.03104 8.4693L12.0004 15.4396L18.9698 8.4693C19.0395 8.39962 19.1222 8.34435 19.2132 8.30663C19.3043 8.26892 19.4019 8.24951 19.5004 8.24951C19.599 8.24951 19.6965 8.26892 19.7876 8.30663C19.8786 8.34435 19.9614 8.39962 20.031 8.4693C20.1007 8.53899 20.156 8.62171 20.1937 8.71276C20.2314 8.8038 20.2508 8.90138 20.2508 8.99993C20.2508 9.09847 20.2314 9.19606 20.1937 9.2871C20.156 9.37815 20.1007 9.46087 20.031 9.53055Z"
                  fill="#4D4D4D"
                />
              </svg>
            </span>
          </button>
        </div>
      </div>
      <ul
        className={classNames(
          "absolute w-full bg-white overflow-y-auto p-0 max-h-80 z-50",
          isOpen === false && "hidden",
        )}
        {...getMenuProps()}
      >
        {isOpen &&
          props.items.map((item, index) => {
            return (
              <li
                {...getItemProps({ item, index })}
                className={classNames(
                  "p-2 text-left text-base text-neutral-600",
                  highlightedIndex === index ? "bg-gray-200" : "bg-white",
                )}
                key={`${item.value}${index}`}
              >
                <span>{item.label}</span>
              </li>
            )
          })}
      </ul>
      {props.error != null && (
        <span className="text-red-500 text-sm block mt-1">{props.error}</span>
      )}
    </div>
  )
}

export const Select = React.forwardRef(SelectInput) as <
  T extends string | number | boolean
>(
  props: SelectProps<T> & {
    ref?: React.ForwardedRef<HTMLButtonElement>
  },
) => ReturnType<typeof SelectInput>
