import { SbBlokData, storyblokEditable } from "@storyblok/react"
import IconCard, { IIconCardProps } from "../../components/cards/IconCard"
import { BlokImage } from ".."
import Image from "next/image"
import PlainCard from "../../components/cards/PlainCard"
import PrimaryCard from "../../components/cards/PrimaryCard"
import Markdown from "../../components/content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IWaysToShip extends SbBlokData {
  heading: string
  content?: string
  ways: Array<
    IIconCardProps & {
      component: "iconCard" | "primaryCard" | "plainCard"
    }
  >
  image: BlokImage
}

export default function WaysToShip({ blok }: { blok: IWaysToShip }) {
  const { heading, content, ways, image } = blok

  return (
    <section
      className="relative page-padding w-full flex flex-col md:items-center gap-3"
      {...storyblokEditable(blok)}
    >
      <h3 className="mb-6 text-center text-4xl text-blue7 font-montserrat font-medium">
        {heading}
      </h3>
      {image && (
        <div className="flex justify-center items-center relative w-full md:w-1/2 max-h-72 mb-5 sm:max-h-fit h-96">
          <Image
            className="h-fit object-cover rounded-lg "
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
          />
        </div>
      )}
      {content && (
        <Markdown className="max-w-none w-full mb-5 sm:mb-14 text-center prose-p:text-xl prose-p:font-normal prose-p:font-roboto">
          {content}
        </Markdown>
      )}
      {ways.map(({ component, ...way }, i) => {
        switch (component) {
          case "iconCard":
            return <IconCard {...way} key={`waysToShip/iconCard/${i}`} />
          case "primaryCard":
            return (
              <PrimaryCard
                {...way}
                key={`waysToShip/primaryCard/${i}`}
                component="primaryCard"
              />
            )
          case "plainCard":
            return <PlainCard {...way} key={`waysToShip/plainCard/${i}`} />
          default:
            return (
              <PrimaryCard
                {...way}
                key={`waysToShip/primaryCard/${i}`}
                component="primaryCard"
              />
            )
        }
      })}
    </section>
  )
}
