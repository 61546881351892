import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Link from "next/link"
import Markdown from "../../components/content/Markdown"

export interface ISupport extends SbBlokData {
  markdown: string
  bgColor: "white" | "gray12"
}

export default function Support({ blok }: { blok: ISupport }) {
  return (
    <section
      className={`mx-auto px-4 py-20 sm:py-32 max-w-[75ch] text-center text-xl`}
      {...storyblokEditable(blok)}
    >
      {blok?.markdown ? (
        <Markdown className="hover:no-underline">{blok?.markdown}</Markdown>
      ) : (
        <>
          <h3 className="text-4xl text-blue7 font-montserrat pb-8">
            We’re here for you the entire ride
          </h3>
          <p>
            For additional information, please consult the{" "}
            <Link href="faq">FAQ</Link> or browse through our{" "}
            <Link href="https://help.citizenshipper.com/hc/en-us">
              help center articles
            </Link>
            .
          </p>
          <p>
            We take pride in our unmatched Customer Support Team! You can always
            contact us{" "}
            <Link
              href="https://help.citizenshipper.com/hc/en-us/requests/new"
              target="_blank"
            >
              here
            </Link>{" "}
            for one-on-one assistance.
          </p>
        </>
      )}
    </section>
  )
}
