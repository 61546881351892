import React from "react"
import classNames from "classnames"
import { marked } from "marked"
import { createUseStyles } from "react-jss"
import { SbBlokData } from "@storyblok/react"

import { Container, Row, Col } from "../../../components/layout/grid"

import CheckIcon from "../../../assets/vector/check-new.svg"

import colors from "../../../styles/colors"
import { mediaQueries } from "../../../styles/breakpoints"
import { fontWeight, fontSize } from "../../../styles/fonts-constants"

const useStyles = createUseStyles(
  {
    keyPoints: {
      flexGrow: 1,
    },
    keyPointsTablet: {
      [mediaQueries.tablet]: {
        marginTop: 100,
      },
    },

    wrapContent: {
      [mediaQueries.desktop]: {
        "& h2": {
          marginLeft: "20%",
          textAlign: "left",
          marginBottom: "40px",
        },
      },
    },
    title: {
      fontFamily: "var(--font-montserrat), sans-serif",
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.22,
      textAlign: "center",
      width: "70%",
      margin: "0 auto",
      color: colors.blue7,
      [mediaQueries.tablet]: {
        fontSize: fontSize.extraLarge,
        width: "100%",
        textAlign: "center",
        paddingBottom: 0,
      },
    },
    titleDarkBlue: {
      extend: "title",
      color: colors.blue7,
      [mediaQueries.tablet]: {
        marginTop: 80,
      },
      [mediaQueries.desktop]: {
        marginTop: 160,
        paddingTop: 60,
      },
    },
    bulletsWrapper: {
      [mediaQueries.tablet]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    bullets: {
      padding: 0,
      margin: "40px auto 80px auto",
      [mediaQueries.largeMobile]: {
        padding: "0 15px",
      },
    },
    bulletTxt: {
      fontFamily: "'Roboto', sans-serif",
      fontSize: "16px",
      fontWeight: fontWeight.light,
      lineHeight: 1.5,
      color: colors.blue5,
      [mediaQueries.tablet]: {
        fontSize: fontSize.medium,
      },
    },

    flexRow: {
      display: "flex",
      marginBottom: 30,
    },
    checkIcon: {
      marginRight: 15,
      height: 38,
      [mediaQueries.desktop]: {
        marginRight: 30,
      },
    },
    article: {
      "& ul": {
        marginLeft: 10,
      },
      "& li": {
        marginBottom: 16,
        extend: "bulletTxt",
        fontWeight: fontWeight,
        lineHeight: 1.44,
        textIndent: -14,
      },
      "& li:before": {
        content: `url(${CheckIcon.src})`,
        top: 12,
        position: "relative",
        left: -36,
        marginRight: -24,
      },
      "& p": {
        fontSize: 16,
        lineHeight: 1.5,
      },

      "& a": {
        color: "#2268DE",
      },
      "& a:hover": {
        textDecoration: "underline",
      },
    },
  },
  { link: true },
)
export interface IPillarsPeaceOfMind extends SbBlokData {
  title: string
  keyPoints: string
  id: string
}
const PillarsPeaceOfMind = ({ blok }: { blok: IPillarsPeaceOfMind }) => {
  const { title, keyPoints, id } = blok
  const article = marked.parse(keyPoints)
  const classes = useStyles()

  return (
    <Container
      id={id}
      className={classNames(classes.keyPoints, classes.keyPointsTablet)}
    >
      <Row>
        {title && (
          <Col sm={12}>
            <h2 className={classes.title}>{title}</h2>
          </Col>
        )}

        <Col sm={12} className={classes.bulletsWrapper}>
          <Col className={classes.bullets} lg={8} md={12}>
            {keyPoints && (
              <article
                className={classNames("m-auto", classes.article)}
                dangerouslySetInnerHTML={{ __html: article }}
              />
            )}
          </Col>
        </Col>
      </Row>
    </Container>
  )
}
export default PillarsPeaceOfMind
