import React from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"
import classNames from "classnames"

const unit = 8

const size = (props) => props.size

const animation = {
  "@keyframes sk-bouncedelay": {
    "0%, 80%, 100%": {
      webkitTransform: "scale(0)",
      transform: "scale(0)",
    },
    "40%": {
      webkitTransform: "scale(1.0)",
      transform: "scale(1.0)",
    },
  },
}

const bounce = {
  width: size,
  height: size,
  marginLeft: unit / 4,
  marginRight: unit / 4,
  backgroundColor: (props) => props.backgroundColor,
  borderRadius: "100%",
  display: "inline-block",
  webkitAnimation: `$sk-bouncedelay1.4s infinite ease-in-out both`,
  animation: `$sk-bouncedelay 1.4s infinite ease-in-out both`,
}

const useStyles = createUseStyles({
  ...animation,
  display: "flex",
  height: "100vh",
  width: "100%",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  default: {
    margin: "auto",
  },
  spinner: {
    margin: "auto",
    textAlign: "center",
  },
  bounce1: {
    ...bounce,
    webkitAnimationDelay: "-0.32s",
    animationDelay: "-0.32s",
  },
  bounce2: {
    ...bounce,
    webkitAnimationDelay: "-0.16s",
    animationDelay: "-0.16s",
  },
  bounce3: {
    ...bounce,
  },
})

const BounceLoader = ({
  type = "default",
  className,
  backgroundColor,
  size,
}) => {
  const classes = useStyles({ size, backgroundColor })
  return (
    <div
      className={classNames({
        [type === "centered" ? classes.centered : classes.default]: true,
        [className]: className,
      })}
    >
      <div className={classes.spinner}>
        <div className={classes.bounce1} />
        <div className={classes.bounce2} />
        <div className={classes.bounce3} />
      </div>
    </div>
  )
}

BounceLoader.propTypes = {
  type: PropTypes.oneOf(["default", "centered"]),
}

BounceLoader.defaultProps = {
  size: 12,
}

export default BounceLoader
