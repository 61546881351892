import { SbBlokData } from "@storyblok/react"
import { BlokImage } from "../.."
import Image from "next/image"
import { BsArrowRight } from "react-icons/bs"
import Markdown from "../../../components/content/Markdown"
import { readImage } from "../../../app/utils/read-image"

export interface ICostCard {
  image: BlokImage
  personIcon: BlokImage
  personFrom: string
  petName: string
  cityFrom: string
  price: string
}
export interface ITransportCost extends SbBlokData {
  header: string
  details: string
  cards: ICostCard[]
}
export default function TransportCost({ blok }: { blok: ITransportCost }) {
  const { header, details, cards } = blok

  return (
    <article className="flex flex-col items-center pt-20 space-y-4">
      <h3 className="text-center text-4xl text-blue5 font-semibold">
        {header}
      </h3>
      {details && <Markdown className="">{details}</Markdown>}
      <section className="w-full flex flex-col lg:flex-row md:px-4 items-center space-y-7 lg:space-y-0 lg:justify-center lg:space-x-10 pt-10">
        {cards &&
          cards.map((card) => (
            <div
              key={card.image.filename}
              className="w-fit flex flex-col items-center pb-7 space-y-5 rounded-xl bg-white"
            >
              <Image
                className="object-contain"
                src={readImage(card.image)}
                alt={card.image.alt}
                width={320}
                height={271}
              />
              <div className="w-full flex space-x-2 justify-center items-center">
                <Image
                  className="object-contain"
                  src={readImage(card.personIcon)}
                  alt={card.personIcon.alt}
                  width={24}
                  height={24}
                />
                <p className="m-0">
                  {card.personFrom} shipped{" "}
                  <span className="font-semibold">{card.petName}</span>
                </p>
              </div>
              <p className="w-full text-center text-xl mb-">
                {card.cityFrom} <BsArrowRight className="inline-block mx-1" />{" "}
                Seattle, WA
              </p>
              <div className="w-full flex flex-col items-center space-y-1">
                <p className="text-3xl text-blue7 font-bold mb-0">
                  {card.price}
                </p>
                <p className="text-sm">$0.5625/mi</p>
              </div>
            </div>
          ))}
      </section>
      <section className="w-full flex flex-col items-center space-y-7 py-20">
        <h4 className="text-2xl text-blue5 text-center ">
          Types of animals and pets we transport
        </h4>
        <section className="grid grid-cols-2 gap-x-10 md:flex md:space-x-12 flex-wrap justify-start md:justify-center">
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/dog.webp"
              alt="dog profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Dogs</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/cat.webp"
              alt="cat profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Cats</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/horse.webp"
              alt="horse profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Horses</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/rabbit.webp"
              alt="rabbit profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Rabbits</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/reptile.webp"
              alt="cat profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Reptiles</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/rodent.webp"
              alt="cat profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Rodents</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/bird.webp"
              alt="bird profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Birds</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/fish.webp"
              alt="fish profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Fish</p>
          </div>
          <div className="flex items-center space-x-2.5 mb-7">
            <Image
              className="object-contain"
              src="/icons/livestock.webp"
              alt="cat profile"
              width={48}
              height={48}
              loading="lazy"
            />
            <p className="text-xl mb-0 font-semibold">Livestock</p>
          </div>
        </section>
      </section>
    </article>
  )
}
