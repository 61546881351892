import { HTMLAttributes } from "react"

export interface IExpenseCard extends HTMLAttributes<HTMLElement> {
  category: string
  pricePerMile: string
  description: string
  examples: string
  title: string
}

export default function ExpenseCard({
  category,
  pricePerMile,
  description,
  examples,
  title,
}: IExpenseCard) {
  return (
    <section className="p-6 sm:p-11 rounded-lg bg-white shadow-lg">
      <h3 className="font-semibold text-2xl text-center mb-4">{title}</h3>
      <p className="text-4xl text-center mb-8">
        <span className="font-bold">{pricePerMile}</span>
        <span className="font-light">/mi.</span>
      </p>
      <p className="mb-5">{description}</p>
      <p className="font-semibold mb-2">{category} include:</p>
      <p className="">{examples}</p>
    </section>
  )
}
