"use client"

import classNames from "classnames"
import * as React from "react"
import { match } from "ts-pattern"

type Headings = "h1" | "h2" | "h3" | "h4" | "h5" | "h6"

type HeadingProps = {
  children: React.ReactNode
  variant?: Headings
  component?: React.ElementType
  className?: string
}

export const Heading = (props: HeadingProps) => {
  const { variant = "h3" } = props
  const Component = props.component ?? "h2"

  const headingStyles = match(variant)
    .with("h1", () => classNames("text-3xl lg:text-5xl"))
    .with("h2", () => classNames("text-2xl lg:text-4xl"))
    .with("h3", () => classNames("text-xl lg:text-3xl"))
    .with("h4", () => classNames("text-xl lg:text-2xl"))
    .with("h5", () => classNames("text-bs lg:text-lg"))
    .with("h6", () => classNames("text-base"))
    .exhaustive()

  return (
    <Component
      className={classNames(
        "text-gray8 font-semibold",
        headingStyles,
        props.className,
      )}
    >
      {props.children}
    </Component>
  )
}
