import { SbBlokData, storyblokEditable } from "@storyblok/react"

import classNames from "classnames"
import colors from "../../styles/colors"
import { createUseStyles } from "react-jss"

export interface IHeadingProps {
  lineHeight?: number | string
  fontSize?: number
  color?: string
  fontWeight?: 400 | 500 | 600 | 700
  margin?: number | string
  padding?: number | string
  display?:
    | "block"
    | "inline"
    | "inline-block"
    | "none"
    | "flex"
    | "inline-flex"
}
export interface IHeadingBlok extends SbBlokData, IHeadingProps {
  text: string
  heading: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  headingClasses?: string
}

const useStyles = createUseStyles<string, IHeadingProps>(() => ({
  heading: {
    padding: ({ padding }) => padding,
    margin: ({ margin }) => margin,
    color: ({ color }) => (color == "white" ? colors.white1 : color),
    fontWeight: ({ fontWeight }) => fontWeight,
    fontSize: ({ fontSize }) => `${fontSize}px`,
    lineHeight: ({ lineHeight }) => lineHeight,
    display: ({ display }) => display,
  },
}))

const Heading = ({ blok }: { blok: IHeadingBlok }) => {
  const { text, heading: Element = "h1", headingClasses, ...styleProps } = blok

  const classes = useStyles({
    ...styleProps,
  })

  return (
    <Element
      key={blok._uid}
      className={classNames(classes.heading, headingClasses)}
      {...storyblokEditable(blok)}
    >
      {text}
    </Element>
  )
}
export default Heading
