import { BlokImage } from "../../storyblok"
import { FC, HTMLAttributes } from "react"

export interface IMeetTheDriversCardProps extends HTMLAttributes<HTMLElement> {
  header: string
  shipments: string
  image: BlokImage
  content: string
  longContent: boolean
  ratingCompany: string
  ratingValue: number
}

const MeetTheDriversCard: FC<IMeetTheDriversCardProps> = (props) => {
  return <div {...props} />
}

export default MeetTheDriversCard
