export const devicesWidths = {
  largeMobile: 480,
  tablet: 768,
  desktop: 992,
  largeDesktop: 1200,
  hugeDesktop: 1500,
  smallerThanTablet: 768,
  smallerThan580p: 580,
}

export const mediaQueries = {
  largeMobile: `@media (min-width: ${devicesWidths.largeMobile}px)`,
  tablet: `@media (min-width: ${devicesWidths.tablet}px)`,
  desktop: `@media (min-width: ${devicesWidths.desktop}px)`,
  largeDesktop: `@media (min-width: ${devicesWidths.largeDesktop}px)`,
  hugeDesktop: `@media (min-width: ${devicesWidths.hugeDesktop}px)`,
  smallerThanTablet: `@media (max-width: ${devicesWidths.smallerThanTablet}px)`,
  smallerThan580p: `@media (max-width: ${devicesWidths.smallerThan580p}px)`,
}
