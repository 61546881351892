import React, { FC } from "react"
import { marked } from "marked"
import { SbBlokData } from "@storyblok/react"
import Image from "next/image"
import "swiper/css"
import "swiper/css/pagination"

import { BlokImage } from ".."
import { readImage } from "../../app/utils/read-image"

type Image = {
  src: string
  alt: string
}

interface Item {
  description: string
  image: BlokImage | Image
  title?: string
}

export interface IPetsPassion extends SbBlokData {
  items?: Item[]
  title?: string
  image: BlokImage | Image
  postText?: string
  description?: string
}
const PetsPassion = ({ blok }: { blok: IPetsPassion }) => {
  const { image, items, title, postText, description } = blok
  return (
    <section className="md:px-10">
      <article className="max-w-[1400px] flex flex-col md:flex-row justify-center gap-8 md:gap-10 lg:gap-12 xl:gap-16 mx-auto py-10 md:py-20">
        <div className="flex justify-center md:order-last px-6 md:px-4">
          {image && (
            <Image
              src={readImage(image)}
              alt={image.alt}
              className="rounded-lg object-cover w-full md:w-[452px] md:h-[551px]"
              width={452}
              height={551}
            />
          )}
        </div>

        <div className="flex flex-col justify-center max-w-[672px] px-6 md:px-4">
          <div>
            <h2 className="text-[37px] leading-[60px] md:leading-[72px] lg:text-[40px] font-merriweather mb-6  md:-mt-10 max-w-[648px]">
              {title}
            </h2>
            <div
              className="font-inter text-xl text-black8 max-w-[600px] leading-[30px] mb-8"
              dangerouslySetInnerHTML={{ __html: marked(description || "") }}
            ></div>

            <p className="text-base text-secondary-500 max-w-[600px]">
              {postText}
            </p>
          </div>
        </div>
      </article>
      {items && (
        <div
          className={`grid grid-cols-1 md:grid-cols-${items.length} gap-x-6 gap-y-8 mb-4 max-w-[1200px] mx-auto px-6 md:px-4`}
        >
          {items?.map((item) => (
            <div className="flex items-center" key={item.title}>
              <Image
                src={readImage(item.image)}
                alt={"rating"}
                height={64}
                width={64}
                loading="lazy"
                className="w-16 h-16 object-contain"
              />
              <div className="pl-6">
                <p className="mb-0.5 text-secondary-500 font-merriweather text-xl md:text-md">
                  {item.title}
                </p>
                <span className="text-base max-w-[200px]">
                  {item.description}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  )
}

export default PetsPassion
