import { marked } from "marked"
import { HTMLAttributes, useEffect, useRef } from "react"

interface IMarkdownProps extends HTMLAttributes<HTMLElement> {
  children: string
}

export default function Markdown({
  className,
  children,
  ...props
}: IMarkdownProps) {
  const ref = useRef<HTMLElement>(null)

  useEffect(() => {
    if (!ref.current) return
    ref.current.innerHTML = marked?.parse(children)
  }, [])

  return (
    <article
      ref={ref}
      className={
        "max-w-none prose prose-xl prose-h3:text-4xl" +
        " " +
        "prose-headings:font-medium prose-headings:font-montserrat prose-headings:text-blue7" +
        " " +
        "prose-p:text-xl prose-p:font-roboto prose-p:text-blue5 prose-p:leading-7" +
        " " +
        "prose-a:text-link prose-a:no-underline hover:prose-a:text-linkHover hover:prose-a:underline prose-a:font-normal" +
        " " +
        "list-disc prose-ul:text-left prose-ul:text-xl" +
        "  " +
        className
      }
      {...props}
    ></article>
  )
}
