import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { useRouter } from "next/router"
import PrimaryButton from "../../components/buttons/PrimaryButton"

export interface IYoutubeEmbed extends SbBlokData {
  header: string
  videoId: string
  content: string
  buttonLabel: string
  bgColor: "white" | "orange1"
  path?: string
}

export default function YoutubeEmbed({ blok }: { blok: IYoutubeEmbed }) {
  const router = useRouter()
  const { header, videoId, content, buttonLabel, bgColor, path } = blok

  return (
    <section
      className={`page-padding relative w-full flex flex-col items-center space-y-8  bg-${bgColor}`}
      {...storyblokEditable(blok)}
    >
      {header && (
        <h3 className="font-montserrat text-4xl text-blue7 text-center">
          {header}
        </h3>
      )}
      <div className="relative w-full px-16 pb-[56.25%] pt-7">
        <iframe
          className="absolute top-0 left-0 right-0 mx-auto w-full h-full"
          src={`https://www.youtube.com/embed/${videoId}`}
        />
      </div>
      {content && <p className="text-blue22 text-2xl font-medium">{content}</p>}
      {buttonLabel && (
        <PrimaryButton
          className=""
          onClick={() => router.push(path ? `/${path}` : `/shipments/new`)}
        >
          {buttonLabel || "Register Today!"}
        </PrimaryButton>
      )}
    </section>
  )
}
