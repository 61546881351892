import { SbBlokData } from "@storyblok/react"
import { createUseStyles } from "react-jss"

import PetTransportBanner from "../../../sections/pet-transport-banner"
import { mediaQueries } from "../../../styles/breakpoints"

export interface IPillarsLeadingPack extends SbBlokData {
  id: string
  content: string
  title: string
}
const useStyles = createUseStyles(
  {
    petCoverageWrapper: {
      marginBottom: 40,
      marginTop: 30,

      [mediaQueries.desktop]: {
        marginTop: 100,
      },
    },
  },
  { link: true },
)

export default function PillarsLeadingPack({
  blok,
}: {
  blok: IPillarsLeadingPack
}) {
  const { id, content, title } = blok

  const classes = useStyles()

  return (
    <section id={id} className={classes.petCoverageWrapper}>
      <PetTransportBanner content={content} title={title} />
    </section>
  )
}
