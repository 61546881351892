import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Details from "../../components/content/Details"

export interface IFAQ extends SbBlokData {
  heading: string
  content: Array<
    {
      question: string
      answer: string
    } & SbBlokData
  >
  bgColor?: "gray11" | "orange1"
}

export default function FAQ({ blok }: { blok: IFAQ }) {
  const { heading, content, bgColor = "" } = blok

  return (
    <section
      className={`relative page-padding w-full flex flex-col gap-y-6 bg-${bgColor}`}
      {...storyblokEditable(blok)}
    >
      <h3 className="mb-4 md:text-4xl text-center text-blue7 font-montserrat">
        {heading}
      </h3>
      {content.map((faq, i) => (
        <Details summary={faq.question} key={`faq/${i}`} open={false}>
          {faq.answer}
        </Details>
      ))}
    </section>
  )
}
