"use client"

import * as React from "react"
import { useSelect } from "downshift"
import classNames from "classnames"
import { HiChevronDown } from "react-icons/hi"

type SelectItem<T extends string | number | boolean> = {
  value: T
  label: string
  icon?: JSX.Element
}

type SelectProps<T extends string | number | boolean> = {
  placeholder: string
  id: string
  value: T | null
  onChange: (value: T) => void
  items: SelectItem<T>[]
  defaultIcon?: JSX.Element
  error?: string
}

const SelectInput = <T extends string | number | boolean>(
  props: SelectProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) => {
  const valueToItem = (value: T | null) => {
    if (value == null) {
      return null
    }

    return props.items.find((item) => item.value === props.value)
  }

  const itemToString = (item: SelectItem<T> | null) => {
    return item?.label ?? ""
  }

  const itemToIcon = (item: SelectItem<T> | null) => {
    if (item == null) {
      return props.defaultIcon
    }

    return item.icon ?? props.defaultIcon
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectedItem,
  } = useSelect({
    id: props.id,
    items: props.items,
    itemToString: itemToString,
    selectedItem: valueToItem(props.value),
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem != null) {
        props.onChange(selectedItem.value)
      }
    },
  })

  return (
    <div className="relative">
      <div>
        <button
          type="button"
          className={classNames(
            "p-4 pr-8 w-full relative flex items-center space-x-2 text-left text-base text-neutral-600 rounded bg-primary-100 focus:outline-none",
            "border-b-2",
            props.error != null
              ? "border-red-500"
              : "border-transparent focus:border-black",
          )}
          {...getToggleButtonProps({
            ref,
            "aria-label": "Select Input",
          })}
        >
          <span>{itemToIcon(selectedItem)}</span>
          <span className={classNames(props.error != null && "text-red-500")}>
            {selectedItem ? itemToString(selectedItem) : props.placeholder}
          </span>
          <span className="absolute right-2 top-1/2 transform -translate-y-1/2">
            <HiChevronDown className="h-6 w-8 text-neutral-600" />
          </span>
        </button>
      </div>
      <ul
        className={classNames(
          "absolute w-full bg-white overflow-y-auto p-0 max-h-80 z-50",
          isOpen === false && "hidden",
        )}
        {...getMenuProps()}
      >
        {isOpen &&
          props.items.map((item, index) => {
            return (
              <li
                {...getItemProps({ item, index })}
                className={classNames(
                  "p-2 text-left text-base text-neutral-600",
                  highlightedIndex === index ? "bg-gray-200" : "bg-white",
                )}
                key={`${item.value}${index}`}
              >
                <span>{item.label}</span>
              </li>
            )
          })}
      </ul>
      {props.error != null && (
        <span className="text-red-500 text-sm block mt-1">{props.error}</span>
      )}
    </div>
  )
}

export const Select = React.forwardRef(SelectInput) as <
  T extends string | number | boolean
>(
  props: SelectProps<T> & {
    ref?: React.ForwardedRef<HTMLButtonElement>
  },
) => ReturnType<typeof SelectInput>
