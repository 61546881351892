import Link from "next/link"
import Image from "next/image"
import bbbRating from "../../assets/images/bbb-rating.webp"
const currentYear = new Date().getFullYear()

const Footer = () => {
  return (
    <section
      id="premium-footer"
      className="font-inter flex flex-col md:flex-row justify-between gap-8 py-4 max-w-7xl mx-auto border-t mt-12 md:mt-24 pt-8 px-6"
    >
      <div className="pt-6">
        <div className="flex gap-8 mb-4 text-md">
          <Link href="https://help.citizenshipper.com/hc/en-us/categories/200277749-Terms-of-Service-Agreements">
            Terms and Conditions
          </Link>
          <Link href="https://help.citizenshipper.com/hc/en-us/articles/204865815-Privacy-Policy">
            Privacy Policy
          </Link>
        </div>
        <p>&#169; {currentYear} CitizenShipper. All Rights Reserved.</p>
      </div>
      <div className="font-inter flex flex-row justify-between items-center gap-2 md:gap-10 ">
        <Image
          src="/icons/powered-by-stripe.webp"
          alt="stripe"
          width="150"
          height="30"
          loading="lazy"
          className="w-[150px] h-[30px]"
        />

        <Image
          src={bbbRating.src}
          alt="CitizenShipper is a BBB Accredited Business."
          width="135"
          height="40"
          className="w-[135px] h-[40px]"
          loading="lazy"
        />
      </div>
    </section>
  )
}
export default Footer
