import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { AiFillStar } from "react-icons/ai"

import { useReviewsContext } from "../../context/ReviewsContext"

export interface IRatingsBlock extends SbBlokData {
  googleRating: string
  trustRating: string
  transportRating: string
}

export default function RatingsBlock({ blok }: { blok: IRatingsBlock }) {
  const { googleRating, trustRating, transportRating } = blok
  const { blokReviews } = useReviewsContext()

  return (
    <article className="w-full hidden md:flex justify-center pb-20">
      <section className="w-[460px] hidden sm:block">
        <div className="space-x-2 flex ">
          <div className="w-1/2 h-36 flex flex-col items-evenly rounded-2xl shadow-md px-7 py-4">
            <Image
              className=""
              src="/icons/google.svg"
              alt="google logo"
              width={100}
              height={20}
              loading="lazy"
            />
            <p className="h-3 text-sm">Average Rating</p>
            <p className="w-full flex text-2xl">
              {blokReviews?.googleRatings ?? googleRating}
              <span className="ml-2 flex items-center">
                {[...Array(5)].map((_, i) => (
                  <AiFillStar
                    key={i}
                    className="h-5 w-5 fill-yellow12 stroke-yellow12 last-of-type:mr-auto"
                  />
                ))}
              </span>
            </p>
          </div>
          <div className="w-1/2 h-36 flex flex-col items-evenly rounded-2xl shadow-md px-7 py-4">
            <Image
              className=""
              src="/icons/trust-pilot.svg"
              alt="trust pilot logo"
              width={140}
              height={20}
              loading="lazy"
            />
            <p className="h-3 text-sm">Average Rating</p>
            <p className="w-full flex text-2xl">
              {blokReviews?.trustpilotRatings ?? trustRating}
              <span className="ml-2 flex items-center">
                {[...Array(5)].map((_, i) => (
                  <AiFillStar
                    key={i}
                    className="h-5 w-5 fill-yellow12 stroke-yellow12 last-of-type:mr-auto"
                  />
                ))}
              </span>
            </p>
          </div>
        </div>
        <div className="w-full h-32 flex flex-col justify-around items-center text- rounded-2xl shadow-md px-7 py-4">
          <p className="mt-4 fl ex">
            {[...Array(5)].map((_, i) => (
              <AiFillStar
                key={i}
                className="h-5 w-5 fill-yellow12 stroke-yellow12 last-of-type:mr-auto"
              />
            ))}
          </p>
          <p>
            Transport service rating:{" "}
            <span className="font-semibold">
              {blokReviews?.transportServiceRatings ?? transportRating}
            </span>{" "}
            -{" "}
            <span className="text-blue7">
              {" "}
              {blokReviews?.transportServiceReviews} reviews
            </span>
          </p>
        </div>
      </section>
    </article>
  )
}
