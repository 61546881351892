import type { BlokImage, ImageItem } from "../../storyblok/types"
import type { StaticImageData } from "next/legacy/image"

const extensions = [".svg", ".webp", ".png", ".jpg", ".jpeg"]
export function readImage(
  image: BlokImage | StaticImageData | ImageItem | string,
) {
  if (typeof image === "object" && "filename" in image) {
    const hasSpecialExtension = extensions.some((ext) =>
      image.filename.endsWith(ext),
    )
    if (hasSpecialExtension) {
      return `${image.filename}/m/`
    }
    if (image.filename.includes("/m/")) {
      return image.filename
    }

    return `${image.filename}m/`
  }

  if (typeof image === "object" && "src" in image) {
    return image.src
  }

  return image
}
