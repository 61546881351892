import { SbBlokData } from "@storyblok/react"

import PetMeetDrivers from "../../../sections/meet-drivers"

import nicoMicax2 from "../../../assets/images/meet-drivers/nico-mica@2x.webp"
import driver2x2 from "../../../assets/images/meet-drivers/driver-img-2@2x.webp"
import driver3x2 from "../../../assets/images/meet-drivers/driver-img-3@2x.webp"
import { BlokImage } from "../../types"

export interface IPillarsMeetDrivers extends SbBlokData {
  id?: string
  title: string
  theme: string
  drivers: Array<{
    shipments: string
    image: BlokImage
    content: string
    hasLongContent: boolean
    ratingCompany: string
    ratingValue: number
  }> &
    SbBlokData
}

const petDrivers = [
  {
    shipments: "16 completed",
    image: {
      src: nicoMicax2,
      width: 552,
      height: 508,
      alt: "Nico and Mica drivers",
    },
    content: `Nico & Mica were amazing! They transported my silver lab puppy from MN to ME... They’re extremely on top of keeping you in the loop with the whereabouts in travel they are with your pup and very understanding when you ask lots of questions! I’d use them again in a heartbeat and I highly recommend them to anyone. Thanks again Nico & Mica ❤️`,
    rating: { company: "Nico & Mica Transport", value: 5 },
  },
  {
    shipments: "385 completed",
    image: {
      src: driver2x2,
      width: 578,
      height: 476,
    },
    alt: "Bill and Erica Driver",
    content: (
      <>
        I can’t say enough about Bill and Erica! I adopted a 1 year old Cane
        Corso from an owner who needed to have it out ASAP. I put my request on
        the site and Bill responded with a great message. Right off the bat I
        knew what to expect, questions I should ask.{" "}
        <span>
          This was my first time doing this, so I had a lot of questions. Bill
          answered them all quickly. Once I hired them through the site, they
          made everything easy. From texting the minute they hit the road, to
          pics, and updates where they were at on the road. My puppy got here
          super happy, and I could tell he had enjoyed his time with Bill and
          Erica. I highly recommend them!
        </span>
      </>
    ),
    longContent: true,
    rating: { company: "Libertys Transport", value: 5 },
  },
  {
    shipments: "164 completed",
    image: {
      src: driver3x2,
      width: 570,
      height: 516,
    },
    alt: "Bailey The Driver",
    content: `Wow—this was a great experience! The word ‘care’ in the company name isn’t just a throw-in. It shows in how Shaun & the team help to make transporting a family member less stressful especially if you [like us] have never done this before. Special thanks to our driver, Larry. He’s caring, professional, flexible and someone we felt immediately comfortable with entrusting our daughter’s dog for a 3-day trip. Hard to see how you could do better than Bailey’s Best Care!`,
    rating: { company: `Bailey's Best Care`, value: 5 },
  },
]
export default function PillarsMeetDrivers({
  blok,
}: {
  blok: IPillarsMeetDrivers
}) {
  const { drivers, theme, title, id } = blok
  const driversData =
    drivers?.map((item) => ({
      ...item,
      longContent: item.hasLongContent,
      image: { ...item.image, src: item.image?.filename },
      rating: { company: item.ratingCompany, value: Number(item.ratingValue) },
    })) ?? []
  const data = driversData.length > 0 ? driversData : petDrivers
  return (
    <section id={id}>
      <PetMeetDrivers drivers={data} theme={theme} title={title} />
    </section>
  )
}
