import React from "react"
import { createUseStyles } from "react-jss"
import dynamic from "next/dynamic"
import { Container, Row, Col } from "../components/layout/grid"
import CtaButton from "../components/cta-button"
import RichSnippets from "../components/rich-snippets"
import { ReviewsContextProvider } from "../context/ReviewsContext"
import colors from "../styles/colors"
import { mediaQueries } from "../styles/breakpoints"
import { fontSize } from "../styles/fonts-constants"
import { env } from "../config"
const DriverReviews = dynamic(() => import("../components/driver-reviews"))

const useStyles = createUseStyles({
  reviewsCtaSnippets: {
    maxWidth: "1280px",
    margin: "auto",
    [mediaQueries.tablet]: {
      width: "95%",
    },
    [mediaQueries.desktop]: {
      width: "95%",
      maxHeight: 1500,
    },
    [mediaQueries.largeDesktop]: {
      paddingLeft: 30,
      paddingRight: 30,
    },
  },
  reviewsTitle: {
    fontFamily: "var(--font-montserrat), sans-serif",
    fontSize: "24px",
    color: colors.blue7,
    margin: "40px 0",
    textAlign: "center",
    fontWeight: "600",

    [mediaQueries.tablet]: {
      fontSize: fontSize.extraLarge,
      lineHeight: "44px",

      color: ({ darkBlueTheme }) =>
        darkBlueTheme ? colors.blue7 : colors.blue1,
      textAlign: "center",
      margin: "80px 0 40px",
    },
  },
  reviewCardsRow: {
    display: "flex",
    justifyContent: "space-around",
    overflow: "hidden",
    position: "relative",
    [mediaQueries.desktop]: {
      maxHeight: 800,
    },
    [mediaQueries.largeDesktop]: {
      maxHeight: 790,
    },
  },
  desktopOnly: {
    display: "none",
    [mediaQueries.desktop]: {
      display: "flex",
      justifyContent: "space-around",
      width: "100%",
    },
  },
  reviewCardsRowGradient: {
    position: "absolute",
    bottom: -1,
    right: 0,
    left: 0,
    width: "100%",
    display: "none",
    [mediaQueries.desktop]: {
      display: "flex",
      minHeight: 220,
      background: `linear-gradient(180deg, transparent 0%, ${colors.white1} 80%)`,
    },
    [mediaQueries.largeDesktop]: {
      minHeight: 250,
    },
  },
})

const ReviewsCtaSnippets = (props) => {
  const {
    title,
    ctaButtonTitle,
    ctaButtonLink,
    darkBlueTheme,
    snippetLinkBlue1,
    driverReviews,
    additionalDriverReviews,
    showSnippets,
    showCta,
  } = props

  const classes = useStyles(props)

  return (
    <ReviewsContextProvider>
      <div className={classes.reviewsCtaSnippets}>
        <Container>
          <Row>
            <Col md={12}>
              <h2 className={classes.reviewsTitle}>{title}</h2>
            </Col>
          </Row>
          <Row className={classes.reviewCardsRow}>
            <DriverReviews
              driverReviews={driverReviews}
              darkBlueTheme={darkBlueTheme}
            />
            <div className={classes.reviewCardsRowGradient} />
            <div className={classes.desktopOnly}>
              <DriverReviews
                applyAdditional
                additionalDriverReviews={additionalDriverReviews}
                darkBlueTheme={darkBlueTheme}
              />
            </div>
          </Row>
          <Row>
            <Col md={12}>
              {!showCta ? null : (
                <CtaButton
                  title={ctaButtonTitle}
                  link={ctaButtonLink}
                  darkBlueTheme={darkBlueTheme}
                />
              )}
            </Col>
          </Row>
          {showSnippets ? (
            <Row>
              <RichSnippets snippetLinkBlue1={snippetLinkBlue1} withWrapper />
            </Row>
          ) : null}
        </Container>
      </div>
    </ReviewsContextProvider>
  )
}
export async function getServerSideProps() {
  return {
    props: {
      host: {
        app: env("APP_HOST"),
        website: env("WEBSITE_HOST"),
        token: env("STORYBLOK_API_KEY"),
      },
    },
  }
}

ReviewsCtaSnippets.defaultProps = {
  snippetLinkBlue1: false,
  darkBlueTheme: false,
  showSnippets: true,
}

export default ReviewsCtaSnippets
