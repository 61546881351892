import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from ".."
import Markdown from "../../components/content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IMotoIntro extends SbBlokData {
  heading: string
  markdown: string
  image: BlokImage
}

export default function MotoIntro({ blok }: { blok: IMotoIntro }) {
  const { heading, markdown, image } = blok

  return (
    <section
      className="relative page-padding w-full flex md:justify-evenly py-10 md:py-32 mb-20 md:items-center bg-gray11 md:space-x-28"
      {...storyblokEditable(blok)}
    >
      <div className="hidden md:block relative">
        <Image
          className="object-contain"
          src={readImage(image)}
          alt={image.description || image.alt}
          width={360}
          height={1}
        />
      </div>
      <article className="md:w-3/5 w-full">
        <h3 className="mb-5 font-montserrat font-medium text-4xl text-blue7 ">
          {heading}
        </h3>

        <Markdown className="prose-p:text-xl prose-p:font-normal prose-p:font-roboto">
          {markdown}
        </Markdown>
        <div className="md:hidden relative h-96 w-full my-8">
          <Image
            className="object-contain"
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
          />
        </div>
      </article>
    </section>
  )
}
