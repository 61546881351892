import { SbBlokData } from "@storyblok/react"
import { createUseStyles } from "react-jss"
import Image from "next/image"
import { BlokImage } from ".."

import { IPrimaryCardProps } from "../../components/cards/PrimaryCard"

import colors from "../../styles/colors"
import { mediaQueries } from "../../styles/breakpoints"

import HeroShippingForm from "../sections/HeroShippingForm"
import PageNav, { NavigationType } from "../sections/PageNav"
import { readImage } from "../../app/utils/read-image"

export interface HeroShowCaseBlok {
  hasSelectCategory?: boolean
  hasPickupInput?: boolean
  hasDeliveryInput?: boolean
  categoryOptions?: { label: string; value: string }[]
  selectCategoryPlaceholder?: string
  hasEmail?: boolean
  footer?: string
  buttonLabel?: string
  buttonLink?: string
}
export interface HeroSectionProps extends HeroShowCaseBlok, SbBlokData {
  heading: string
  details: string
  image?: BlokImage
  cards: Array<IPrimaryCardProps & SbBlokData>
  navItems: NavigationType["navItems"]
  categories: NavigationType["categories"]
  isNavHidden?: NavigationType["isNavHidden"]
  mobileSubNavTitle?: NavigationType["mobileSubNavTitle"]
  ctaRoute?: string
  navButtonLabel?: string
}
const useStyles = createUseStyles({
  root: {
    background: "white",
    width: "100%",
    margin: 0,
    padding: 24,
    borderRadius: 8,
    boxShadow: "0px 15px 75px rgba(6, 17, 46, 0.15);",
    [mediaQueries.desktop]: {
      maxWidth: 540,
    },
  },
  heading: {
    fontFamily: "var(--font-montserrat), sans-serif",
    fontWeight: "bold",
    fontSize: 32,
    lineHeight: "140%",
    color: colors.gray8,
    margin: "0 0 24px 0",
    [mediaQueries.tablet]: {
      fontSize: 40,
      margin: "0 0 16px 0",
    },
  },
  paragraph: {
    fontFamily: "var(--font-montserrat), sans-serif",
    fontWeight: 500,
    fontSize: 17,
    color: colors.gray9,
    margin: "0 0 24px 0",
  },
})
const HeroSection = ({
  blok,
  host,
}: {
  blok: HeroSectionProps
  host?: Record<string, string>
}) => {
  const {
    hasEmail,
    hasSelectCategory,
    hasPickupInput,
    selectCategoryPlaceholder = "What are you shipping",
    hasDeliveryInput,
    categoryOptions,
    image,
    heading,
    details,
    footer,
    buttonLabel,
    buttonLink,
    navItems,
    categories,
    isNavHidden,
    ctaRoute,
    navButtonLabel,
    mobileSubNavTitle,
  } = blok

  const classes = useStyles()
  return (
    <>
      <PageNav
        buttonLabel={navButtonLabel}
        route={ctaRoute}
        navigationData={{
          navItems,
          categories,
          isNavHidden,
          mobileSubNavTitle,
        }}
        host={host}
      />
      <header className="pb-12 sm:p-4 pt-[120px] lg:py-8 md:mt-16 lg:mt-24 relative xl:min-h-[900px] 2xl:pb-8 overflow-hidden">
        <div className="relative lg:absolute right-4 lg:right-0 top-0 lg:w-[70%] xl:w-[53%] lg:h-[85vh] w-full h-[320px]">
          {image && (
            <Image
              className="object-cover rounded-lg"
              src={readImage(image)}
              alt={image.description || image.alt}
              fill
            />
          )}
        </div>
        <section className="z-50 relative mx-auto md:max-w-[600px] lg:max-w-[1380px] lg:pb-5 lg:pt-[75px] md:translate-y-[-30px] lg:translate-y-0">
          <div className={classes.root} id="hero-section">
            <h1 className={classes.heading}>{heading && heading}</h1>
            <p className={classes.paragraph}>{details && details}</p>
            <HeroShippingForm
              blok={{
                hasEmail,
                hasSelectCategory,
                hasPickupInput,
                selectCategoryPlaceholder,
                hasDeliveryInput,
                categoryOptions,
                footer,
                buttonLabel,
                buttonLink,
              }}
            />
          </div>
        </section>
      </header>
    </>
  )
}
export default HeroSection
