import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Markdown from "../components/content/Markdown"
import ListItem from "../components/content/ListItem"
import { BlokImage } from "."

export interface IListSubsection extends SbBlokData {
  header: string
  listItems: {
    icon: BlokImage
    content: string
  }[]
  markdown: string
}

export default function ListSubsection({ blok }: { blok: IListSubsection }) {
  const { header, listItems, markdown } = blok

  return (
    <section className="w-full" {...storyblokEditable(blok)}>
      {header && <h5 className="text-xl font-semibold mb-4">{header}</h5>}
      <aside className="flex flex-col flex-wrap 2xl:h-48 space">
        {listItems.map((item, i) => (
          <ListItem
            icon={item.icon}
            content={item.content}
            key={`MarkdownAndDetails/Details${i}`}
          />
        ))}
      </aside>
      <Markdown className="prose-p:text-xl">{markdown}</Markdown>
    </section>
  )
}
