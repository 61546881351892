import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { AiOutlineCheckCircle } from "react-icons/ai"

export interface ICheckList extends SbBlokData {
  heading: string
  content: Array<
    {
      content: string
    } & SbBlokData
  >
}

export default function CheckList({
  blok,
  className,
}: {
  blok: ICheckList
  className: string
}) {
  const { heading, content } = blok

  return (
    <section
      className={
        "relative mx-auto page-padding md:px-0 w-full max-w-prose flex flex-col md:items-center " +
        className
      }
      {...storyblokEditable(blok)}
    >
      {heading && (
        <h3 className="mb-8 text-4xl text-blue7 text-left md:text-center font-montserrat font-medium">
          {heading}
        </h3>
      )}
      <ul className="px-0">
        {content.map((item, i) => (
          <li key={i} className="flex">
            <AiOutlineCheckCircle className="h-8 w-8 min-w-[2rem] mr-5 fill-green5" />
            <p className="grow text-xl">{item.content}</p>
          </li>
        ))}
      </ul>
    </section>
  )
}
