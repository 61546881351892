import { FC } from "react"
import Image from "next/image"
import { SbBlokData } from "@storyblok/react"
import { marked } from "marked"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

export interface IPremiumOurPromise extends SbBlokData {
  blok: {
    header: string
    subheader: string
    blocks: Array<
      {
        icon: BlokImage
        title: string
        markdown: string
      } & SbBlokData
    >
    customClasses: string
  }
}

const OurPromise: FC<IPremiumOurPromise> = (props) => {
  const { header, subheader, blocks, customClasses } = props.blok
  return (
    <section
      id="our-promise"
      className="px-6 md:px-8 lg:px-16 max-w-[1400px] w-full mx-auto mt-4 pt-8 lg:pt-10"
    >
      <article
        className={`my-4 lg:my-24 text-left lg:text-center ${customClasses}`}
      >
        <h3 className="font-merriweather font-normal text-4xl mb-6 text-center leading-[72px] max-w-4xl mx-auto">
          {header}
        </h3>
        <div className="max-w-4xl mb-16 mx-auto text-center text-2xl leading-9">
          {subheader}
        </div>
        <section className="grid grid-cols-1 md:grid-cols-2 xl:px-20 gap-6">
          {blocks?.map(({ icon, title, markdown }, i) => (
            <div
              key={i}
              className="p-6 md:p-8 border border-black font-merriweather text-left"
            >
              <Image
                className="w-16 h-16 mb-4 object-cover"
                alt={icon.alt}
                src={readImage(icon)}
                height={64}
                width={64}
                loading="lazy"
              />

              {title && <h4 className="text-2xl text-inherit mb-6">{title}</h4>}
              <div
                dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }}
                className="max-w-none font-inter prose-h4:text-xl prose-h4:text-black prose-h4:font-normal prose-p:text-[16px]"
              />
            </div>
          ))}
        </section>
      </article>
    </section>
  )
}
export default OurPromise
