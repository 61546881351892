export const fontWeight = {
  light: 300,
  normal: 400,
  bold: 500,
  extraBold: 600,
  superBold: 700,
}

export const fontSize = {
  extraTiny: 12,
  tiny: 14,
  extraSmall: 16,
  small: 18,
  medium: 20,
  extraMedium: 22,
  normal: 24,
  large: 32,
  extraLarge: 36,
  huge: 38,
  extraHuge: 48,
}
