import { HTMLProps, ReactNode, useRef, useState } from "react"
import { HiChevronDown } from "react-icons/hi"
import useOnOutsideCLick from "../../util/useOnOutsideClick"

interface IOption {
  value: string
  label: string
  icon?: ReactNode
}

export interface ISelectProps
  extends Omit<HTMLProps<HTMLSelectElement>, "placeholder" | "onSelect"> {
  options: Array<IOption | string>
  placeholder: Omit<IOption, "value">
  name: string
  className?: string
  invalid?: boolean
  onSelect?: (option: IOption) => void
}

export default function Select({
  options,
  placeholder,
  name,
  className,
  invalid,
  onSelect,
  ...props
}: ISelectProps) {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState<IOption | undefined>()

  const ref = useRef<HTMLFieldSetElement>(null)

  useOnOutsideCLick(ref, () => setOpen(false))

  const handleSelect = (option: IOption) => {
    setSelected(option)
    onSelect?.(option)
    setOpen(false)
  }

  return (
    <>
      <fieldset
        ref={ref}
        onClick={() => setOpen(!open)}
        className={
          "relative w-full invalid:mb-4 py-4 outline-none bg-blue16 z-50 text-black border-b-2 border-transparent appearance-none invalid:text-red-500 invalid:border-red-500 cursor-pointer" +
          " " +
          className
        }
      >
        <select
          name={name}
          id={name}
          className="hidden peer"
          value={selected?.value}
          required={invalid}
          {...props}
        >
          <option value="" />
          {options.map((option) => {
            return (
              typeof option !== "string" && (
                <option
                  value={option.value}
                  key={`Select/${name}/selected-option/${option.value}`}
                >
                  {option.label}
                </option>
              )
            )
          })}
        </select>
        <label
          className={
            "w-full px-4 flex items-center gap-4 text-base peer-invalid:text-red-500 cursor-pointer " +
            " " +
            (selected ? "" : "text-blue5/60")
          }
        >
          {selected?.icon || placeholder.icon}
          {selected?.label || placeholder.label}
          <HiChevronDown className="ml-auto h-6 w-6 text-black5" />
        </label>
        <p className="hidden peer-invalid:block absolute -bottom-6 m-0 ml-14 font-montserrat text-sm text-red-500">
          Please select an option
        </p>
        <ul
          className={
            (open ? "" : "hidden") +
            " " +
            "z-10 absolute left-0 top-full max-h-80 h-fit w-full p-0 m-0" +
            " " +
            "flex flex-col list-none" +
            " " +
            "bg-white text-black drop-shadow overflow-y-auto"
          }
        >
          {options.map((option) => {
            if (typeof option === "string") {
              return (
                <li
                  key={`Select/${name}/option/${option}`}
                  className="list-none w-full py-2 px-4 text-sm text-gray9 cursor-default"
                >
                  {option}
                </li>
              )
            } else {
              return (
                <li
                  className="list-none"
                  key={`Select/${name}/option/${option.value}`}
                >
                  <span
                    id={option.value}
                    className="w-full py-2 px-4 flex items-center gap-4 text-base hover:bg-blue16 hover:cursor-pointer"
                    onClick={() => handleSelect(option)}
                  >
                    {option.icon}
                    {option.label}
                  </span>
                </li>
              )
            }
          })}
        </ul>
      </fieldset>
    </>
  )
}
