import React, { FC, HTMLAttributes } from "react"

import dynamic from "next/dynamic"
import { SbBlokData } from "@storyblok/react"

import { BlokImage } from ".."

const Reviews = dynamic(() => import("../../sections/homepage-reviews"))
const Showcase = dynamic(() => import("../../sections/homepage-showcase"))
const Offer = dynamic(() => import("../../sections/homepage-offer"))

export type ShipmentsDataType = {
  id: number
  imageSrc: BlokImage
  amount: number
  breed: string
  mileage: string
  city: string
}
export interface IShowCaseProps {
  title?: string
  data: ShipmentsDataType[]
  matchTitle?: string
  matchSubtitle?: string
  feedback?: string
  authorName?: string
  platform?: string
}
export type ShowCaseCardProps = IShowCaseProps & HTMLAttributes<HTMLElement>
export type ReviewItem = {
  reviewer: string
  icon: BlokImage
  title: string
  content: string
  images?: string
  category: string
  reviewerIcon?: {
    src: string
    width: number
    height: number
  }
}
export interface IHomeContent extends SbBlokData, IShowCaseProps {
  title: string
  buttonTitle: string
  buttonLink: string
  hasDarkBlueTheme: boolean
  reviews: ReviewItem[]
  reviewCount: string
  reviewTitle: string
}
export type ReviewSnippetsCardProps = ReviewItem & HTMLAttributes<HTMLElement>

export const HomeReviewItem: FC<ReviewSnippetsCardProps> = (props) => {
  return <div {...props} />
}
export const HomePriceShowCaseItem: FC<ShowCaseCardProps> = (props) => {
  return <div {...props} />
}
const HomeContent = ({ blok }: { blok: IHomeContent }) => {
  const {
    reviews,
    title,
    matchSubtitle,
    matchTitle,
    platform,
    feedback,
    authorName,
    data,
    reviewCount,
    reviewTitle,
    buttonLink,
    buttonTitle,
  } = blok

  const reviewsData =
    reviews?.map((review) => ({
      ...review,
      reviewerIcon: { src: review.icon.filename, width: 593, height: 605 },
    })) || []
  return (
    <>
      <Reviews
        reviews={reviewsData}
        reviewCount={reviewCount}
        reviewTitle={reviewTitle}
        buttonLink={buttonLink}
        buttonTitle={buttonTitle}
      />
      <Showcase
        title={title}
        data={data}
        matchTitle={matchTitle}
        matchSubtitle={matchSubtitle}
        feedback={feedback}
        authorName={authorName}
        platform={platform}
      />
      <Offer />
    </>
  )
}

export default HomeContent
