import { FC } from "react"
import Image from "next/image"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

export interface IPremiumAsFeaturedIn {
  blok: {
    header: string
    headerClass?: string
    logos?: {
      image: BlokImage
      width: number
      height: number
    }[]
  }
}
const AsFeaturedIn: FC<IPremiumAsFeaturedIn> = (props) => {
  const { logos, header = "As featured in", headerClass = "" } = props.blok
  return (
    <section
      id="as-featured-in"
      className="max-w-[1400px] w-full mx-auto my-16 md:mt-24 md:mb-20 md:pb-10"
    >
      <h2
        className={`mb-10 md:mb-16 font-montserrat font-normal text-center uppercase ${headerClass}`}
      >
        <div className="flex items-center justify-center md:pt-10">
          <div className="border-t border-gold w-16 mr-2"></div>
          <span className="text-gold text-2xl uppercase font-inter">
            {header}
          </span>
          <div className="border-t border-gold w-16 ml-2"></div>
        </div>
      </h2>
      <div className="flex items-center mx-auto px-8 md:px-3 justify-center gap-y-10 gap-x-12 md:gap-10 flex-wrap xl:flex-nowrap">
        {logos?.map((logo) => (
          <Image
            src={readImage(logo.image)}
            alt={logo.image.alt}
            height={Number(logo.height) || 38}
            width={Number(logo.width) || 172}
            key={logo.image.id}
          />
        ))}
      </div>
    </section>
  )
}
export default AsFeaturedIn
