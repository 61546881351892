import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { BlokImage } from ".."
import Image from "next/image"
import IconCard from "../../components/cards/IconCard"
import PrimaryCard, {
  IPrimaryCardProps,
} from "../../components/cards/PrimaryCard"
import { readImage } from "../../app/utils/read-image"

export interface IShipFactors extends SbBlokData {
  heading: string
  content: string
  blocks: IPrimaryCardProps[]
  image: BlokImage
}

export default function ShipFactors({ blok }: { blok: IShipFactors }) {
  const { heading, content, blocks, image } = blok

  return (
    <article
      className="relative mx-auto page-padding w-full flex flex-col md:items-center space-y-10 bg-white"
      {...storyblokEditable(blok)}
    >
      <h3 className="mb-8 text-4xl text-blue7 text-left md:text-center font-montserrat font-medium">
        {heading}
      </h3>
      {image.filename && (
        <div className=" relative w-full md:w-1/2 max-h-72 sm:max-h-fit h-96">
          <Image
            className="h-fit object-cover rounded-lg "
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
          />
        </div>
      )}
      <p className="md:text-center text-2xl mb-16">{content}</p>
      <section className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {blocks.map(({ icon, markdown }, i) => (
          <PrimaryCard
            icon={icon}
            markdown={markdown}
            key={`shipFactors/${i}`}
            className="bg-white"
            component="primaryCard"
          />
        ))}
      </section>
    </article>
  )
}
