import { HTMLProps } from "react"
import { BlokTable } from "../../storyblok"

interface ITableProps extends HTMLProps<HTMLTableElement> {
  table: BlokTable
}

export default function Table({ table, ...props }: ITableProps) {
  const { tbody, thead } = table

  return (
    <table
      {...props}
      className="mx-auto min-w-fit w-full my-4 border-separate border-spacing-0 border border-gray-500 rounded text-xl overflow-x-auto "
    >
      <thead>
        <tr>
          {thead.map((th, i) => (
            <th
              key={`table/thead${i}`}
              className="border-r border-b last-of-type:border-r-0 px-4 py-2"
            >
              {th.value}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tbody.map((row, i) => (
          <tr
            key={`table/row${i}`}
            className="py-2 border-b last-of-type:border-none"
          >
            {row.body.map((cell, j) => (
              <td
                key={`table/row${i}/cell${j}`}
                className="border-r last-of-type:border-r-0 px-4 py-2"
              >
                {cell.value}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
