import React from "react"
import { createUseStyles } from "react-jss"
import { Container, Row, Col } from "../components/layout/grid"

import FaqContent from "./faq-expand-collapse-content"
import colors from "../styles/colors"
import { mediaQueries } from "../styles/breakpoints"
import { fontWeight, fontSize } from "../styles/fonts-constants"

const useStyles = createUseStyles({
  frequent: {
    flexGrow: 1,
    fontSize: fontSize.tiny,
    paddingTop: 80,
    paddingBottom: 100,
    color: colors.gray,
    overflow: "hidden",
    [mediaQueries.tablet]: {
      paddingTop: 40,
      paddingBottom: 40,
    },
  },
  title: {
    fontFamily: "var(--font-montserrat), sans-serif",
    color: colors.blue7,
    fontWeight: fontWeight.bold,
    lineHeight: 1.22,
    fontSize: "24px",
    marginBottom: "30px",

    textAlign: "left",
    [mediaQueries.tablet]: {
      textAlign: "center",
      fontSize: fontSize.extraLarge,
      marginBottom: 60,
    },
  },
  cntText: {
    fontFamily: "'Roboto', sans-serif",
    lineHeight: 1.38,
    color: colors.black2,
    fontSize: fontSize.normal,
    fontWeight: fontWeight.normal,
  },

  colSpec: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    "& ul": {
      "& li": {
        marginBottom: 12,
      },
    },
    [mediaQueries.tablet]: {
      width: 0,
    },
  },
})

const FaqExpandCollapse = (props) => {
  const { data } = props

  const classes = useStyles()

  return (
    <div className={classes.frequent}>
      <Container>
        <Row>
          <Col sm={12}>
            <h2 className={classes.title}>Frequently Asked Questions</h2>
          </Col>
          <Col className={[classes.colSpec, classes.cntText]}>
            {data.map((question, index) => (
              <FaqContent question={question} key={index} />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FaqExpandCollapse
