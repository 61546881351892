import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"

interface ILeadingThePack extends SbBlokData {}

export default function LeadingThePack({ blok }: { blok: ILeadingThePack }) {
  return (
    <section
      className="relative page-padding w-full"
      {...storyblokEditable(blok)}
    >
      <section className="py-16 px-4 border-2 flex flex-col md:flex-row md:justify-center gap-y-4 gap-x-10 items-center">
        <Image
          className="object-contain w-16 md:w-auto h-auto md:h-auto"
          src="/icons/trophy.svg"
          alt="pet protection"
          height={128}
          width={112}
        />
        <div className="max-w-xl">
          <h3 className="font-montserrat font-normal text-center md:text-left text-xl md:text-3xl lg:text-4xl text-blue7 mb-3">
            We're leading the pack
          </h3>
          <p className="m-0 text-center md:text-left text-base md:text-xl lg:text-2xl">
            CitizenShipper is the first and only shipping platform to offer a
            Pet Protection Plan for every journey.
          </p>
        </div>
      </section>
    </section>
  )
}
