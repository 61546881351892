import Image from "next/image"
import { HTMLAttributes } from "react"
import { AiFillStar } from "react-icons/ai"

export interface ILFLReview {
  reviewer: string
  title?: string
  content: string
  trustpilot: boolean
  rating: string
}

interface ILFLReviewCardProps extends HTMLAttributes<HTMLElement> {
  review: ILFLReview
}

export default function LFLReviewCard({
  review,
  className,
}: ILFLReviewCardProps) {
  const { reviewer, rating, content, title, trustpilot } = review

  return (
    <article
      className={
        "p-8 bg-white shadow-xl text-black text-lg rounded-xl " + className
      }
    >
      <p className="mb-2.5 font-bold">{reviewer}</p>
      <div className="mb-6 flex items-center">
        {[...Array(parseInt(rating))].map((_, i) => (
          <AiFillStar
            key={i}
            className="h-5 w-5 fill-yellow12 stroke-yellow12 last-of-type:mr-auto"
          />
        ))}
        {trustpilot && (
          <Image
            className="object-contain"
            src="/icons/trust-pilot.svg"
            alt="trustpilot"
            height={26}
            width={108}
          />
        )}
      </div>

      {title && <h4 className="text-blue1 font-medium text-xl">{title}</h4>}
      <p className="text-blue5">{content}</p>
    </article>
  )
}
