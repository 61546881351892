import { SbBlokData, storyblokEditable } from "@storyblok/react"

import { BlokImage } from ".."
import Image from "next/legacy/image"
import MeetDriversContent from "../../components/meet-driver-content"
import PawBg from "../../assets/vector/paw.svg"

export interface IMeetTheDrivers extends SbBlokData {
  heading: string
  drivers: IDriver[]
  petProtection?: string
  isNonPets?: boolean
}

interface IDriver extends SbBlokData {
  shipmentsComplete: string
  longContent: boolean
  content: string
  name: string
  rating: string
  image: BlokImage
}

export default function MeetTheDrivers({ blok }: { blok: IMeetTheDrivers }) {
  const {
    heading,
    drivers,
    petProtection = "$1,000 Pet Protection Plan - Included with all pet transports",
    isNonPets,
  } = blok

  return (
    <section
      id="meet-the-drivers"
      className="flex flex-col page-padding w-full"
      {...storyblokEditable(blok)}
    >
      <h3 className="mb-8 text-4xl text-blue7 text-left md:text-center font-montserrat font-medium">
        {heading}
      </h3>

      <MeetDriversContent items={drivers} />
      {!isNonPets && (
        <p className="mx-auto flex justify-center font-medium text-base md:text-lg mt-3 items-baseline md:items-center md:w-full">
          <Image
            height={24}
            width={24}
            src={PawBg.src}
            alt="paw"
            loading="lazy"
          />{" "}
          <span className="pl-2 text-xs md:text-base">{petProtection}</span>
        </p>
      )}
    </section>
  )
}
