import React, { useState, useEffect } from "react"
import { createUseStyles } from "react-jss"

import colors from "../styles/colors"
import { mediaQueries } from "../styles/breakpoints"
import { fontWeight, fontSize } from "../styles/fonts-constants"

const useStyles = createUseStyles({
  wrapCollapsible: {
    margin: "0 auto",
    width: "100%",
  },
  fonts: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.normal,
    lineHeight: "1.42",
    fontWeight: fontWeight.bold,
  },
  title: {
    display: "flex",
    position: "relative",
    fontSize: "16px",
    paddingRight: "20px",
    color: colors.black2,
    marginTop: "20px",
    cursor: "pointer",
    justifyContent: "space-between",
    fontFamily: "var(--font-montserrat), sans-serif",
    "&:hover": {
      color: colors.blue7,
    },
    [mediaQueries.tablet]: {
      fontSize: fontSize.large,
    },
  },
  titleExtend: {
    extend: "title",
    paddingRight: "20px",
  },
  textExtended: {
    extend: "fonts",
    fontSize: "16px",
    fontWeight: fontWeight.normal,
    textAlign: "left",
    color: colors.gray7,
    width: "100%",
    overflow: "hidden",
    marginTop: 25,
    transition: "0.3s",
    "-ms-transition": "0.3s",
    transitionTimingFunction: "cubic-bezier(0.47, 0.03, 0.74, 0.97)",
    "-ms-transition-timing-function": "cubic-bezier(0.47, 0.03, 0.74, 0.97)",
    [mediaQueries.desktop]: {
      width: "90%",
    },
    [mediaQueries.tablet]: {
      fontSize: fontSize.normal,
    },
  },
  text: {
    extend: "textExtended",
    marginTop: "5px",
    height: 0,
    width: "100%",
    [mediaQueries.desktop]: {
      width: "90%",
    },
  },
})

const FaqExpandCollapseContent = (props) => {
  const { question } = props
  const [extend, setExtend] = useState(false)
  const [expHeight, setExpHeight] = useState(0)

  const classes = useStyles()

  useEffect(() => {
    const height = document.getElementById(question.id).clientHeight
    setExpHeight(height)
  })

  return (
    <>
      <div className={classes.wrapCollapsible}>
        <div
          className={extend ? classes.titleExtend : classes.title}
          onClick={() => setExtend(!extend)}
        >
          <p>{question.title}</p>
          <p>{extend ? " -" : "+"} </p>
        </div>
        <div
          className={extend ? classes.textExtended : classes.text}
          style={extend ? { height: expHeight } : null}
        >
          <div
            id={question.id}
            dangerouslySetInnerHTML={{ __html: question.text }}
          />
        </div>
      </div>
    </>
  )
}

export default FaqExpandCollapseContent
