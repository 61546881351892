import React from "react"
import { createUseStyles } from "react-jss"
import Img from "next/legacy/image"
import { Container } from "./layout/grid"

import StarRating from "./star-rating-v2"
import { useReviewsContext } from "../context/ReviewsContext"

import TrustPilotIcon from "../assets/icons/trust-pilot.webp"
import GoogleIcon from "../assets/icons/google.webp"
import star from "../assets/vector/star-new.svg"

import colors from "../styles/colors"
import { fontWeight, fontSize } from "../styles/fonts-constants"
import { mediaQueries } from "../styles/breakpoints"

const useStyles = createUseStyles({
  richSnippet: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.tiny - 1,
    fontWeight: fontWeight.bold,
    color: colors.blue5,
    height: 120,
  },
  richSnippetWrapped: {
    extend: "richSnippet",
    display: "flex",
    borderRadius: "10px",
    textAlign: "left",
    justifyContent: "left",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    alignItems: "center",
    padding: "10px 30px",
    [mediaQueries.tablet]: {
      justifyContent: "center",
      margin: "10px auto 0 auto",
      textAlign: "center",
      borderRadius: "10px",
    },
  },
  richSnippetContent: {
    display: "flex",
    justifyContent: "center",
  },

  contentContainer: {
    display: "flex",
    justifyContent: "left",
    borderRadius: "10px",
    boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.08)",
    padding: "10px 30px",

    [mediaQueries.tablet]: {
      width: "250px",
      justifyContent: "center",
      padding: "10px 0px",
    },
  },
  richSnippetTypeInnerContent: {
    display: "flex",
    padding: "10px 0 ",
    "& a": {
      fontSize: "14px",
    },
  },
  richSnippetImgsWrapper: {
    textAlign: "left",
    "& img": {
      border: "none",
    },
    [mediaQueries.tablet]: {
      textAlign: "center",
      justifyContent: "center",
    },
  },
  richSnippetRatingForeground: {
    padding: "5px 0",
    "& a": {
      textDecoration: "none",
    },
    [mediaQueries.tablet]: {
      fontSize: "16px",
    },
  },
})

const RichSnippets = ({ withWrapper, type, rating, link }) => {
  const classes = useStyles({})
  const { blokReviews } = useReviewsContext()
  return (
    <>
      {type ? (
        <div className={classes.contentContainer}>
          <span itemScope="" itemType="http://schema.org/Product">
            {type === "trustpilot" && (
              <Img src={TrustPilotIcon} alt="TrustPilot" />
            )}
            {type === "google" && <Img src={GoogleIcon} alt="google" />}

            <div className={classes.richSnippetInnerContent}>
              <p
                style={{
                  marginTop: "10px",
                  marginBottom: "0",
                  fontSize: "14px",
                }}
              >
                {type === "trustpilot" ? "Excellent Rating" : "Average Rating"}
              </p>
              <div>
                <div className={classes.richSnippetTypeInnerContent}>
                  <h4 style={{ margin: "0", marginRight: "5px" }}>{rating}</h4>
                  <StarRating
                    rating={5}
                    star={star}
                    alt="rating star"
                    wrapperClass={classes.richSnippetImgsWrapper}
                  />
                </div>
                <a
                  style={{ color: "#1B53E1" }}
                  href={link ? link : "https://citizenshipper.com/reviews"}
                >
                  {type === "trustpilot" && (
                    <>
                      <span itemProp="ratingCount">
                        {blokReviews?.trustpilotReviews}
                      </span>
                      &nbsp;reviews{" "}
                    </>
                  )}
                  {type === "google" && (
                    <>
                      <span itemProp="google">
                        {blokReviews?.googleReviews}
                      </span>
                      &nbsp;reviews{" "}
                    </>
                  )}
                </a>
              </div>
            </div>
          </span>
        </div>
      ) : (
        <Container
          className={
            withWrapper ? classes.richSnippetWrapped : classes.richSnippet
          }
        >
          <div className={classes.richSnippetContent}>
            <span itemScope="" itemType="http://schema.org/Product">
              <div className={classes.richSnippetInnerContent}>
                <StarRating
                  rating={5}
                  star={star}
                  alt="rating star"
                  wrapperClass={classes.richSnippetImgsWrapper}
                />
                <div className={classes.richSnippetRating}>
                  <div className={classes.richSnippetRatingForeground}>
                    <span itemProp="name">Customer satisfaction</span>
                    &nbsp;rating:{" "}
                    <span
                      itemProp="aggregateRating"
                      itemScope=""
                      itemType="http://schema.org/AggregateRating"
                    >
                      <span itemProp="ratingValue">
                        {" "}
                        {blokReviews?.transportServiceRatings}
                      </span>{" "}
                      <br />
                    </span>
                  </div>
                  <a
                    style={{ color: "#1B53E1" }}
                    href="https://citizenshipper.com/reviews"
                  >
                    <span itemProp="ratingCount">
                      {" "}
                      {blokReviews?.transportServiceReviews}
                    </span>{" "}
                    reviews
                  </a>
                </div>
              </div>
            </span>
          </div>
        </Container>
      )}
    </>
  )
}

export default RichSnippets
