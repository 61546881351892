import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { BlokTable } from "../.."
import Markdown from "../../../components/content/Markdown"

export interface ITableSubsection extends SbBlokData {
  header: string
  markdown: string
  table: BlokTable
}

export default function TableSubsection({ blok }: { blok: ITableSubsection }) {
  const { header, markdown, table } = blok

  const { thead, tbody } = table

  return (
    <section className="relative w-full">
      {header && (
        <h3 className="text-center text-4xl text-blue7 mb-10">{header}</h3>
      )}
      {markdown && <Markdown className="mb-10">{markdown}</Markdown>}
      {table && (
        <table className="mx-auto min-w-fit w-full my-4 border-separate border-spacing-0 border border-gray-500 rounded text-xl overflow-x-auto ">
          <thead>
            <tr>
              {thead.map((th, i) => (
                <th
                  key={`table/thead${i}`}
                  className="border-r border-b last-of-type:border-r-0 px-4 py-2 text-blue7 text-2xl"
                >
                  {th.value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tbody.map((row, i) => (
              <tr
                key={`table/row${i}`}
                className="py-2 border-b last-of-type:border-none"
              >
                {row.body.map((cell, j) => (
                  <td
                    key={`table/row${i}/cell${j}`}
                    className="border-r border-b last-of-type:border-r-0 px-4 py-2"
                  >
                    {cell.value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </section>
  )
}
