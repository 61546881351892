import React, { useState } from "react"
import { fontSize, fontWeight } from "../styles/fonts-constants"

import Image from "next/legacy/image"
import MeetDriversContent from "../components/meet-driver-content"
import PawBg from "../assets/vector/paw.svg"
import PropTypes from "prop-types"
import colors from "../styles/colors"
import { createUseStyles } from "react-jss"
import { mediaQueries } from "../styles/breakpoints"

const useStyles = createUseStyles({
  meetDriver: {
    margin: "40px 0",
    padding: "50px 8px",
    background: "rgba(27,83,225,0.08)",
    [mediaQueries.tablet]: {
      padding: "20px 0",
    },
    [mediaQueries.desktop]: {
      marginTop: 100,
      minHeight: 830,
    },
    [mediaQueries.largeDesktop]: {
      minHeight: 550,
    },
  },
  meetDriverHomepage: {
    marginBottom: 96,
    [mediaQueries.desktop]: {
      marginBottom: 136,
    },
  },
  meetDriverTitle: {
    fontFamily: "var(--font-montserrat), sans-serif",
    fontWeight: 400,
    fontSize: 24,
    color: colors.blue7,
    marginTop: 30,
    marginBottom: 30,
    textAlign: "center",

    [mediaQueries.tablet]: {
      fontSize: fontSize.extraLarge,
      lineHeight: "44px",
      color: colors.blue7,
      marginBottom: 24,
      textAlign: "center",
    },
  },
  meetDriverTitleHomepage: {
    extend: "meetDriverTitle",
    fontFamily: '"Montserrat", sans-serif',
    fontSize: fontSize.extraLarge,
    lineHeight: "44px",
    fontWeight: fontWeight.extraBold,
    color: colors.gray8,
    textAlign: "center",
    [mediaQueries.desktop]: {
      fontSize: fontSize.extraHuge,
      lineHeight: "112%",
    },
  },
  meetDriverMain: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [mediaQueries.desktop]: {
      flexDirection: "row",
      justifyContent: "center",
    },
  },
  leftColumn: {
    flex: 1,
    height: "100vw",
    maxHeight: 500,
    [mediaQueries.largeMobile]: {
      padding: 20,
    },
  },

  quote: {
    display: "none",
    position: "absolute",
    width: 28,
    marginLeft: -33,
    marginTop: 5,
    [mediaQueries.desktop]: {
      display: "flex",
      width: 38,
      marginLeft: -50,
      marginTop: 5,
    },
  },

  rightColumn: {
    flex: 1,
    position: "relative",
    zIndex: 3,
    [mediaQueries.tablet]: {
      padding: 20,
    },
  },

  effectAppear: {
    animationName: "$appear",
    animationDuration: "0.3s",
    animationTimingFunction: "ease-in-out",
    animationFillMode: "forwards",
    [mediaQueries.tablet]: {
      animationDuration: "0.5s",
    },
  },
  "@keyframes appear": {
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  },

  meetDriverShipments: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.tiny,
    lineHeight: "22px",
    marginTop: 8,
    marginBottom: 8,
    "& > span": {
      fontWeight: fontWeight.bold,
    },
  },
  meetDriverShipmentsHomepage: {
    extend: "meetDriverShipments",
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: fontWeight.bold,
    fontSize: fontSize.medium,
    lineHeight: "22px",
    color: colors.blue5,

    "& span": {
      fontWeight: fontWeight.extraBold,
      color: colors.gray8,
    },
  },
  meetDriverContent: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: 16,
    fontWeight: fontWeight.light,
    lineHeight: "24px",
    color: colors.blue5,
    marginBottom: 30,
    animation: "0.5s ease-in-out 0s 1 forwards $slideFromRight",
    [mediaQueries.largeMobile]: {
      fontSize: fontSize.normal,
      lineHeight: 1.25,
    },
    [mediaQueries.tablet]: {
      fontSize: "20px",

      fontWeight: fontWeight.normal,
    },
  },

  meetDriverContentHomepage: {
    fontFamily: '"Montserrat", sans-serif',
    fontWeight: fontWeight.bold,
    fontSize: fontSize.normal,
    lineHeight: "34px",
    color: colors.gray8,
    marginBottom: 30,
    animation: "0.5s ease-in-out 0s 1 forwards $slideFromRight",
    [mediaQueries.desktop]: {
      fontSize: fontSize.small,
      lineHeight: "26px",
    },
  },

  "@keyframes slideFromRight": {
    "0%": { transform: "translate(40px, 0px)" },
    "100%": { transform: "translate(0px, 0px)" },
  },

  meetDriverContentLess: {
    extend: "meetDriverContent",
    marginBottom: 20,

    "& > span": {
      display: "none",
    },
  },

  meetDriverContentBottomWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  meetDriverRating: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.medium,
    fontWeight: fontWeight.bold,
    lineHeight: "28px",
    color: colors.blue5,
  },
  meetDriverCompanyMobile: {
    [mediaQueries.desktop]: {
      display: "none",
    },
  },
  readMoreBtn: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.medium,
    fontWeight: fontWeight.normal,
    color: ({ darkBlueTheme }) => (darkBlueTheme ? colors.blue7 : colors.blue1),
    border: "none",
    backgroundColor: "unset",
    padding: 0,

    "&:hover": {
      cursor: "pointer",
      color: colors.blue15,
    },

    "&:focus": {
      outline: "0",
      border: "none",
    },
  },
  displayHidden: {
    display: "none",
  },

  meetDriverButtonV2: {
    extend: "meetDriverButtonV1",
    width: 60,
    height: 60,
    [mediaQueries.desktop]: {
      width: 80,
      height: 80,
    },
  },
})

const MeetDriver = ({
  drivers,
  theme,
  title,
  isNonPets = false,
  petProtection = "$1,000 Pet Protection Plan - Included with all pet transports",
}) => {
  const [state, setState] = useState({
    driverId: 0,
    readMore: false,
  })

  const classes = useStyles({ theme })

  const themedClasses = {
    homepageTheme: {
      meetDriver: classes.meetDriverHomepage,
      title: classes.meetDriverTitleHomepage,
      companyName: classes.meetDriverCompanyNameHomepage,
      content: classes.meetDriverContentHomepage,
      shipments: classes.meetDriverShipmentsHomepage,
      meetDriverArrows: classes.meetDriverArrowsHomepage,
    },
    default: {
      meetDriver: classes.meetDriver,
      title: classes.meetDriverTitle,
      companyName: classes.meetDriverCompanyName,
      content: classes.meetDriverContent,
      shipments: classes.meetDriverShipments,
      meetDriverArrows: classes.meetDriverArrows,
    },
  }
  const formattedDrivers = drivers.map((driver) => ({
    ...driver,
    name: driver.rating.company,
    rating: driver.rating.value,
    shipmentsComplete: driver.shipments,
  }))
  return (
    <div className={themedClasses[theme].meetDriver} id="drivers">
      <div className={themedClasses[theme].title}>{title}</div>
      <div className="md:max-w-3xl mx-auto">
        <MeetDriversContent items={formattedDrivers} />
      </div>
      {!isNonPets && (
        <p className="mx-auto flex justify-center font-medium text-base md:text-lg mt-3 items-baseline md:items-center md:w-full">
          <Image
            height={24}
            width={24}
            src={PawBg.src}
            alt="paw"
            loading="lazy"
          />{" "}
          <span className="pl-2 text-xs md:text-base">{petProtection}</span>
        </p>
      )}
    </div>
  )
}

MeetDriver.propTypes = {
  theme: PropTypes.string,
}

MeetDriver.defaultProps = {
  theme: "default",
  title: "Meet the drivers",
}

export default MeetDriver
