import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { AiOutlineCheckCircle } from "react-icons/ai"

interface IPreparingForHouseholdGoodsShipping extends SbBlokData {}

export default function PreparingForHouseholdGoodsShipping({
  blok,
}: {
  blok: IPreparingForHouseholdGoodsShipping
}) {
  return (
    <section
      className="relative page-padding w-full flex flex-col lg:flex-row gap-20 bg-gray3"
      {...storyblokEditable(blok)}
    >
      <article className="lg:w-2/3">
        <h3 className="mb-6 font-montserrat text-blue7 text-4xl">
          How to ship household goods with Citizenshipper
        </h3>
        <p className="mb-12 text-2xl">
          You may wonder{" "}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a
            href="https://citizenshipper.com/how-it-works"
            target="_blank"
            rel="noopener"
          >
            how to ship
          </a>{" "}
          an item using a two-way marketplace like CitizenShipper. The process
          is straightforward and begins when you post a listing to ship
          household goods on our marketplace.
        </p>
        <ul className="p-0 text-xl flex flex-col gap-6">
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            It’s free to post a listing on CitizenShipper and get quotes from
            household goods movers to find out how much to ship a TV.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Include details like the pick-up and drop-off locations, the size
            and type of household goods you need shipped.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            Most listings quickly receive multiple bids.
          </li>
          <li className="flex">
            <AiOutlineCheckCircle className="h-9 w-9 min-w-fit mr-4 fill-green5" />
            You can discuss all the details with transporters that bid on your
            listing and find the perfect professional mover for your household
            items.
          </li>
        </ul>
      </article>
      <Image
        className="h-fit w-full max-w-xs mx-auto lg:max-w-none lg:w-1/3"
        src="/images/preparing-for-household-goods-shipping.webp"
        alt="Couple getting ready to use the cheapest way to ship household goods"
        height={376}
        width={300}
      />
    </section>
  )
}
