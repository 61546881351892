const colors = {
  white1: "#ffffff",
  white2: "#EAF1F6",
  white3: "#EAF1F7",
  black1: "#1f1d15",
  black2: "#071525",
  black3: "#25231c",
  black4: "#000000",
  black5: "#29313b",
  black6: "#0000001E",
  black7: "#21252999",
  gray1: "#4a4a4a",
  gray2: "#dbdbdb",
  gray3: "#f9f9f9",
  gray4: "#9b9b9b",
  gray5: "#444444",
  gray6: "#7d7d7d",
  gray7: "#50565e",
  gray8: "#091C4D",
  gray9: "#444C61",
  gray10: "#F0F1F4",
  gray11: "#f6f8fe",
  gray12: "#fafcfe",
  blue1: "#236bbf",
  blue2: "#0069d9",
  blue3: "#0062cc",
  blue4: "#1155ee",
  blue5: "#031d3c",
  blue6: "#1b51ff",
  blue7: "#1B53E1",
  blue8: "#1b64e1",
  blue9: "#3b5998",
  blue10: "#1da1f3",
  blue11: "#f2f5fb",
  blue12: "#1B53FF26",
  blue13: "#0c7bc0",
  blue14: "#212529",
  blue15: "#1748C4",
  blue16: `#F0F4FC`,
  blue17: "#7192E6",
  blue18: "#E8EDF6",
  blue19: "#1645BB",
  blue20: "#123796",
  blue21: "#A0A9C0",
  blue22: "#385993",
  blue23: "#E4E9F5",
  blue24: "#CDD6EC",
  skyblue: "#86a6c8",
  yellow1: "#fffcd0",
  yellow2: "#e8ce22",
  yellow3: "#413e1f",
  yellow4: "#fff9b4",
  yellow5: "#e7d021",
  yellow6: "#e4dd84",
  yellow7: "#d3cb5c",
  yellow8: "#D4CB5C45",
  yellow9: "#FFF9B47A",
  yellow10: "#E8CE2235",
  yellow11: "#FEF7D3",
  orange1: "#fffaec",
  orange2: "#f7c62c",
  red1: "#B00808",
  green1: "#99edc3",
  green2: "#32612d",
  green3: "#c6e0a0",
  green4: "#e3edca",
  secondary900: "#2C1A0A",
}

export default colors
