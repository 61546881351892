import Image from "next/image"
import { storyblokEditable } from "@storyblok/react"
import { HTMLAttributes, FC } from "react"

import { BlokImage, ConditionalBlok } from ".."
import { readImage } from "../../app/utils/read-image"

export type FeaturedInItemType = {
  image: BlokImage
  width?: number
  height?: number
}

export const FeaturedInItem: FC<
  FeaturedInItemType & HTMLAttributes<HTMLElement>
> = (props) => {
  return <div {...props} />
}

type IAsFeaturedInProps = ConditionalBlok<{}> & {
  className?: string
  blok?: {
    className?: string
    headerClass?: string
    logos?: Array<FeaturedInItemType>
  }
}
export default function AsFeaturedIn(props: IAsFeaturedInProps) {
  const { noBlok, blok } = props
  const headerClass = blok?.headerClass || "text-blue7 text-4xl"
  return (
    <section
      className={`relative featured-in-padding w-full flex flex-col ${blok?.className}`}
      {...(!noBlok && storyblokEditable(blok))}
    >
      <h4
        className={`mb-16 font-montserrat font-normal text-center uppercase ${headerClass}`}
      >
        As featured in
      </h4>
      <div className="flex items-center justify-center gap-12 md:gap-10 flex-wrap xl:flex-nowrap">
        {blok?.logos?.map((logo) => (
          <Image
            src={readImage(logo.image)}
            alt={logo.image.alt}
            height={logo.height || 38}
            width={logo.width || 172}
            key={logo.image.id}
          />
        )) || (
          <>
            <Image
              src="/icons/la-times.webp"
              alt="LA Times"
              height={30}
              width={238}
            />
            <Image
              src="/icons/first-for-women.webp"
              alt="First For Women"
              height={40}
              width={124}
            />
            <Image
              src="/icons/the-balance.webp"
              alt="The Balance"
              height={30}
              width={172}
            />
            <Image
              src="/icons/forbes.webp"
              alt="Forbes"
              height={40}
              width={152}
            />
            <Image
              src="/icons/tails-and-toys.webp"
              alt="Tails and Toys"
              height={64}
              width={92}
            />
            <Image
              src="/icons/new-york-times.webp"
              alt="New York Times"
              height={24}
              width={234}
            />
          </>
        )}
      </div>
    </section>
  )
}
