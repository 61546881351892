import { FC } from "react"
import { SbBlokData } from "@storyblok/react"
import { BlokImage } from "../../storyblok"

export interface IWhyCitizenshipper extends SbBlokData {
  blok: {
    title: string
    items: Array<
      {
        title: string
        description: string
      } & SbBlokData
    >
    customClasses: string
  }
}
const icons: JSX.Element[] = [
  <svg
    key="circle"
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 12.66c-3.968 0-7.2 3.195-7.2 7.118 0 3.922 3.232 7.117 7.2 7.117s7.2-3.195 7.2-7.117c0-3.923-3.232-7.117-7.2-7.117Zm3.776 5.694-3.904 4.176c-.192.19-.448.316-.736.316s-.544-.126-.736-.316l-2.176-2.341c-.383-.411-.352-1.012.064-1.392a.985.985 0 0 1 1.409.063l1.472 1.582 3.168-3.385c.384-.38.992-.411 1.408-.063.351.348.383.98.031 1.36Z"
      fill="#1B53E1"
    />
    <path
      opacity=".6"
      d="M34.848 20.537a.948.948 0 0 0 0-1.487l-2.368-1.993c-.32-.253-.416-.696-.288-1.075l1.056-2.879c.192-.569-.16-1.17-.768-1.297l-3.072-.569a.945.945 0 0 1-.8-.79l-.544-3.006c-.096-.6-.736-.949-1.312-.759L23.84 7.726c-.384.126-.832.031-1.088-.285l-2.016-2.34a.973.973 0 0 0-1.504 0l-1.984 2.34c-.256.317-.704.412-1.088.285l-2.912-1.044c-.608-.19-1.216.158-1.312.76l-.576 3.036a.945.945 0 0 1-.8.79l-3.04.538c-.608.095-.96.728-.768 1.297l1.056 2.879c.128.38.032.822-.288 1.075L5.152 19.05a.948.948 0 0 0 0 1.487L7.52 22.53c.32.253.416.696.288 1.075l-1.056 2.879a.96.96 0 0 0 .768 1.265l3.072.538c.416.063.736.38.8.79l.544 3.037c.096.601.736.95 1.312.76l2.912-1.044c.384-.127.832-.032 1.088.284l2.016 2.341a.973.973 0 0 0 1.504 0l2.017-2.34c.256-.317.703-.412 1.087-.285l2.913 1.043a1.013 1.013 0 0 0 1.312-.759l.543-3.037a.945.945 0 0 1 .8-.79l3.073-.538c.608-.095.96-.728.768-1.297l-1.089-2.879c-.128-.38-.032-.822.288-1.075l2.368-1.961ZM20 28.824c-5.056 0-9.152-4.049-9.152-9.046 0-4.998 4.096-9.047 9.152-9.047 5.056 0 9.152 4.049 9.152 9.047S25.056 28.824 20 28.824Z"
      fill="#1B53E1"
    />
  </svg>,
  <svg
    key="quotes"
    width="35"
    height="24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.258 15.545c0 4.137-3.424 7.522-7.61 7.522-4.185 0-7.61-3.385-7.61-7.522C.039 7.233 6.85.501 15.259.501c0 0-4.985 1.843-7.572 7.522 4.185 0 7.572 3.385 7.572 7.522Z"
      fill="#1B53E1"
    />
    <path
      opacity=".6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.71 8.023c4.185 0 7.571 3.385 7.571 7.522 0 4.137-3.424 7.522-7.61 7.522-4.185 0-7.609-3.385-7.609-7.522 0-8.312 6.81-15.044 15.22-15.044 0 0-4.985 1.843-7.572 7.522Z"
      fill="#1B53E1"
    />
  </svg>,
  <svg
    key="timer"
    width="40"
    height="40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m32.269 13.84-.89.889a12.647 12.647 0 0 1 2.55 8.565 12.688 12.688 0 0 1-3.76 8.118 12.968 12.968 0 0 1-9.1 3.72 12.975 12.975 0 0 1-5.242-1.093c-1.65-.725-2.747-1.956-3.955-3.283a.784.784 0 0 1 .07-1.103c.322-.288.438-.097.736.215a11.354 11.354 0 0 0 7.721 3.664c2.96.173 5.87-.811 8.103-2.74a11.127 11.127 0 0 0 3.834-7.57 11.088 11.088 0 0 0-2.637-8.054.784.784 0 0 1 .045-1.072l1.395-1.379a.555.555 0 0 0-.02-.771.571.571 0 0 0-.78-.02l-1.45 1.433a.807.807 0 0 1-1.046.073 11.341 11.341 0 0 0-5.497-2.158.794.794 0 0 1-.714-.784V7.94a.787.787 0 0 1 .23-.567.804.804 0 0 1 .57-.236h.733c.313 0 .566-.25.566-.56 0-.31-.253-.56-.566-.56h-4.199a.563.563 0 0 0-.566.56c0 .31.254.56.566.56h.733c.212 0 .416.083.566.232.15.148.234.349.234.559v2.562c0 .402-.306.74-.71.784a11.367 11.367 0 0 0-6.81 3.3c-.31.312-.405.318-.721.01a.786.786 0 0 1-.012-1.12c1.906-1.926 3.967-3.207 6.653-3.66V8.719a2.167 2.167 0 0 1-1.722-1.138 2.12 2.12 0 0 1 .032-2.048 2.17 2.17 0 0 1 1.757-1.085h4.198a2.17 2.17 0 0 1 1.758 1.085 2.12 2.12 0 0 1 .032 2.048 2.167 2.167 0 0 1-1.722 1.138V9.79c1.788.3 3.493.969 5.001 1.964l.96-.949a2.183 2.183 0 0 1 2.093-.555 2.153 2.153 0 0 1 1.532 1.513c.201.74-.012 1.528-.56 2.07l.01.006ZM21.065 31.788a9.596 9.596 0 0 0 5.803-1.938 9.41 9.41 0 0 0 3.409-5.03 9.311 9.311 0 0 0-.396-6.042 9.447 9.447 0 0 0-4.035-4.554 9.617 9.617 0 0 0-6.007-1.183 9.564 9.564 0 0 0-5.493 2.678.785.785 0 0 0 0 1.12c.313.31.82.31 1.132 0a7.974 7.974 0 0 1 5.606-2.3 7.975 7.975 0 0 1 5.607 2.293 7.79 7.79 0 0 1 2.324 5.543 7.79 7.79 0 0 1-2.324 5.541 7.974 7.974 0 0 1-5.607 2.294 7.974 7.974 0 0 1-5.606-2.3.807.807 0 0 0-1.133 0 .785.785 0 0 0 0 1.12 9.504 9.504 0 0 0 3.083 2.04 9.589 9.589 0 0 0 3.637.718Z"
      fill="#1B53E1"
    />
    <path
      opacity=".6"
      d="M6.029 18.326c0 .21.084.41.234.559.15.148.354.231.566.231h5.555c.442 0 .8-.354.8-.79a.795.795 0 0 0-.8-.791H6.832a.796.796 0 0 0-.8.787l-.003.004Zm9.094 3.833c0-.21-.084-.41-.234-.559a.805.805 0 0 0-.566-.231H8.771c-.442 0-.8.354-.8.79 0 .437.358.791.8.791h5.552a.805.805 0 0 0 .566-.231.787.787 0 0 0 .234-.56Zm-8.291 4.628h5.552c.442 0 .8-.354.8-.79a.795.795 0 0 0-.8-.791H6.832c-.442 0-.8.354-.8.79 0 .437.358.791.8.791Zm18.672-7.68-2.052 2.027c.406.761.416 1.668.028 2.438a2.724 2.724 0 0 1-4.332.698 2.653 2.653 0 0 1-.757-2.32 2.675 2.675 0 0 1 1.463-1.963 2.732 2.732 0 0 1 2.466.028l2.05-2.028a.808.808 0 0 1 1.134 0c.312.31.312.81 0 1.12Zm-3.341 3.27c0-.287-.116-.563-.322-.767a1.104 1.104 0 0 0-1.552 0 1.078 1.078 0 0 0 0 1.535 1.104 1.104 0 0 0 1.551-.001c.206-.203.322-.479.323-.766Z"
      fill="#1B53E1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.093 14.658a7.734 7.734 0 0 0-5.212 2.005 2.55 2.55 0 0 1-3.572-.156 2.48 2.48 0 0 1 .157-3.531 12.824 12.824 0 0 1 8.627-3.317c7.085 0 12.807 5.702 12.807 12.71 0 7.007-5.722 12.709-12.807 12.709a12.823 12.823 0 0 1-8.985-3.654 2.48 2.48 0 0 1-.02-3.535 2.55 2.55 0 0 1 3.575-.02 7.736 7.736 0 0 0 5.43 2.21c4.268 0 7.75-3.44 7.75-7.71s-3.482-7.71-7.75-7.71Z"
      fill="#1B53E1"
    />
    <path
      d="M18.257 5.931h5.529v1.187l-.857.91v2.14h-3.814v-2.14l-.858-.91V5.93ZM29.271 11.481h2.314v2.289h-2.314z"
      fill="#1B53E1"
    />
  </svg>,
]
const WhyCitizenshipper: FC<IWhyCitizenshipper> = (props) => {
  const { title, items } = props.blok
  return (
    <section
      id="why-citizenshipper"
      className="px-4 py-10 md:pt-2 md:pb-14 bg-gray3"
    >
      <div className="max-w-[1320px] mx-auto pt-10 pb-5 md:pt-10">
        <h2 className="text-3xl xl:text-[36px] font-merriweather leading-[54px]  mx-auto w-full text-center mb-6 md:mb-12 ">
          {title}
        </h2>
        <article className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
          {items?.map(({ title, description }, i) => (
            <div className="flex items-start" key={title}>
              <span className="mr-3 md:mr-2 block w-10 h-10">{icons[i]}</span>
              <div className="text-base md:text-md">
                <h3 className="text-xl md:text-[24px] font-merriweather leading-8">
                  {title}
                </h3>
                {description}
              </div>
            </div>
          ))}
        </article>
      </div>
    </section>
  )
}
export default WhyCitizenshipper
