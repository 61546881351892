import { DetailedHTMLProps, HTMLAttributes, FC } from "react"
import { SbBlokData } from "@storyblok/react"
import Image from "next/image"
import classNames from "classnames"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Scrollbar } from "swiper/modules"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"
import { ImageItem } from "../../storyblok/types"
import "swiper/css"
import "swiper/css/navigation"

export interface OfferItem
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  subtitle: string
  href: string
  description?: string
  text: string
  image: BlokImage | ImageItem
}

export interface IOfferCard extends SbBlokData {
  items?: OfferItem[]
  title?: string
}

export const OfferCardItem: FC<OfferItem> = (props) => {
  return <div {...props} />
}

export const OfferCardComponent = (props: IOfferCard) => {
  const { items, title } = props

  return (
    <section className="mx-auto pt-4 pb-6 md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] text-center">
      {title && (
        <h2 className="mx-auto text-xl md:text-2xl font-montserrat my-8 sm:mb-10">
          {title}
        </h2>
      )}
      <ul className="md:hidden grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-8 mx-auto m-0 p-2">
        {items?.map((item, index) => {
          const active = index == 0
          return (
            <li
              key={index}
              className={classNames(
                {
                  "bg-blue7 ": active,
                  "hover:bg-blue7 hover:bg-opacity-10": !active,
                },
                "group font-montserrat hover:shadow-md flex flex-col justify-between no-underline hover:no-underline border border-gray-300 rounded-2xl overflow-hidden min-h-[232px] md:min-h-[295px] p-4",
              )}
            >
              <header className="">
                {item.subtitle && (
                  <h3
                    className={classNames(
                      {
                        "bg-white mx-auto rounded-md inline-block": active,
                      },

                      "text-sm mb-5 py-0 px-2 uppercase tracking-widest",
                    )}
                  >
                    {item.subtitle}
                  </h3>
                )}
                <Image
                  className="mb-6 h-[192px] w-[192px] m-auto rounded-full object-cover"
                  src={readImage(item.image)}
                  alt={item.image.alt}
                  width={192}
                  height={192}
                />
                {item.title && (
                  <h3
                    className={classNames(
                      {
                        "text-white": active,
                        "text-blue7": !active,
                      },
                      "text-xl md:text-2xl lg:text-[30px] p-2 mb-4 font-bold",
                    )}
                  >
                    {item.title}
                  </h3>
                )}
              </header>
              <footer>
                <div className="flex flex-col justify-between min-h-[96px]">
                  <p
                    className={classNames(
                      {
                        "text-white": active,
                      },
                      "m-0",
                    )}
                  >
                    {item.description}
                  </p>
                  <a
                    href={item.href}
                    className={classNames(
                      {
                        "bg-white text-blue7 hover:text-blue7": active,
                        "bg-blue7 text-white hover:text-white": !active,
                      },
                      "text-sm lg:text-base font-semibold hover:no-underline rounded-full py-2 px-4 inline-block mx-auto",
                    )}
                  >
                    {item.text}
                  </a>
                </div>
              </footer>
            </li>
          )
        })}
      </ul>
      <ul className="hidden md:block">
        <Swiper
          spaceBetween={32}
          slidesPerView={3}
          navigation={true}
          scrollbar={true}
          modules={[Navigation, Scrollbar]}
          className="mySwiper"
        >
          {items?.map((item, index) => {
            const active = index == 0
            return (
              <SwiperSlide key={index}>
                <li
                  className={classNames(
                    {
                      "bg-blue7 ": active,
                      "hover:bg-blue7 hover:bg-opacity-10": !active,
                    },
                    "group font-montserrat hover:shadow-md flex flex-col justify-between no-underline hover:no-underline border border-gray-300 rounded-2xl overflow-hidden min-h-[232px] md:min-h-[461px] p-4",
                  )}
                >
                  <header className="">
                    {item.subtitle && (
                      <h3
                        className={classNames(
                          {
                            "bg-white mx-auto rounded-md inline-block": active,
                          },

                          "text-sm mb-5 py-0 px-2 uppercase tracking-widest",
                        )}
                      >
                        {item.subtitle}
                      </h3>
                    )}
                    <div className="h-[192px] w-[192px] object-cover m-auto">
                      <Image
                        className="mb-6 h-[192px] w-[192px] m-auto rounded-full object-cover"
                        src={readImage(item.image)}
                        alt={item.image.alt}
                        width={192}
                        height={192}
                      />
                    </div>
                    {item.title && (
                      <h3
                        className={classNames(
                          {
                            "text-white": active,
                            "text-blue7": !active,
                          },
                          "text-xl md:text-2xl lg:text-[32px] p-2 mb-4 font-bold",
                        )}
                      >
                        {item.title}
                      </h3>
                    )}
                  </header>
                  <footer>
                    <div className="flex flex-col justify-between min-h-[96px]">
                      <p
                        className={classNames(
                          {
                            "text-white": active,
                          },
                          "mb-0",
                        )}
                      >
                        {item.description}
                      </p>
                      <a
                        href={item.href}
                        className={classNames(
                          {
                            "bg-white text-blue7 hover:text-blue7": active,
                            "bg-blue7 text-white hover:text-white": !active,
                          },
                          "text-sm lg:text-base font-semibold hover:no-underline rounded-full py-2 px-4 inline-block mx-auto",
                        )}
                      >
                        {item.text}
                      </a>
                    </div>
                  </footer>
                </li>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </ul>
    </section>
  )
}
const OfferCards = ({ blok }: { blok: IOfferCard }) => (
  <OfferCardComponent {...blok} />
)

export default OfferCards
