import React, { FC } from "react"
import { SbBlokData } from "@storyblok/react"
import Image from "next/image"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper/modules"
import "swiper/css"
import "swiper/css/pagination"

import { BlokImage } from ".."
import StarRating from "../../components/star-rating-v2"
import Verifications from "../../components/verifications"
import star from "../../assets/vector/star-brown.svg"
import { readImage } from "../../app/utils/read-image"

type Image = {
  src: string
  alt: string
}

interface Item {
  content: string
  companyName: string
  rating: string
  image: BlokImage | Image
  shipmentsComplete: string
  longContent?: boolean
  verified?: boolean
  onCallVet?: boolean
  usdaRegistered?: boolean
  insuranceVerified?: boolean
  review: string
}

export interface IItem extends SbBlokData {
  items?: Item[]
  title?: string
  subTitle?: string
}
const Thumbnail: FC<Item> = ({
  image,
  companyName,
  rating,
  shipmentsComplete,
  content,
  verified = true,
  onCallVet = true,
  usdaRegistered = true,
  insuranceVerified = true,
  review,
}) => {
  return (
    <section className="ml-auto w-full  md:min-h-full">
      <div className="grid gap-4 grid-cols-[82px_1fr] items-center">
        <div className="">
          <Image
            className="object-cover rounded w-[82px] h-[82px]"
            src={readImage(image)}
            alt={image?.alt || ""}
            width={82}
            height={82}
          />
        </div>
        <div>
          <div className="text-base font-bold mb-2 font-merriweather text-secondary-900">
            {companyName}
          </div>

          <StarRating
            rating={parseInt(rating)}
            star={{ ...star, width: 16, height: 16 }}
          />
          <h5 className="mb-2.5 mt-2 text-sm font-light">
            {shipmentsComplete} shipments so far
          </h5>
        </div>
      </div>
      <div className="hidden md:block mt-6">
        <Verifications
          verified={verified}
          onCallVet={onCallVet}
          usdaRegistered={usdaRegistered}
          insuranceVerified={insuranceVerified}
          isThumbnail
        />
      </div>

      <p className={`mb-8 mt-6 text-[15px] font-inter`}>”{content}”</p>
      <p className="text-gray14">{review}</p>
    </section>
  )
}

const ThumbnailSet = ({ blok }: { blok: IItem }) => {
  const { items, title, subTitle } = blok
  return (
    <section
      id="thumbnail-set"
      className="max-w-[1334px] mx-auto px-4 my-10 md:my-14 md:pt-10 lg:my-8 md:px-10"
    >
      <p className="font-merriweather mb-8 text-4xl md:text-[40px] pt-12 md:mt-12 md:pt-16 lg:mt-0 lg:pt-2 leading-[54px] mx-auto text-center">
        {title}
      </p>
      <p className="font-inter mb-8 mx-auto text-center text-2xl">{subTitle}</p>
      <div className="hidden lg:grid grid-cols-3 gap-8 auto-rows-max">
        {items?.map((item, index) => (
          <div
            key={index}
            className="border bg-secondary-100 border-secondary-300 p-6 rounded-3xl"
          >
            <Thumbnail
              companyName={item.companyName}
              shipmentsComplete={item.shipmentsComplete}
              rating={item.rating}
              verified={item.verified}
              onCallVet={item.onCallVet}
              usdaRegistered={item.usdaRegistered}
              insuranceVerified={item.insuranceVerified}
              content={item.content}
              review={item.review}
              image={item.image}
            />
          </div>
        ))}
      </div>
      <div className="lg:hidden">
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {items?.map((item, index) => (
            <SwiperSlide key={index}>
              <div
                key={index}
                className="border bg-secondary-100 border-secondary-300 p-6 rounded-3xl mb-10"
              >
                <Thumbnail
                  companyName={item.companyName}
                  shipmentsComplete={item.shipmentsComplete}
                  rating={item.rating}
                  verified={item.verified}
                  onCallVet={item.onCallVet}
                  usdaRegistered={item.usdaRegistered}
                  insuranceVerified={item.insuranceVerified}
                  content={item.content}
                  review={item.review}
                  image={item.image}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
export default ThumbnailSet
