import { Fragment } from "react"
import Image from "next/image"
import { SbBlokData } from "@storyblok/react"
import star from "../assets/vector/star-new.svg"
import { BlokImage } from "../storyblok/types"
import { readImage } from "../app/utils/read-image"

export type OverlayReview = {
  name: string
  reviewerImage: BlokImage
  rating: string
  description: string
} & SbBlokData

const applyRating = (rating: string, star: any) => {
  return Array(Number(rating))
    .fill(
      <Image height={16} width={16} src={star.src} alt="star" loading="lazy" />,
    )
    .map((star, id) => <Fragment key={id}>{star}</Fragment>)
}

export default function OverlayCard({
  feedback,
  overlayReview,
  className,
}: {
  className?: string
  feedback?: string
  overlayReview?: OverlayReview[]
}) {
  return (
    <section
      className={className ? className : "absolute bottom-[5vh] right-4 z-100"}
    >
      {feedback && (
        <div className="bg-white max-w-[300px] font-montserrat font-semibold rounded-tl-2xl rounded-r-xl px-4 py-2 text-gray14">
          {feedback}
        </div>
      )}
      <div className={"flex justify-start items-center gap-4 lg:mt-8"}>
        {overlayReview?.map((item) => (
          <div
            key={item.name}
            className="bg-white rounded-2xl px-4 pt-3 w-[256px] min-w-[256px] min-h-[200px] mt-6 lg:mt-0 shadow-lg"
          >
            <div className="flex justify-between items-center mb-1">
              <div className="flex items-center md:flex-row">
                <Image
                  className="rounded-full h-10 w-10"
                  src={readImage(item.reviewerImage)}
                  alt={item.reviewerImage?.alt || "reviewer"}
                  height={40}
                  width={40}
                />
                <div className="flex flex-col ml-1.5">
                  <div className="font-roboto font-bold text-base overflow-hidden px-1.5 md:px-3">
                    {item.name}
                  </div>
                  <div className="flex">{applyRating(item.rating, star)}</div>
                </div>
              </div>
            </div>

            {item.description && (
              <p className="m-0 text=[14px] text-gray14 leading-[19.6px]">
                {item.description}
              </p>
            )}
          </div>
        ))}
      </div>
    </section>
  )
}
