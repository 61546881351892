import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Details from "../../components/content/Details"
import Markdown from "../../components/content/Markdown"

export interface IDropdownSection extends SbBlokData {
  markdown: string
  details: {
    summary: string
    content: string
  }[]
}

export default function DropdownSection({ blok }: { blok: IDropdownSection }) {
  const { markdown, details } = blok

  return (
    <section className="w-full" {...storyblokEditable(blok)}>
      <Markdown className="prose-h4:text-blue5 prose-h4:font-semibold mb-6">
        {markdown}
      </Markdown>
      <aside className="flex flex-col gap-2">
        {details.map((detail, i) => (
          <Details
            summary={detail.summary}
            key={`MarkdownAndDetails/Details${i}`}
          >
            {detail.content}
          </Details>
        ))}
      </aside>
    </section>
  )
}
