import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Markdown from "../../components/content/Markdown"
import ImageLeftContent from "./ImageLeftContent"
import ImageRightContent from "./ImageRightContent"
import AdaptiveGrid from "./layout/AdaptiveGrid"
import { IPrimaryCardProps } from "../../components/cards/PrimaryCard"

export interface ISection extends SbBlokData {
  header: string
  markdown: string
  blocks: Array<any>
  bgColor: "gray11" | "white" | "orange1"
  headerClasses?: string
  sectionClass?: string
}

export default function Section({
  blok,
  host,
}: {
  blok: ISection
  host: Record<string, string>
}) {
  const {
    header,
    markdown,
    blocks,
    bgColor,
    sectionClass,
    headerClasses = "text-blue7 mb-5",
  } = blok

  return (
    <section
      className={`relative page-padding w-full flex flex-col gap-3 bg-${bgColor} ${sectionClass}`}
      {...storyblokEditable(blok)}
    >
      <h3 className={`font-montserrat text-center text-4xl ${headerClasses}`}>
        {header}
      </h3>
      {markdown && (
        <Markdown className="xl:px-24 prose-p:text-center prose-p:text-xl">
          {markdown}
        </Markdown>
      )}
      {blocks.map((block, i) => {
        switch (block.component) {
          case "imageRightContent":
            return (
              <ImageRightContent
                noBlok
                header={block.header}
                markdown={block.markdown}
                image={block.image}
                blocks={block.blocks}
                imageJustify={block.imageJustify}
                imageAlign={block.imageAlign}
                buttonLabel={block.buttonLabel}
                extraSection={block.extraSection}
                key={`imageRightContent/${i}`}
                className="p-0 px-0"
                host={host}
                noPadding
              />
            )
          case "imageLeftContent":
            return (
              <ImageLeftContent
                noBlok
                header={block.header}
                markdown={block.markdown}
                image={block.image}
                blocks={block.blocks}
                imageJustify={block.imageJustify}
                imageAlign={block.imageAlign}
                buttonLabel={block.buttonLabel}
                extraSection={block.extraSection}
                key={`imageLeftContent/${i}`}
                className="p-0"
                host={host}
                noPadding
              />
            )
          case "adaptiveGrid":
            return (
              <AdaptiveGrid
                noBlok
                maxColumns={block.maxColumns}
                cards={block.cards}
                bgColor={block.bgColor}
                key={`adaptiveGrid/${i}`}
                className="p-0"
                noPadding
              />
            )
          default:
            return null
        }
      })}
    </section>
  )
}
