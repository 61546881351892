import classNames from "classnames"
import * as React from "react"
import { match } from "ts-pattern"

type ParagraphProps = {
  children: React.ReactNode
  variant?: "small" | "normal" | "large"
  className?: string
}

export const Paragraph = (props: ParagraphProps) => {
  const { variant = "normal" } = props

  const paragraphStyles = match(variant)
    .with("small", () => classNames("text-sm"))
    .with("normal", () => classNames("text-base"))
    .with("large", () => classNames("text-xl"))
    .exhaustive()

  return (
    <p
      className={classNames(
        "text-neutral-900 font-roboto",
        paragraphStyles,
        props.className,
      )}
    >
      {props.children}
    </p>
  )
}
