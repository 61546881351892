export function validateEmail(email) {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const isOk = re.test(email)

  if (!isOk) {
    return "The email must be a valid email address."
  }
  return null
}

export function validateLocationAddress(location) {
  if (location === null) {
    return "A valid address is required."
  }
  return null
}
