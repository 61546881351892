import React from "react"
import { createUseStyles } from "react-jss"
import classNames from "classnames"
import { mediaQueries } from "../../styles/breakpoints"

const useStyles = createUseStyles({
  container: {
    width: "100%",
    paddingRight: 15,
    paddingLeft: 15,
    marginRight: "auto",
    marginLeft: "auto",
    [mediaQueries.tablet]: {
      maxWidth: 720,
    },
    [mediaQueries.desktop]: {
      maxWidth: 960,
    },
    [mediaQueries.largeDesktop]: {
      maxWidth: 1140,
    },
  },
  row: {
    display: "flex",
    flexWrap: "wrap",
    marginLeft: -15,
    marginRight: -15,
  },
  col: {
    paddingLeft: 15,
    paddingRight: 15,
    width: "100%",
    maxWidth: "100%",
  },
  colMD1: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "8.3333333333%",
    },
  },
  colMD2: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "16.6666666667%",
    },
  },
  colMD3: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "25%",
    },
  },
  colMD4: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "33.3333333333%",
    },
  },
  colMD5: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "41.6666666667%",
    },
  },
  colMD6: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "50%",
    },
  },
  colMD7: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "58.3333333333%",
    },
  },
  colMD8: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "66.6666666667%",
    },
  },
  colMD9: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "75%",
    },
  },
  colMD10: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "83.3333333333%",
    },
  },
  colMD11: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "91.6666666667%",
    },
  },
  colMD12: {
    [mediaQueries.tablet]: {
      flex: "0 0 auto",
      width: "100%",
    },
  },
  colLG1: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "8.3333333333%",
    },
  },
  colLG2: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "16.6666666667%",
    },
  },
  colLG3: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "25%",
    },
  },
  colLG4: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "33.3333333333%",
    },
  },
  colLG5: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "41.6666666667%",
    },
  },
  colLG6: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "50%",
    },
  },
  colLG7: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "58.3333333333%",
    },
  },
  colLG8: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "66.6666666667%",
    },
  },
  colLG9: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "75%",
    },
  },
  colLG10: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "83.3333333333%",
    },
  },
  colLG11: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "91.6666666667%",
    },
  },
  colLG12: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "100%",
    },
  },
  colXL1: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "8.3333333333%",
    },
  },
  colXL2: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "16.6666666667%",
    },
  },
  colXL3: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "25%",
    },
  },
  colXL4: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "33.3333333333%",
    },
  },
  colXL5: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "41.6666666667%",
    },
  },
  colXL6: {
    [mediaQueries.desktop]: {
      flex: "0 0 auto",
      width: "50%",
    },
  },
  colXL7: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "58.3333333333%",
    },
  },
  colXL8: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "66.6666666667%",
    },
  },
  colXL9: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "75%",
    },
  },
  colXL10: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "83.3333333333%",
    },
  },
  colXL11: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "91.6666666667%",
    },
  },
  colXL12: {
    [mediaQueries.largeDesktop]: {
      flex: "0 0 auto",
      width: "100%",
    },
  },
})

const Container = ({ className = "", children, ...props }) => {
  const classes = useStyles()
  return (
    <div
      className={classNames({
        [className]: className,
        [classes.container]: true,
      })}
      {...props}
    >
      {children}
    </div>
  )
}

const Row = ({ className = "", children }) => {
  const classes = useStyles()
  return (
    <div
      className={classNames({
        [className]: className,
        [classes.row]: true,
      })}
    >
      {children}
    </div>
  )
}

const Col = ({ className = "", children, ...props }) => {
  const classes = useStyles()
  const md = props.md ? `colMD${props.md}` : false
  const lg = props.lg ? `colLG${props.lg}` : false
  const xl = props.xl ? `colXL${props.xl}` : false
  return (
    <div
      className={classNames({
        [className]: className,
        [classes.col]: true,
        [classes[md]]: md,
        [classes[lg]]: lg,
        [classes[xl]]: xl,
      })}
    >
      {children}
    </div>
  )
}

export { Container, Row, Col }
