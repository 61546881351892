import {
  ISbRichtext,
  renderRichText,
  storyblokEditable,
} from "@storyblok/react"
import dynamic from "next/dynamic"
import { ConditionalBlok } from ".."
import { DriverIcon, QuotesIcon } from "../../components/svgs"

const FastStopwatchIcon = dynamic(
  () => import("../../components/svgs/FastStopwatchIcon"),
)
const TransportIcon = dynamic(
  () => import("../../components/svgs/TransportIcon"),
)

interface IStats {}
type IStatsProps = ConditionalBlok<IStats> & {
  className?: string
  blok?: {
    driver: ISbRichtext | string
    listingPercentage: ISbRichtext | string
    miles: ISbRichtext | string
    years: ISbRichtext | string
  }
}
const classes =
  "max-w-none prose prose-xl prose-h3:text-4xl" +
  " " +
  "prose-headings:font-medium prose-headings:font-montserrat prose-headings:text-blue7" +
  " " +
  "prose-p:text-[16px] prose-p:font-roboto prose-p:text-blue5 prose-p:leading-7" +
  " " +
  "prose-a:text-link prose-a:no-underline hover:prose-a:text-linkHover hover:prose-a:underline prose-a:font-normal" +
  " " +
  "list-disc prose-ul:text-left prose-ul:text-xl"

export default function Stats(props: IStatsProps) {
  const { noBlok, className, blok } = props
  const isString = (content: string | ISbRichtext) =>
    typeof content === "string" ? content : renderRichText(content)
  return (
    <section
      className={`w-full page-padding my-0 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-10 bg-gray3 ${
        className ?? ""
      }`}
      {...(!noBlok && storyblokEditable(blok))}
    >
      <aside className="max-w-full w-80 sm:w-44 mx-auto flex flex-row sm:flex-col">
        <div className="h-20 w-20 mr-4 sm:mr-0 mb-6 p-4 rounded-full flex items-center justify-center bg-white drop-shadow-md">
          <DriverIcon className="fill-blue7" />
        </div>
        <div
          className={classes}
          dangerouslySetInnerHTML={{ __html: isString(blok?.driver ?? "") }}
        />
      </aside>
      <aside className="max-w-full w-80 sm:w-44 mx-auto flex flex-row sm:flex-col">
        <div className="h-20 w-20 mr-4 sm:mr-0 mb-6 p-4 rounded-full flex items-center justify-center bg-white drop-shadow-md">
          <QuotesIcon className="fill-blue7" />
        </div>
        <div
          className={classes}
          dangerouslySetInnerHTML={{
            __html: isString(blok?.listingPercentage ?? ""),
          }}
        />
      </aside>
      <aside className="max-w-full w-80 sm:w-44 mx-auto flex flex-row sm:flex-col">
        <div className="h-20 w-20 mr-4 sm:mr-0 mb-6 p-4 rounded-full flex items-center justify-center bg-white drop-shadow-md">
          <TransportIcon className="fill-blue7" />
        </div>
        <div
          className={classes}
          dangerouslySetInnerHTML={{
            __html: isString(blok?.miles ?? ""),
          }}
        />
      </aside>
      <aside className="max-w-full w-80 sm:w-44 mx-auto flex flex-row sm:flex-col">
        <div className="h-20 w-20 mr-4 sm:mr-0 mb-6 p-4 rounded-full flex items-center justify-center bg-white drop-shadow-md">
          <FastStopwatchIcon className="fill-blue7" />
        </div>
        <div
          className={classes}
          dangerouslySetInnerHTML={{
            __html: isString(blok?.years ?? ""),
          }}
        />
      </aside>
    </section>
  )
}
