export default function useBusinessHours() {
  const now = new Date()
  // 9 - 5 Monday - Friday PST
  const startHours = new Date().setHours(9, 0, 0, 0)
  const endHours = new Date().setHours(17, 0, 0, 0)
  const isWeekday = now.getDay() >= 1 && now.getDay() <= 5

  const isInBusinessHours =
    isWeekday && now.getTime() >= startHours && now.getTime() <= endHours

  return { isInBusinessHours }
}
