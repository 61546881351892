import Image from "next/image"
import Link from "next/link"
import StarRating from "./StarRating"
import { useReviewsContext } from "../context/ReviewsContext"

export default function RatingCard({
  isMilitary = false,
}: {
  isMilitary?: boolean
}) {
  const { blokReviews } = useReviewsContext()
  return (
    <div className={"flex justify-start items-center gap-4"}>
      <section className="w-[128px] h-[60px] p-2 rounded-lg shadow-md bg-white">
        <Image
          className="h-[22px] w-auto"
          src="/icons/google.svg"
          alt="google logo"
          width={100}
          height={20}
        />

        <div className="w-full flex flex-row justify-start items-center text-sm">
          <StarRating value={5} className="w-20" />

          <Link
            href="https://www.google.com/maps/place/CitizenShipper/@32.2908037,-95.3054722,17z/data=!4m7!3m6!1s0x0:0xaa0dc85bb2e33171!8m2!3d32.2908037!4d-95.3054722!9m1!1b1?hl=en"
            target="_blank"
            rel="noreferrer noopener"
            passHref
          >
            {blokReviews?.googleRatings}
          </Link>
        </div>
      </section>
      <section className="w-[128px] h-[60px] p-2 rounded-lg shadow-md bg-white">
        <Image
          className="h-[22px] w-auto"
          src="/icons/trust-pilot.svg"
          alt="trust pilot logo"
          width={100}
          height={20}
        />

        <div className="w-full flex flex-row  justify-start items-center text-sm">
          <StarRating value={5} className="w-20" />

          <Link
            href="https://www.trustpilot.com/review/citizenshipper.com?stars=5#:~:text=Reply%20from%20CitizenShipper"
            target="_blank"
            rel="noreferrer noopener"
            passHref
          >
            {blokReviews?.trustpilotRatings}
          </Link>
        </div>
      </section>
      {isMilitary && (
        <section className="w-[128px] h-[60px]">
          <Link
            href="https://www.trustpilot.com/review/citizenshipper.com?stars=5#:~:text=Reply%20from%20CitizenShipper"
            target="_blank"
            rel="noreferrer noopener"
            passHref
          >
            <Image
              className="h-[60px] w-auto"
              src="/images/military-trusted-badge.webp"
              alt="military trusted"
              width={128}
              height={60}
            />
          </Link>
        </section>
      )}
    </div>
  )
}
