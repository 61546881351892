import { SbBlokData } from "@storyblok/react"
import { FC, HTMLAttributes } from "react"

import { BlokImage } from "../.."
import ReviewsCtaSnippets from "../../../sections/reviews-cta-snippets"
import { readImage } from "../../../app/utils/read-image"

type ReviewSnippet = {
  reviewer: string
  reviewerIcon: BlokImage
  logo: string
  content: string
  rating: number
}
export type ReviewSnippetsCardProps = ReviewSnippet &
  HTMLAttributes<HTMLElement>

export const ReviewSnippetsCard: FC<ReviewSnippetsCardProps> = (props) => {
  return <div {...props} />
}

export interface IReviewSnippets extends SbBlokData {
  title: string
  buttonTitle: string
  buttonLink: string
  additionalDriverReviews: ReviewSnippet[] & SbBlokData
  hasDarkBlueTheme: boolean
  reviews: ReviewSnippet[] & SbBlokData
}
const ReviewSnippets = ({ blok }: { blok: IReviewSnippets }) => {
  const {
    reviews,
    buttonTitle,
    buttonLink,
    title,
    additionalDriverReviews,
    hasDarkBlueTheme,
  } = blok

  const reviewsData = reviews?.map(
    (review) =>
      ({
        ...review,
        rating: Number(review.rating),
        reviewerIcon: {
          ...review.reviewerIcon,
          src: readImage(review.reviewerIcon),
        },
      } || []),
  )

  return (
    <ReviewsCtaSnippets
      title={title}
      ctaButtonTitle={buttonTitle}
      ctaButtonLink={buttonLink}
      driverReviews={reviewsData}
      additionalDriverReviews={additionalDriverReviews}
      darkBlueTheme={hasDarkBlueTheme}
    />
  )
}
export default ReviewSnippets
