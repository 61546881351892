import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { AiFillStar } from "react-icons/ai"
import { BlokImage } from "../.."
import { readImage } from "../../../app/utils/read-image"

interface ISuccessStories extends SbBlokData {
  header: string
  stories: IStory[]
}

interface IStory extends SbBlokData {
  reviewer: string
  reviewerIcon: BlokImage
  trustpilot: boolean
  facebook: boolean
  rating: 5
  review: string
  image1: BlokImage
  image2: BlokImage
}

export default function SuccessStories({ blok }: { blok: ISuccessStories }) {
  const { header, stories } = blok

  const story = stories[0]

  return (
    <section className="w-full flex flex-col items-center px-8 sm:px-16 md:px-24 pb-24 m-0 -mb-[2px] bg-cover">
      <h3 className="text-3xl my-10">{header}</h3>
      <article className="w-full p-8 space-y-6 bg-white shadow-md rounded-2xl">
        <section className="flex justify-between flex-col-reverse sm:flex-row ">
          <div className="flex space-x-3 items-center">
            <Image
              className="rounded-full"
              src={readImage(story.reviewerIcon)}
              alt={story.reviewerIcon.alt}
              width={60}
              height={60}
              loading="lazy"
            />
            <div className="">
              <p className="m-0 font-bold">{story.reviewer}</p>
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <AiFillStar
                    key={i}
                    className="h-5 w-5 fill-yellow12 stroke-yellow12 last-of-type:mr-auto"
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            {story.trustpilot && (
              <Image
                className="mb-4 sm:mb-0"
                src="/icons/trust-pilot.svg"
                alt="trustpilot logo"
                width={150}
                height={50}
                loading="lazy"
              />
            )}
            {story.facebook && (
              <Image
                className="mb-4 sm:mb-0"
                src="/icons/facebook.svg"
                alt="facebook logo"
                width={150}
                height={50}
                loading="lazy"
              />
            )}
          </div>
        </section>
        <p>{story.review}</p>
        <div className="flex space-x-2">
          {story.image1.filename && (
            <Image
              className=""
              src={readImage(story.image1)}
              alt={story.image2.alt}
              width={70}
              height={70}
              loading="lazy"
            />
          )}
          {story.image2.filename && (
            <Image
              className=""
              src={readImage(story.image2)}
              alt={story.image2.alt}
              width={70}
              height={70}
              loading="lazy"
            />
          )}
        </div>
      </article>
    </section>
  )
}
