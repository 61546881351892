import React, { FC, HTMLAttributes } from "react"

import dynamic from "next/dynamic"
import { SbBlokData } from "@storyblok/react"

import { BlokImage } from ".."

const Support = dynamic(() => import("../../sections/homepage-support"))

export type ShipmentsDataType = {
  id: number
  imageSrc: string
  amount: number
  breed: string
  mileage: string
  city: string
}
export interface IShowCaseProps {
  title?: string
  data: ShipmentsDataType[]
  matchTitle?: string
  matchSubtitle?: string
  feedback?: string
  authorName?: string
  platform?: string
}
export type ShowCaseCardProps = IShowCaseProps & HTMLAttributes<HTMLElement>
export type SupportItem = {
  name: string
  position: string
  image: BlokImage
  testimony: {
    text: string
    author: string
    origin: string
  }[]
}
export interface IHomeSupport extends SbBlokData, IShowCaseProps {
  title?: string
  supportData?: SupportItem[]
  headline?: string
  statistics?: string
}
export type SupportItemProps = SupportItem & HTMLAttributes<HTMLElement>

export const HomeSupportItem: FC<SupportItemProps> = (props) => {
  return <div {...props} />
}

const HomeSupport = ({ blok }: { blok: IHomeSupport }) => {
  const { title, headline, statistics, supportData } = blok
  const data =
    supportData?.map((item, index) => ({
      ...item,
      image: { ...item.image, src: item.image?.filename },
      testimony: {
        text: item.testimony[0].text,
        author: item.testimony[0].author,
        origin: item.testimony[0].origin,
      },
      id: index,
    })) ?? []

  return (
    <section className="page-padding">
      <Support
        supportData={data}
        title={title}
        headline={headline}
        statistics={statistics}
      />
    </section>
  )
}

export default HomeSupport
