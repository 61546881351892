import { SbBlokData } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from ".."

import Badge from "../../assets/vector/check-badge.svg"
import Map from "../../assets/vector/map.svg"
import Globe from "../../assets/vector/globe-americas.svg"
import Cursor from "../../assets/vector/cursor-arrow-rays.svg"

type ItemsType = "Globe" | "Cursor" | "Map" | "Badge"

interface IFeatures extends SbBlokData {
  items: {
    name: ItemsType
    text: string
    subtext: string
  }[]
}

export default function Features({ blok }: { blok: IFeatures }) {
  const { items } = blok
  const iconsInfo: {
    [key in ItemsType]: string
  } = {
    Globe: Globe.src,
    Cursor: Cursor.src,
    Map: Map.src,
    Badge: Badge.src,
  }

  return (
    <section
      id="stats"
      className="mt-10 md:mt-14 py-10 max-w-[1289px] mx-auto px-4 md:px-1"
    >
      <div
        className={`grid grid-cols-1 md:grid-cols-${items.length} gap-x-4 gap-y-8 mb-4 max-w-[1350px] mx-auto`}
      >
        {items.map((item, index) => (
          <div className="flex items-center" key={index}>
            <Image
              src={iconsInfo[item.name]}
              alt={item.name}
              height={48}
              width={48}
              loading="lazy"
            />
            <div className="pl-2">
              <p className="mb-0.5">{item.text}</p>
              <span className="text-sm">{item.subtext}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
