import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Link from "next/link"
import Image from "next/image"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import Details from "../../components/content/Details"
import Markdown from "../../components/content/Markdown"
import useCTARoute from "../../util/useCTARoute"
import { BlokImage } from ".."
import { readImage } from "../../app/utils/read-image"

export interface IMarkdownAndDetails extends SbBlokData {
  markdown: string
  buttonLabel?: string
  details: {
    summary: string
    content: string
  }[]
  image: BlokImage
}

export default function MarkdownAndDetails({
  blok,
}: {
  blok: IMarkdownAndDetails
}) {
  const { markdown, details, buttonLabel, image } = blok

  const { route } = useCTARoute()

  return (
    <section
      className="relative page-padding w-full grid grid-cols-1 md:grid-cols-2 gap-12"
      {...storyblokEditable(blok)}
    >
      <div className="space-y-10">
        <Markdown className="prose-h2:text-4xl prose-h2:font-medium prose-p:text-2xl prose-p:font-normal">
          {markdown}
        </Markdown>
        {buttonLabel && (
          <Link className="flex h-fit mt-12" href={route}>
            <PrimaryButton className="rounded-lg">{buttonLabel}</PrimaryButton>
          </Link>
        )}
        {image && (
          <div className=" relative w-full max-h-72 sm:max-h-fit h-96">
            <Image
              className="h-fit object-contain sm:object-cover"
              src={readImage(image)}
              alt={image.description || image.alt}
              fill
            />
          </div>
        )}
      </div>
      <aside className="flex flex-col gap-2">
        {details.map((detail, i) => (
          <Details
            summary={detail.summary}
            key={`MarkdownAndDetails/Details${i}`}
          >
            {detail.content}
          </Details>
        ))}
      </aside>
    </section>
  )
}
