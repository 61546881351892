import { IIconProps } from "."

export function FiveStarRatingIcon(props: IIconProps) {
  return (
    <svg
      width="140"
      height="28"
      viewBox="0 0 140 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.0954 20.6938C13.6515 20.3581 14.3478 20.3581 14.904 20.6938L17.6589 22.3566C18.9842 23.1565 20.6191 21.9683 20.2675 20.4608L19.5363 17.3267C19.3887 16.694 19.6037 16.0317 20.0947 15.6064L22.5314 13.4955C23.7015 12.4819 23.076 10.5599 21.5335 10.429L18.3295 10.1572C17.6828 10.1023 17.1195 9.69401 16.8662 9.09638L15.6109 6.13466C15.0079 4.71186 12.9914 4.71186 12.3884 6.13466L11.1331 9.09638C10.8799 9.69401 10.3166 10.1023 9.66983 10.1572L6.46586 10.429C4.92337 10.5599 4.2979 12.4819 5.46795 13.4955L7.90465 15.6064C8.39564 16.0317 8.61062 16.694 8.46304 17.3267L7.7319 20.4608C7.38022 21.9683 9.01512 23.1565 10.3404 22.3566L13.0954 20.6938Z"
        fill="#F7C62C"
      />
      <path
        d="M41.0954 20.6938C41.6515 20.3581 42.3478 20.3581 42.904 20.6938L45.6589 22.3566C46.9842 23.1565 48.6191 21.9683 48.2675 20.4608L47.5363 17.3267C47.3887 16.694 47.6037 16.0317 48.0947 15.6064L50.5314 13.4955C51.7015 12.4819 51.076 10.5599 49.5335 10.429L46.3295 10.1572C45.6828 10.1023 45.1195 9.69401 44.8662 9.09638L43.6109 6.13466C43.0079 4.71186 40.9914 4.71186 40.3884 6.13466L39.1331 9.09638C38.8799 9.69401 38.3166 10.1023 37.6698 10.1572L34.4659 10.429C32.9234 10.5599 32.2979 12.4819 33.4679 13.4955L35.9047 15.6064C36.3956 16.0317 36.6106 16.694 36.463 17.3267L35.7319 20.4608C35.3802 21.9683 37.0151 23.1565 38.3404 22.3566L41.0954 20.6938Z"
        fill="#F7C62C"
      />
      <path
        d="M69.0954 20.6938C69.6515 20.3581 70.3478 20.3581 70.904 20.6938L73.6589 22.3566C74.9842 23.1565 76.6191 21.9683 76.2675 20.4608L75.5363 17.3267C75.3887 16.694 75.6037 16.0317 76.0947 15.6064L78.5314 13.4955C79.7015 12.4819 79.076 10.5599 77.5335 10.429L74.3295 10.1572C73.6828 10.1023 73.1195 9.69401 72.8662 9.09638L71.6109 6.13466C71.0079 4.71186 68.9914 4.71186 68.3884 6.13466L67.1331 9.09638C66.8799 9.69401 66.3166 10.1023 65.6698 10.1572L62.4659 10.429C60.9234 10.5599 60.2979 12.4819 61.4679 13.4955L63.9047 15.6064C64.3956 16.0317 64.6106 16.694 64.463 17.3267L63.7319 20.4608C63.3802 21.9683 65.0151 23.1565 66.3404 22.3566L69.0954 20.6938Z"
        fill="#F7C62C"
      />
      <path
        d="M97.0954 20.6938C97.6515 20.3581 98.3478 20.3581 98.904 20.6938L101.659 22.3566C102.984 23.1565 104.619 21.9683 104.267 20.4608L103.536 17.3267C103.389 16.694 103.604 16.0317 104.095 15.6064L106.531 13.4955C107.701 12.4819 107.076 10.5599 105.533 10.429L102.33 10.1572C101.683 10.1023 101.119 9.69401 100.866 9.09638L99.6109 6.13466C99.0079 4.71186 96.9914 4.71186 96.3884 6.13466L95.1331 9.09638C94.8799 9.69401 94.3166 10.1023 93.6698 10.1572L90.4659 10.429C88.9234 10.5599 88.2979 12.4819 89.4679 13.4955L91.9047 15.6064C92.3956 16.0317 92.6106 16.694 92.463 17.3267L91.7319 20.4608C91.3802 21.9683 93.0151 23.1565 94.3404 22.3566L97.0954 20.6938Z"
        fill="#F7C62C"
      />
      <path
        d="M125.095 20.6938C125.652 20.3581 126.348 20.3581 126.904 20.6938L129.659 22.3566C130.984 23.1565 132.619 21.9683 132.267 20.4608L131.536 17.3267C131.389 16.694 131.604 16.0317 132.095 15.6064L134.531 13.4955C135.701 12.4819 135.076 10.5599 133.533 10.429L130.33 10.1572C129.683 10.1023 129.119 9.69401 128.866 9.09638L127.611 6.13466C127.008 4.71186 124.991 4.71186 124.388 6.13466L123.133 9.09638C122.88 9.69401 122.317 10.1023 121.67 10.1572L118.466 10.429C116.923 10.5599 116.298 12.4819 117.468 13.4955L119.905 15.6064C120.396 16.0317 120.611 16.694 120.463 17.3267L119.732 20.4608C119.38 21.9683 121.015 23.1565 122.34 22.3566L125.095 20.6938Z"
        fill="#F7C62C"
      />
    </svg>
  )
}
