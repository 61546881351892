import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { AiOutlineCheckCircle } from "react-icons/ai"

interface IHowMuchDoesItCostToMoveAnRV extends SbBlokData {}

export default function HowMuchDoesItCostToMoveAnRV({
  blok,
}: {
  blok: IHowMuchDoesItCostToMoveAnRV
}) {
  return (
    <section
      className="relative page-padding w-full flex flex-col-reverse md:flex-row gap-20 bg-gray11"
      {...storyblokEditable(blok)}
    >
      <article className="md:py-16 md:w-1/2">
        <h2 className="text-4xl text-blue7 font-montserrat">
          How much does it cost to move an RV
        </h2>
        <p className="text-2xl">
          A number of factors affect the rate RV transport companies charge.
        </p>
        <p>The two most important variables are:</p>
        <ul className="p-0 m-0 mb-12">
          <li className="flex items-center">
            <AiOutlineCheckCircle className="h-8 w-8 min-w-[2rem] mr-5 fill-green5" />
            The distance of the transport.
          </li>
          <li className="flex items-center">
            <AiOutlineCheckCircle className="h-8 w-8 min-w-[2rem] mr-5 fill-green5" />
            The size of the RV, trailer, camper or 5th wheel.
          </li>
        </ul>
        <p className="text-base ">
          RV transport companies typically charge more per mile for short trips.
          For example, an RV transport of less than 100 miles costs an average
          of over $3.00 per mile — and as much as over $4.00 per mile. On the
          other hand, transports in the 100-1000 mile range can cost as little
          as $1.00 per mile.
        </p>
      </article>
      <div className="my-auto relative md:w-1/2 h-96">
        <Image
          className="object-contain"
          src="/images/cost-image.webp"
          alt="rv-transport"
          fill
        />
      </div>
    </section>
  )
}
