import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"

interface IRVInvestment extends SbBlokData {}

export default function RVInvestment({ blok }: { blok: IRVInvestment }) {
  return (
    <section
      className="relative page-padding w-full flex flex-col md:flex-row gap-20 bg-gray11"
      {...storyblokEditable(blok)}
    >
      <article className="md:py-16 md:w-1/2">
        <h2 className="text-4xl text-blue7 font-montserrat">
          An RV is a substantial investment, you need a transporter you trust
        </h2>
        <p className="text-2xl">
          It’s worth paying a little bit extra for your own peace of mind.
        </p>
      </article>
      <div className="my-auto relative md:w-1/2 h-80">
        <Image
          className="object-contain"
          src="/images/rv-investment.webp"
          alt="RV transport of 5th wheel trailer"
          fill
        />
      </div>
    </section>
  )
}
