import React from "react"
import { createUseStyles } from "react-jss"
import PropTypes from "prop-types"
import classNames from "classnames"

import { noop } from "../../util/fp"
import colors from "../../styles/colors"

const unit = 8

export const styles = {
  input: {
    border: `solid 1px #7D7D7D`,
    boxSizing: "border-box",
    color: "#29313B",
    paddingLeft: unit * 1.5,
    fontWeight: 300,
    borderRadius: 3,
    height: 48,
    fontSize: 16,
    WebkitAppearance: "none",
    "&:focus": {
      outline: `2px solid #1B53E1`,
    },
    "&::placeholder": {
      color: "#7D7D7D",
      fontSize: 16,
    },
  },
}

const width = (props) => (props.width ? props.width : null)
const height = (props) => (props.height ? props.height : 55)

const input = {
  ...styles.input,
  width,
  height,
}

const useStyles = createUseStyles({
  input: {
    ...input,
    width,
    height,
  },
  error: {
    ...input,
    width: "100%",
    height,
    border: `solid 1px ${colors.red1}`,
  },
})

const Input = ({
  className,
  value,
  placeholder,
  disabled,
  onClick = noop,
  onChange = noop,
  type = "text",
  pattern,
  error,
  minValue,
  name,
  onBlur = noop,
  width,
  height,
  maxLength = 9999,
  ...props
}) => {
  const classes = useStyles({ width, height })
  return (
    <input
      className={
        error
          ? classes.error
          : classNames({
              [classes.input]: true,
              [className]: className,
            })
      }
      placeholder={placeholder}
      value={value}
      disabled={disabled}
      onChange={(e) => {
        e.preventDefault()
        onChange(e.target.value)
      }}
      style={
        disabled
          ? {
              backgroundColor: colors.gray13,
            }
          : undefined
      }
      onBlur={(e) => {
        e.preventDefault()
        return onBlur(e)
      }}
      onClick={onClick}
      autoComplete="off"
      type={type === "step" ? "number" : type}
      step={type === "step" ? 1 : undefined}
      min={type === "number" ? minValue || "0" : null}
      name={name}
      pattern={pattern}
      maxLength={maxLength}
      {...props}
    />
  )
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.oneOf(["email", "text", "number", "step", "password", "tel"]),
}

Input.defaultProps = {
  value: "",
}

export default Input
