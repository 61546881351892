import { ChangeEvent, HTMLProps, ReactNode, useState } from "react"
import classNames from "classnames"

export interface IBaseInputProps extends HTMLProps<HTMLInputElement> {
  label: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  children?: ReactNode
  name: string
  error?: string | null
}

export default function BaseInput({
  label,
  className,
  children,
  type,
  onChange,
  name,
  error,
  ...props
}: IBaseInputProps) {
  const [value, setValue] = useState<string>("")

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
    setValue(e.target.value)
  }

  return (
    <div>
      <fieldset
        name={name}
        className={classNames(
          "relative w-full flex items-center cursor-text",
          className,
        )}
      >
        <span className="absolute left-4 top-3">{children}</span>
        <div className="relative grow">
          <input
            id={name}
            name={name}
            type={type}
            className={classNames(
              "w-full p-4 pl-14 outline-none peer text-black bg-transparent border border-transparent",
              value != "" ? "content" : "no-content",
              error && "border-b-red1",
            )}
            autoComplete="off"
            value={value}
            onChange={handleChange}
            placeholder=" "
            aria-label={label}
            {...props}
          />
          <label
            htmlFor={name}
            className="absolute top-4 left-14 text-blue5/60 transition-transform origin-left cursor-text 
                    scale-75 -translate-y-3 
                    peer-focus:scale-75 peer-focus:-translate-y-3 
                    peer-[content]:scale-75 peer-[content]:-translate-y-3 
                    peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-0
                    peer-invalid:text-red-500 peer-[no-content]:text-blue5/60
                    "
          >
            {label}
          </label>
        </div>
      </fieldset>
      {error != null && (
        <span className="text-red1 ml-14 block pt-1 text-xs">{error}</span>
      )}
    </div>
  )
}
