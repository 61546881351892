import React from "react"
import { createUseStyles } from "react-jss"

import TravelProtectionProgrammeIcon from "../assets/icons/travel-protection-programme.svg"

import colors from "../styles/colors"
import { mediaQueries } from "../styles/breakpoints"

const useStyles = createUseStyles({
  travelIcon: {
    display: "inline-flex",
    marginRight: 16,
  },
  wrapper: {
    display: "flex",
    marginTop: 16,
  },
  paragraph: {
    fontFamily: "var(--font-montserrat), sans-serif",
    fontWeight: 500,
    fontSize: 14,
    marginBottom: 0,
    color: (props) => props.textColor,
    [mediaQueries.tablet]: {
      fontSize: 17,
    },
  },
})

const BookingAssuranceGuaranteeBadge = ({
  textColor = colors.gray9,
  footer = "",
}) => {
  const classes = useStyles({ textColor })
  const bookingGuarantee =
    "All transports are covered under our <br /> <a href='https://help.citizenshipper.com/hc/en-us/articles/360041203251-CitizenShipper-Booking-Assurance-Guarantee' style={{ fontWeight: 600, color: textColor }} rel='noopener'>Booking Assurance Guarantee.</a>"
  return (
    <div className={classes.wrapper}>
      <img
        className={classes.travelIcon}
        src={TravelProtectionProgrammeIcon.src}
        height={TravelProtectionProgrammeIcon.height}
        width={TravelProtectionProgrammeIcon.width}
        alt="travel protection"
      />
      <span
        className={classes.paragraph}
        dangerouslySetInnerHTML={{ __html: footer || bookingGuarantee }}
      />
    </div>
  )
}

export default BookingAssuranceGuaranteeBadge
