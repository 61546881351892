import { FC } from "react"

export interface IPremiumSocialProof {
  stats?: {
    title?: string
    text?: string
  }[]
}
const icons = [
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key="icon1"
  >
    <path
      d="M12.2301 11.0869L12.8678 14.9128C13.279 17.3798 14.4507 19.6568 16.2192 21.4253L19.5 24.7061L18.7252 26.2556C18.2921 27.1218 18.4619 28.168 19.1467 28.8528L21.841 31.547C22.2629 31.969 22.5 32.5413 22.5 33.138V35.3155C22.5 36.1677 22.9815 36.9468 23.7438 37.3279L24.0495 37.4808C24.9157 37.9139 25.9619 37.7441 26.6467 37.0593L28.0921 35.6139C30.3221 33.384 31.904 30.5899 32.6689 27.5305C32.8713 26.7207 32.5925 25.8665 31.9512 25.3321L29.2926 23.1166C28.7894 22.6972 28.1284 22.518 27.4823 22.6257L25.1421 23.0157C24.4254 23.1352 23.6951 22.9011 23.1812 22.3873L22.591 21.797C21.7123 20.9184 21.7123 19.4937 22.591 18.6151L22.8533 18.3528C23.5381 17.668 24.5843 17.4982 25.4505 17.9313L26.6584 18.5352C26.883 18.6476 27.1308 18.7061 27.382 18.7061C28.5848 18.7061 29.3671 17.4402 28.8292 16.3644L28.5 15.7061L31.0113 14.0318C32.3145 13.163 33.3684 11.9692 34.0689 10.5683L34.3606 9.9849M12.2301 11.0869C8.41432 14.3875 6 19.2648 6 24.7061C6 34.6472 14.0589 42.7061 24 42.7061C33.9411 42.7061 42 34.6472 42 24.7061C42 18.6221 38.9816 13.2431 34.3606 9.9849M12.2301 11.0869C15.3858 8.3573 19.5001 6.70605 24 6.70605C27.8571 6.70605 31.4309 7.91926 34.3606 9.9849"
      stroke="#9F6D25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key="icon2"
  >
    <path
      d="M30.0846 44.0497L27.367 33.9074M27.367 33.9074L22.3482 38.3581L23.485 19.4185L33.9393 35.2523L27.367 33.9074ZM24 5.20605V9.70605M35.6673 10.0388L32.4853 13.2208M40.5 21.7061H36M15.5147 30.1913L12.3327 33.3733M12 21.7061H7.5M15.5147 13.2208L12.3327 10.0388"
      stroke="#9F6D25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  <svg
    width="49"
    height="49"
    viewBox="0 0 49 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key="icon3"
  >
    <path
      d="M18.375 14.2061V30.7061M30.625 18.7061V35.2061M31.6522 42.203L41.6053 37.328C42.3835 36.9469 42.875 36.1678 42.875 35.3155V10.3467C42.875 8.67407 41.0781 7.5862 39.5509 8.33422L31.6522 12.203C31.0056 12.5197 30.2444 12.5197 29.5978 12.203L19.4022 7.20922C18.7556 6.8925 17.9944 6.8925 17.3478 7.20922L7.39468 12.0842C6.61654 12.4654 6.125 13.2444 6.125 14.0967V39.0655C6.125 40.7381 7.92187 41.826 9.44907 41.078L17.3478 37.2092C17.9944 36.8925 18.7556 36.8925 19.4022 37.2092L29.5978 42.203C30.2444 42.5197 31.0056 42.5197 31.6522 42.203Z"
      stroke="#9F6D25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
  <svg
    width="48"
    height="49"
    viewBox="0 0 48 49"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key="icon4"
  >
    <path
      d="M38.8133 30.8424C40.7408 29.4851 42 27.2426 42 24.7061C42 22.1699 40.7412 19.9278 38.8143 18.5704C39.2175 16.2477 38.5223 13.7715 36.7286 11.9779C34.9349 10.1842 32.4587 9.48895 30.136 9.89219C28.7786 7.96505 26.5363 6.70605 24 6.70605C21.4636 6.70605 19.2212 7.96515 17.8639 9.89243C15.5413 9.48937 13.0653 10.1846 11.2717 11.9782C9.4782 13.7717 8.78294 16.2476 9.18595 18.5702C7.25892 19.9276 6 22.1698 6 24.7061C6 27.2422 7.25881 29.4843 9.18572 30.8417C8.78249 33.1645 9.47772 35.6406 11.2714 37.4343C13.0651 39.228 15.5413 39.9232 17.8641 39.52C19.2214 41.4471 21.4637 42.7061 24 42.7061C26.5364 42.7061 28.7787 41.447 30.136 39.5198C32.4584 39.9226 34.9341 39.2273 36.7275 37.4339C38.5209 35.6405 39.2162 33.1648 38.8133 30.8424Z"
      stroke="#9F6D25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 30.7061V19.7061L17 20.8061"
      stroke="#9F6D25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.1538 30.7061V19.7061L23 27.4061H31"
      stroke="#9F6D25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>,
]
const PremiumSocialProof: FC<IPremiumSocialProof> = (props) => {
  const { stats } = props
  return (
    <section
      id="social-proof"
      className="mt-12 md:my-8 px-4 sm:px-0 mx-auto flex justify-center relative"
    >
      <div className="flex flex-col md:flex-row gap-x-6 gap-y-6 mb-4 justify-center">
        {stats?.map((stat, index) => (
          <div className="flex" key={stat?.title?.trim() || index}>
            {icons[index]}

            <div className="pl-1">
              <p className="mb-0.5">{stat?.title}</p>
              <span className="text-[13.5px]">{stat?.text}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
export default PremiumSocialProof
