import { useRouter } from "next/router"
import dynamic from "next/dynamic"

import * as config from "../../../util/config"

const PetReviewsCtaSnippets = dynamic(
  () => import("../../../sections/reviews-cta-snippets"),
)

const petReviewsTitle = (
  <>
    Pet owners love CitizenShipper!
    <br />
    Here's what they're saying...
  </>
)

const PillarsReview = () => {
  const host = config.getWebsiteHost()
  const router = useRouter()
  const petCtaButtonTitle =
    router.query.type === "call"
      ? "Call us for a free quote"
      : "Get Transportation Quotes"

  const petCtaButtonLink =
    router.query.type === "call"
      ? "tel:+19032223176"
      : `${host}/shipments/new/animals`
  return (
    <PetReviewsCtaSnippets
      title={petReviewsTitle}
      ctaButtonTitle={petCtaButtonTitle}
      ctaButtonLink={petCtaButtonLink}
      showCta={false}
      showSnippets={false}
      darkBlueTheme
    />
  )
}

export default PillarsReview
