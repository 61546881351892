import { storyblokEditable } from "@storyblok/react"
import classNames from "classnames"
import { ConditionalBlok } from "../.."
import ExpenseCard from "../../../components/cards/ExpenseCard"
import PrimaryCard from "../../../components/cards/PrimaryCard"
import ShipmentCard from "../../../components/cards/ShipmentCard"

export interface IAdaptiveGrid {
  maxColumns: "1" | "2" | "3"
  cards: Array<any>
  bgColor: "blue16" | "white" | "orange1"
}
type IAdaptiveGridProps = ConditionalBlok<IAdaptiveGrid> & {
  className?: string
  noPadding?: boolean
}

export default function AdaptiveGrid(props: IAdaptiveGridProps) {
  const { noBlok, className, noPadding } = props
  const { maxColumns, cards, bgColor } = noBlok ? props : props.blok

  let cols

  if (maxColumns === "1") {
    cols = "grid-cols-1"
  } else if (maxColumns === "2") {
    cols = "grid-cols-1 sm:grid-cols-2"
  } else if (maxColumns === "3") {
    cols = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
  }

  return (
    <section
      {...(!noBlok && storyblokEditable(props.blok))}
      className={classNames("grid gap-6 ", cols, className, `bg-${bgColor}`, {
        "page-padding": !noPadding,
      })}
    >
      {cards.map((card, i) => {
        switch (card.component) {
          case "primaryCard":
            return (
              <PrimaryCard {...card} key={`adaptiveGrid/primaryCard${i}`} />
            )
          case "expenseCard":
            return (
              <ExpenseCard {...card} key={`adaptiveGrid/expenseCard/${i}`} />
            )
          case "shipmentCard":
            return (
              <ShipmentCard {...card} key={`adaptiveGrid/shipmentCard/${i}`} />
            )
          default:
            return null
        }
      })}
    </section>
  )
}
