import { FormEvent, HTMLProps, useState } from "react"
import { HiOutlineCube } from "react-icons/hi"
import dynamic from "next/dynamic"
import { isEmpty, merge } from "lodash"
import PrimaryButton from "../buttons/PrimaryButton"
import PickupIcon from "../../assets/icons/arrow-diagonal-thick-up.svg"
import DeliveryIcon from "../../assets/icons/arrow-diagonal-thick-down.svg"

import {
  MessageIcon,
  BoatIcon,
  BoxIcon,
  CarIcon,
  FreightIcon,
  FurnitureIcon,
  HomeIcon,
  MotorcycleIcon,
  PawIcon,
  TractorIcon,
} from "../svgs"

import EmailInput from "./EmailInput"
import Select, { ISelectProps } from "./Select"
import { ShipmentDataType, ErrorType, GoogleAddressData } from "./types"

import { generateFormQueryParams } from "../../services/links"
import { EMAIL_STORAGE } from "../../constants/storage"
import * as config from "../../util/config"

const PlaceInputAutocomplete = dynamic(
  () => import("../../components/form/place-input-autocomplete"),
  {
    ssr: false,
  },
)
interface IShippingFormProps extends HTMLProps<HTMLFormElement> {
  buttonLabel?: string
  section: string
  hasSelectCategory?: boolean
  hasPickupInput?: boolean
  hasDeliveryInput?: boolean
  selectCategoryPlaceholder?: string
  categoryOptions?: { label: string; value: string }[]
  buttonLink?: string
  unsubscribeText?: string
  variant?: string
  hasEmailField?: boolean
  className?: string
  formClassName?: string
}

const initialData = {
  delivery: null,
  pickup: null,
  email: "",
}
const selectOptions: ISelectProps["options"] = [
  "All Categories",
  {
    value: "animals",
    label: "Pets and animals",
    icon: <PawIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "motorcycles",
    label: "Motorcycles",
    icon: <MotorcycleIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "vehicles",
    label: "Vehicles",
    icon: <CarIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "boats",
    label: "Boats and water vehicles",
    icon: <BoatIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "household-items",
    label: "Household items",
    icon: <FurnitureIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "apartment-home-moves",
    label: "Apartment and home moves",
    icon: <HomeIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "heavy-equipment",
    label: "Heavy equipment and industrial",
    icon: <TractorIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "freight",
    label: "Freight",
    icon: <FreightIcon className="h-6 w-6 stroke-blue14" />,
  },
  {
    value: "others",
    label: "All other items",
    icon: <BoxIcon className="h-6 w-6 stroke-blue14" />,
  },
]
const host = config.getWebsiteHost()
const appHost = config.getAppHost()

const appendHostname = (currentRoute: string) => {
  const isAppRoute = currentRoute.includes("shipments/new")
  let path = `${isAppRoute ? appHost : host}/${currentRoute}`

  try {
    const url = new URL(currentRoute)
    path = url.toString()
  } catch {}
  return path
}

export default function ShippingForm({
  buttonLabel = "test",
  className,
  section,
  hasSelectCategory,
  hasPickupInput,
  hasDeliveryInput,
  hasEmailField = true,
  categoryOptions,
  selectCategoryPlaceholder = "What are you shipping",
  buttonLink = `${host}/shipments/new/`,
  unsubscribeText = `We'll keep your email secure. Unsubscribe at any time.`,
  variant,
  formClassName,
  ...props
}: IShippingFormProps) {
  const buttonLinkParts = buttonLink.split("?")

  const buttonLinkParams = Object.fromEntries(
    new URLSearchParams(buttonLinkParts[1]),
  )

  const [data, setData] = useState<ShipmentDataType>(initialData)
  const [errors, setErrors] = useState<ErrorType>({
    delivery: null,
    pickup: null,
    email: null,
  })
  const [selectInvalid, setSelectInvalid] = useState<boolean>(false)
  const handleLocationChange = (type: "delivery" | "pickup", location: any) => {
    setData((previousData) => ({
      ...previousData,
      [type]: location,
    }))
  }

  const handleLocationError = (type: "delivery" | "pickup", error: any) => {
    setErrors((previousErrors) => ({
      ...previousErrors,
      [type]: error,
    }))
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form = new FormData(e.currentTarget)

    const pickup = form.get(`${section}/pickupLocation`)
    const delivery = form.get(`${section}/deliveryLocation`)
    const email = form.get(`${section}/email`)
    const select = form.get(`${section}/select`)
    if (hasSelectCategory) {
      if (!select) {
        setSelectInvalid(true)
        return
      }
    }

    const shippingData = JSON.stringify({
      pickup: { address: pickup },
      delivery: { address: delivery },
    })
    let queryParams: Record<string, string | number> = {}

    if (hasPickupInput && data.pickup != null) {
      queryParams.pickupFA = data.pickup.formatted_address
      queryParams.pickupSC = data.pickup.state_code
      queryParams.pickupCt = data.pickup.city
      queryParams.pickupLat = data.pickup.lat
      queryParams.pickupLng = data.pickup.lng
      queryParams.pickupZip = data.pickup.zip ?? ""
    }

    if (hasDeliveryInput && data.delivery != null) {
      queryParams.deliveryFA = data.delivery.formatted_address
      queryParams.deliverySC = data.delivery.state_code
      queryParams.deliveryCt = data.delivery.city
      queryParams.deliveryLat = data.delivery.lat
      queryParams.deliveryLng = data.delivery.lng
      queryParams.deliveryZip = data.delivery.zip ?? ""
    }
    if (hasEmailField && email) {
      queryParams.email = email.toString()
      sessionStorage.setItem(EMAIL_STORAGE, email.toString())
    }

    const formQueryParams = generateFormQueryParams({
      query: isEmpty(buttonLinkParams)
        ? queryParams
        : merge(queryParams, buttonLinkParams),
    })

    sessionStorage.setItem("create-shipment-form", shippingData)
    const val = select ?? ""

    const path = appendHostname(buttonLinkParts[0])

    window.location.assign(`${path}${val}${formQueryParams}`)
  }

  const selectPlaceholder: ISelectProps["placeholder"] = {
    label: selectCategoryPlaceholder,
    icon: <HiOutlineCube className="h-6 w-6 stroke-blue14" />,
  }
  const options = categoryOptions || selectOptions
  return (
    <form
      className={`flex flex-col gap-4 ${className}`}
      onSubmit={handleSubmit}
      {...props}
    >
      {hasSelectCategory && (
        <Select
          options={options}
          placeholder={selectPlaceholder}
          name={`${section}/select`}
          className="font-montserrat"
          invalid={selectInvalid}
        />
      )}
      <div className={formClassName ? formClassName : "flex flex-col gap-4"}>
        {hasPickupInput && (
          <PlaceInputAutocomplete
            value={data.pickup}
            icon={PickupIcon.src}
            label="Pickup location"
            name={`${section}/pickupLocation`}
            onChange={(location: string) => {
              handleLocationChange("pickup", location)
            }}
            onError={(error: string) => {
              handleLocationError("pickup", error)
            }}
            error={errors.pickup}
            backgroundColor="white"
            className="w-full"
          />
        )}
        {hasDeliveryInput && (
          <PlaceInputAutocomplete
            value={data.delivery}
            icon={DeliveryIcon.src}
            label="Delivery location"
            name={`${section}/deliveryLocation`}
            onChange={(location: GoogleAddressData) => {
              handleLocationChange("delivery", location)
            }}
            onError={(error: string) => {
              handleLocationError("delivery", error)
            }}
            error={errors.delivery}
            backgroundColor="white"
            className="w-full"
          />
        )}
      </div>
      {hasEmailField && (
        <EmailInput
          label="Email"
          className="w-full bg-white border border-blue7/10 rounded font-montserrat"
          name={`${section}/email`}
        >
          <MessageIcon />
        </EmailInput>
      )}
      {unsubscribeText &&
        (variant === "overlay" ? (
          <p className="mx-0 mt-0 text-center text-sm -mb-2 text-white lg:text-gray9">
            {unsubscribeText}
          </p>
        ) : (
          <p
            className="m-0 text-center text-sm text-gray9"
            dangerouslySetInnerHTML={{ __html: unsubscribeText }}
          />
        ))}
      <PrimaryButton type="submit" className="w-full">
        {buttonLabel || "Get Instant Quotes"}
      </PrimaryButton>
    </form>
  )
}
