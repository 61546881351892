import React, { useState } from "react"
import { createUseStyles } from "react-jss"
import Image from "next/legacy/image"

import { fontWeight, fontSize } from "../styles/fonts-constants"
import { mediaQueries } from "../styles/breakpoints"
import colors from "../styles/colors"

import StarRating from "../components/star-rating-v2"
import star from "../assets/vector/star-new.svg"
import arrowLeft from "../assets/vector/meet-drivers/arrow-left.svg"
import arrowRight from "../assets/vector/meet-drivers/arrow-right.svg"
import { readImage } from "../app/utils/read-image"

const drivers = [
  {
    id: 0,
    shipments: "100+ completed",
    image: "/images/homepage/giga-international.jpg",
    alt: "Giga International Co Ltd",
    testimony: {
      text:
        "“Perfect job and communication for my old Harley Davidson Sportster! Thank you so much! See you next time.”",
      author: "Kimberly L.",
      origin: "via TrustPilot",
    },
    rating: { company: "Giga International Co Ltd", value: 5 },
  },
  {
    id: 1,
    shipments: "16+ completed",
    image: "/images/homepage/nico-mica@2x.jpg",
    alt: "Nico and Mica drivers",
    testimony: {
      text:
        "Nico & Mica were amazing! They transported my silver lab puppy from MN to ME... They’re extremely on top of keeping you in the loop with the whereabouts in travel they are with your pup and very understanding when you ask lots of questions! I’d use them again in a heartbeat and I highly recommend them to anyone. Thanks again Nico & Mica ❤️",
      author: "Kimberly L.",
      origin: "via TrustPilot",
    },
    rating: { company: "Nico & Mica Transport", value: 5 },
  },
  {
    id: 2,
    shipments: "164+ completed",
    image: "/images/homepage/baileys-best-care@2x.jpg",
    alt: "Bailey The Driver",
    testimony: {
      text:
        "Wow—this was a great experience! The word ‘care’ in the company name isn’t just a throw-in. It shows in how Shaun & the team help to make transporting a family member less stressful especially if you [like us] have never done this before. Special thanks to our driver, Larry. He’s caring, professional, flexible and someone we felt immediately comfortable with entrusting our daughter’s dog for a 3-day trip. Hard to see how you could do better than Bailey’s Best Care!",
      author: "Kimberly L.",
      origin: "via TrustPilot",
    },
    rating: { company: `Bailey's Best Care`, value: 5 },
  },
]

const useStyles = createUseStyles({
  drivers: {
    maxWidth: 1140,
    padding: "0 15px",
    position: "relative",
    margin: "0 auto 64px auto",
    [mediaQueries.desktop]: {
      marginBottom: 150,
    },
  },
  inner: {
    [mediaQueries.desktop]: {
      maxWidth: 470,
      marginLeft: "auto",
      marginRight: 50,
    },
  },
  title: {
    fontFamily: "var(--font-montserrat), sans-serif",
    color: "black",
    fontWeight: fontWeight.extraBold,
    fontSize: fontSize.extraLarge,
    margin: "0 0 32px 0",
    letterSpacing: "-0.5px",
    [mediaQueries.desktop]: {
      fontSize: fontSize.extraHuge,
      marginBottom: 24,
    },
  },
  role: {
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.12)",
    borderRadius: 6,
    padding: "9px 13px",
    backgroundColor: "white",
    transform: "translateY(-15px)",
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.extraSmall,
    "& h5": {
      lineHeight: 1.3125,
      margin: 0,
    },
    "& span": {},
    [mediaQueries.tablet]: {
      position: "absolute",
      left: "50%",
      bottom: 0,
      width: 380,
      fontSize: fontSize.small,
      transform: "translateX(-50%) translateY(50%)",
    },
  },
  driverAvatars: {
    maxWidth: 445,
    marginBottom: 22,
    [mediaQueries.tablet]: {
      marginBottom: 60,
    },
    [mediaQueries.desktop]: {
      position: "absolute",
      left: 15,
      top: 50,
      width: "calc(100% - 500px)",
      maxWidth: 440,
    },
    [mediaQueries.largeDesktop]: {
      maxWidth: 470,
    },
  },
  headline: {
    fontFamily: "'Roboto', sans-serif",
    color: "rgba(0, 0, 0, 0.8);",
    fontSize: fontSize.extraMedium,
    fontWeight: fontWeight.bold,
    lineHeight: 1.45,
    margin: "0 0 24px 0",
    "& span": {
      color: "#385993",
    },
    [mediaQueries.desktop]: {
      fontSize: fontSize.normal,
    },
  },
  statistics: {
    color: "black",
    display: "block",
    fontSize: fontSize.small,
    fontFamily: "'Roboto', sans-serif",
    margin: "0 0 24px 0",
    "& span": {
      fontWeight: fontWeight.superBold,
    },
    [mediaQueries.desktop]: {
      margin: "0 0 32px 0",
    },
  },
  driverAvatar: {
    position: "relative",
    "& img": {
      borderRadius: 10,
    },
  },
  quotes: {
    marginBottom: 28,
  },
  quote: {
    background: colors.gray10,
    borderRadius: 24,
    borderTopLeftRadius: 0,
    padding: "24px 16px",
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.small,
    color: "rgba(0, 0, 0, 0.8)",
    marginBottom: 26,
    "& p": {
      fontWeight: fontWeight.bold,
      lineHeight: 1.44,
      margin: "0 0 16px 0",
    },
    [mediaQueries.desktop]: {
      padding: "32px 24px",
      marginBottom: 32,
    },
  },
  author: {
    "& span": {
      fontWeight: fontWeight.bold,
    },
  },
  controlArrows: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [mediaQueries.desktop]: {
      justifyContent: "flex-start",
    },
  },
  controlArrow: {
    // background: "transparent",
    border: "none",
    width: 50,
    marginLeft: 24,
    boxSizing: "border-box",
    borderRadius: "5px",
    padding: "7px",
    // background: "#F6F8FE",
    background: "#FFF",

    "&[disabled]": {
      opacity: 0.3,
    },
    "& img": {
      display: "block",
      width: "100%",
    },
    [mediaQueries.desktop]: {
      margin: "0 24px 0 0",
    },
  },
  shipments: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: fontSize.extraMedium,
    "& span": {
      fontWeight: fontWeight.extraBold,
    },
  },
  hidden: {
    display: "none",
  },
  visible: {
    display: "block",
  },
})

const MeetDriver = ({
  driversData = [],
  title = "Meet the Drivers",
  headline = "Find feedback-rated and background-screened drivers who can safely and reliably transport just about anything. Match with a trusted driver who's perfect for your budget, schedule and shipping needs.",
}) => {
  const [currentDriverID, setCurrentDriverID] = useState(0)
  const classes = useStyles()
  const driversCrew = driversData.length > 0 ? driversData : drivers
  const setActive = (go) => {
    if (go === "prev") {
      if (currentDriverID > 0) {
        setCurrentDriverID((previousId) => {
          return previousId - 1
        })
      }
    }

    if (go === "next") {
      if (currentDriverID < driversCrew.length - 1) {
        setCurrentDriverID((previousId) => {
          return previousId + 1
        })
      }
    }
  }

  return (
    <div className={classes.drivers} id="homepage-meet-drivers">
      <div className={classes.inner}>
        <h3 className={classes.title}>{title}</h3>
        <div className={classes.driverAvatars}>
          {driversCrew.map((member, index) => (
            <div
              className={`${classes.driverAvatar} ${
                currentDriverID === index ? classes.visible : classes.hidden
              }`}
              key={member.id}
            >
              <Image
                src={readImage(member.image)}
                alt={member.image.alt || member.name}
                width={593}
                height={605}
                loading="lazy"
              />
              <div className={classes.role}>
                <h5>{member.rating.company}</h5>
                <div className={classes.rating}>
                  <StarRating
                    rating={member.rating.value}
                    star={star}
                    alt="star-rating"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <p className={classes.headline}>{headline}</p>
        <div className={classes.quotes}>
          {driversCrew.map((member, index) => (
            <div
              className={`${
                currentDriverID === index ? classes.visible : classes.hidden
              }`}
              key={index}
            >
              <div className={`${classes.quote}`}>
                <p>{member.testimony.text}</p>
              </div>
              <div className={classes.shipments}>
                <span>{member.shipments}</span> shipments so far
              </div>
            </div>
          ))}
        </div>
        <div className={classes.controlArrows}>
          <button
            className={classes.controlArrow}
            disabled={currentDriverID === 0}
            onClick={() => {
              setActive("prev")
            }}
            title="arrow-left"
            name="arrow-left"
          >
            <Image
              src={arrowLeft.src}
              height={arrowLeft.height}
              width={arrowLeft.width}
              alt="Arrow Left"
            />
          </button>
          <button
            className={classes.controlArrow}
            disabled={currentDriverID === driversCrew.length - 1}
            onClick={() => {
              setActive("next")
            }}
            title="arrow-right"
            name="arrow-right"
          >
            <Image
              src={arrowRight.src}
              height={arrowRight.height}
              width={arrowRight.width}
              alt="Arrow Right"
            />
          </button>
        </div>
      </div>
    </div>
  )
}

export default MeetDriver
