import { FC } from "react"
import classNames from "classnames"
import FirstVet from "../../assets/vector/first-vet.svg"
import IdentityVerified from "../../assets/vector/identity-verified.svg"
import Image from "next/legacy/image"
import Insurance from "../../assets/vector/insurance.svg"
import USDAVerified from "../../assets/vector/usda-verified.svg"

const Verifications: FC<{
  verified?: boolean
  onCallVet?: boolean
  usdaRegistered?: boolean
  insuranceVerified?: boolean
  isThumbnail?: boolean
}> = ({
  verified = true,
  onCallVet = true,
  usdaRegistered = true,
  insuranceVerified = true,
  isThumbnail,
}) => {
  return (
    <section>
      <div
        className={classNames(
          "grid grid-cols-1 md:grid-cols-2 sm:gap-1 md:gap-2 lg:gap-1.5",
        )}
      >
        {verified && (
          <div className="flex items-start">
            <Image
              height={24}
              width={24}
              src={IdentityVerified.src}
              alt="star"
              loading="lazy"
            />
            <span
              className={classNames(
                "pl-2 whitespace-nowrap ",
                isThumbnail ? " text-[15px]" : "text-secondary-900 font-medium",
              )}
            >
              {" "}
              Identity verified
            </span>
          </div>
        )}
        {onCallVet && (
          <div className="flex items-start">
            <Image
              height={24}
              width={24}
              src={FirstVet.src}
              alt="on call vet"
              loading="lazy"
            />
            <span
              className={classNames(
                "pl-2 whitespace-nowrap",
                isThumbnail ? " text-[15px]" : "text-secondary-900 font-medium",
              )}
            >
              {" "}
              24/7 On-call vet
            </span>
          </div>
        )}
        {usdaRegistered && (
          <div className="flex items-start">
            <Image
              height={24}
              width={24}
              src={USDAVerified.src}
              alt="usda verified"
              loading="lazy"
            />
            <span
              className={classNames(
                "pl-2 whitespace-nowrap",
                isThumbnail ? " text-[15px]" : "text-secondary-900 font-medium",
              )}
            >
              {" "}
              USDA Registered
            </span>
          </div>
        )}
        {insuranceVerified && (
          <div className="flex items-start">
            <Image
              height={24}
              width={24}
              src={Insurance.src}
              alt="insurance verified"
              loading="lazy"
            />
            <span
              className={classNames(
                "pl-2 whitespace-nowrap ",
                isThumbnail ? "text-[15px]" : "text-secondary-900 font-medium",
              )}
            >
              {" "}
              Insurance verified
            </span>
          </div>
        )}
      </div>
    </section>
  )
}
export default Verifications
