import { DetailedHTMLProps, HTMLAttributes, FC } from "react"
import Image from "next/image"
import { SbBlokData } from "@storyblok/react"
import { readImage } from "../../app/utils/read-image"
import { BlokImage } from "../../storyblok"
import { ImageItem } from "../../storyblok/types"

export interface FeatureItem
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  title: string
  href: string
  text: string
  image: BlokImage | ImageItem
}

export interface IFeatureCard extends SbBlokData {
  items?: FeatureItem[]
  title?: string
}

export const FeatureCardItem: FC<FeatureItem> = (props) => {
  return <div {...props} />
}

export const FeatureCardComponent = (props: IFeatureCard) => {
  const { items, title } = props
  return (
    <section className="mx-auto py-4 md:max-w-[720px] lg:max-w-[960px] xl:max-w-[1140px] text-center">
      {title && (
        <h2 className="mx-auto text-xl md:text-2xl font-montserrat my-8 sm:mb-10">
          {title}
        </h2>
      )}
      <ul className="grid grid-cols-2 lg:grid-cols-4 md:gap-4 mx-auto m-0 p-2">
        {items?.map((item, index) => (
          <li key={index} className="p-2 md:p-1">
            <a
              href={item.href}
              className="px-2 md:px-4 py-2 group font-montserrat hover:shadow-md flex flex-col justify-between no-underline hover:no-underline border border-gray-300 rounded-2xl overflow-hidden min-h-[232px] md:min-h-[295px] md:p-6 lg:p-4"
            >
              {item.image ? (
                <Image
                  className="w-12 h-12 object-cover transition-transform group-hover:translate-x-1 group-hover:-skew-y-1 duration-500 group-hover:scale-120 group-hover:focus:scale-120 group-hover:active:scale-120"
                  src={readImage(item.image)}
                  alt={item.image?.alt}
                  width={48}
                  height={48}
                />
              ) : (
                <div />
              )}
              <div className="flex flex-col justify-between min-h-[96px] text-left">
                <h3 className="text-base md:text-[26px] lg:text-2xl font-bold leading-8 min-h-[72px] md:min-h-[96px] md:leading-10 text-blue7 whitespace-pre-wrap m-0">
                  {item.title}
                </h3>
                <p className="text-sm lg:text-base leading-6 inline font-medium m-0 lg:whitespace-nowrap">
                  {item.text}
                </p>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </section>
  )
}
const FeatureCards = ({ blok }: { blok: IFeatureCard }) => (
  <FeatureCardComponent {...blok} />
)

export default FeatureCards
