import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Markdown from "../../components/content/Markdown"

export interface ITextColumnSection extends SbBlokData {
  header: string
  details: string
  column1: string
  column2: string
}

export default function TextColumnsSection({
  blok,
}: {
  blok: ITextColumnSection
}) {
  const { header, details, column1, column2 } = blok

  return (
    <section className="w-full relative page-padding ">
      {header && (
        <h3 className="mb-8 font-montserrat text-4xl text-blue7">{header}</h3>
      )}
      {details && <p className="text-xl">{details}</p>}
      <section className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <Markdown className="prose-li:text-xl">{column1}</Markdown>
        <Markdown className="">{column2}</Markdown>
      </section>
    </section>
  )
}
