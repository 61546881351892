import axios from "axios"
import { env } from "../config"

export default function getStorybookClient() {
  return axios.create({
    baseURL: "https://api-us.storyblok.com/v2/cdn/",
    timeout: 1000,
    params: { token: env("STORYBLOK_API_KEY") },
  })
}

export function toSentenceCase(str: string): string | void {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
  }
}
