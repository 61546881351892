import Image from "next/image"
import { HTMLAttributes } from "react"
import { BlokImage } from "../../storyblok"
import Markdown from "../content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IListCardProps extends HTMLAttributes<HTMLElement> {
  header: string
  markdown: string
  headerColor: "blue7" | "blue5"
  icon?: BlokImage
  component: "listCard"
}

export default function ListCard({
  header,
  markdown,
  icon,
  headerColor,
  className,
  ...props
}: IListCardProps) {
  return (
    <article
      className={
        "w-full flex rounded-xl bg-transparent text-black drop-shadow-[0_2px_8px_rgba(0,0,0,0.05)] " +
        className
      }
      {...props}
    >
      {icon?.id ? (
        <div className="relative h-[30px] w-[35px] mr-4">
          <Image
            className="object-contain"
            src={readImage(icon)}
            alt={icon.name}
            fill
          />
        </div>
      ) : null}
      <section className="w-full">
        <h5
          className={`text-2xl font-montserrat font-normal text-${headerColor}`}
        >
          {header}
        </h5>
        <Markdown className="max-w-none prose-h4:text-xl prose-h4:text-black prose-h4:font-normal prose-p:text-base">
          {markdown}
        </Markdown>
      </section>
    </article>
  )
}
