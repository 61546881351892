import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from ".."
import AdaptiveGrid, { IAdaptiveGrid } from "./layout/AdaptiveGrid"
import PrimaryCard, {
  IPrimaryCardProps,
} from "../../components/cards/PrimaryCard"
import { readImage } from "../../app/utils/read-image"

export interface ICostBreakdown extends SbBlokData {
  heading: string
  content: string
  image?: BlokImage
  maxColumns: "1" | "2" | "3"
  cards: Array<IPrimaryCardProps & SbBlokData>
}

export default function CostBreakdown({ blok }: { blok: ICostBreakdown }) {
  const { heading, content, image, maxColumns, cards } = blok

  const adaptiveGridBlok: IAdaptiveGrid = {
    maxColumns: maxColumns,
    cards: cards,
    bgColor: blok.bgColor as "blue16" | "white",
  }

  return (
    <section
      className="relative page-padding w-full flex flex-col"
      {...storyblokEditable(blok)}
    >
      <h3 className="font-montserrat mb-12 text-blue7 text-4xl text-center">
        {heading}
      </h3>
      {content && <p className="mb-12 text-center">{content}</p>}
      {image?.filename && (
        <div className="relative w-full md:w-4/5 mb-6 mx-auto h-52 sm:h-80 md:h-96 lg:h-[520px]">
          <Image
            className="object-cover rounded-lg"
            src={readImage(image)}
            alt={image.description || image.alt}
            fill
          />
        </div>
      )}
      <AdaptiveGrid noBlok {...adaptiveGridBlok} className="px-0 py-4" />
    </section>
  )
}
