import { FC } from "react"
import Link from "next/link"
import { SbBlokData } from "@storyblok/react"
import { marked } from "marked"

export interface IPremiumPetOntTheRoad extends SbBlokData {
  blok: {
    header: string
    buttonLabel: string
    buttonLink: string
    items: Array<
      {
        title: string
        markdown: string
      } & SbBlokData
    >
    customClasses: string
  }
}
const PetOntTheRoad: FC<IPremiumPetOntTheRoad> = (props) => {
  const { header, buttonLabel, buttonLink, items, customClasses } = props.blok
  return (
    <section
      id="pet-on-the-road"
      className="grid grid-cols-1 md:grid-cols-2 gap-12 max-w-5xl w-full mx-auto mt-2 md:mt-20 pt-10 md:pt-20 align-start px-6 lg:px-0 mb-10"
    >
      <article className={`px-4 text-left ${customClasses}`}>
        <h3 className="font-merriweather font-normal text-4xl mb-2 md:mb-24 leading-[54px] md:max-w-[456px]">
          {header}
        </h3>
        <Link
          className="hidden md:inline font-inter bg-blue7 text-white hover:text-white hover:no-underline uppercase text-center px-12 py-4 no-underline"
          href={buttonLink}
        >
          {buttonLabel}
        </Link>
      </article>
      <section className="grid grid-cols-1">
        {items?.map(({ title, markdown }, i) => (
          <div
            key={i}
            className="grid grid-cols-[50px_1fr] md:grid-cols-[70px_1fr] font-merriweather"
          >
            <div className="text-gold text-3xl md:text-5xl mr-10 w-20">
              {i + 1}
            </div>
            <div>
              {title && <h4 className="text-2xl text-inherit mb-4">{title}</h4>}
              <div
                dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }}
                className="max-w-none font-inter prose-h4:text-xl leading-6 prose-h4:text-black prose-h4:font-normal prose-p:text-[16px]"
              />
            </div>
          </div>
        ))}
        <Link
          className="md:hidden font-inter bg-blue7 text-white hover:text-white uppercase text-center px-12 py-4 no-underline"
          href={buttonLink}
        >
          {buttonLabel}
        </Link>
      </section>
    </section>
  )
}
export default PetOntTheRoad
