import * as React from "react"
import Image from "next/image"
import { SbBlokData, storyblokEditable } from "@storyblok/react/rsc"
import type { StaticImageData } from "next/legacy/image"
import { ShippingForm } from "../components/form/shipping-form"
import { Container } from "../components/container"

import { readImage } from "../utils/read-image"
import { BlokImage } from "../../storyblok"

export interface HomeHeroInterface extends SbBlokData {
  image: StaticImageData | BlokImage
  title: string
  description: string
  unsubscribe: string
  buttonLabel: string
}

export type HomeHeroProps = {
  blok: HomeHeroInterface
}

export const HomeHero = (props: HomeHeroProps) => {
  const { image, title, description, unsubscribe, buttonLabel } = props.blok
  return (
    <div
      className="relative isolate xl:min-h-[900px] overflow-hidden"
      {...storyblokEditable(props.blok)}
    >
      <div>
        <div className="w-full h-[300px] overflow-hidden relative lg:absolute lg:right-0 lg:top-0 lg:w-[70%] xl:w-[53%] lg:h-[900px]">
          <Image
            src={readImage(image)}
            alt="hero image"
            className="object-cover object-top overflow-x-visible"
            priority
            fill
            sizes="(max-width: 768px) 100vw, 55vw"
            quality={90}
          />
        </div>
      </div>
      <div className="relative mx-auto md:max-w-[600px] lg:max-w-[1380px] lg:pb-5 lg:pt-[75px] lg:translate-y-0">
        <Container>
          <ShippingForm
            title={title}
            unsubscribe={unsubscribe}
            description={description}
            buttonLabel={buttonLabel}
          />
        </Container>
      </div>
    </div>
  )
}
