import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from ".."
import Markdown from "../../components/content/Markdown"
import Table from "../../components/content/Table"

export interface IMarkdownAndTable extends SbBlokData {
  markdown: string
  image?: BlokImage
  table: any
}

export default function MarkdownAndTable({
  blok,
}: {
  blok: IMarkdownAndTable
}) {
  const { markdown, image, table } = blok

  return (
    <section
      className="relative page-padding w-full"
      {...storyblokEditable(blok)}
    >
      <Markdown className="prose-h2:text-4xl prose-h2:font-medium prose-p:text-2xl prose-p:font-normal">
        {markdown}
      </Markdown>
      <Table table={table} />
    </section>
  )
}
