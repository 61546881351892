import * as React from "react"
import classNames from "classnames"

type ButtonProps = {
  type?: "submit" | "button" | "reset"
  children: React.ReactNode
  onClick?: () => void
  fullWidth?: boolean
}

export const Button = (props: ButtonProps) => {
  const { type = "button" } = props

  return (
    <button
      type={type}
      onClick={props.onClick}
      className={classNames(
        "font-roboto p-5 text-xl font-normal transition-colors text-white bg-primary-500 hover:bg-primary-700 focus:ring-1 focus:ring-primary-700 rounded-md",
        props.fullWidth && "w-full",
      )}
    >
      {props.children}
    </button>
  )
}

type LinkProps = {
  children: React.ReactNode
  onClick?: () => void
  fullWidth?: boolean
}

export const Link = (props: LinkProps) => {
  return (
    <span
      className={classNames(
        "p-5 text-xl inline-flex font-semibold text-white bg-primary-500 focus:ring-1 focus:ring-primary-700 rounded-md",
        props.fullWidth && "w-full",
      )}
    >
      {props.children}
    </span>
  )
}
