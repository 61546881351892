import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Markdown from "../../components/content/Markdown"

export interface IMarkdownSection extends SbBlokData {
  markdown: string
}

export default function MarkdownSection({ blok }: { blok: IMarkdownSection }) {
  return (
    <section
      className="relative page-padding w-full"
      {...storyblokEditable(blok)}
    >
      <Markdown className="prose-h3:text-center">{blok.markdown}</Markdown>
    </section>
  )
}
