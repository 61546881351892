import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { BlokImage } from ".."
import Image from "next/image"
import { readImage } from "../../app/utils/read-image"

export interface IProcess extends SbBlokData {
  heading: string
  content: Array<
    {
      heading: string
      content: string
      highlighted: boolean
    } & SbBlokData
  >
  image: BlokImage
}

export default function Process({ blok }: { blok: IProcess }) {
  const { heading, content, image } = blok

  return (
    <section
      className="relative page-padding w-full flex"
      {...storyblokEditable(blok)}
    >
      <article className="w-full md:w-3/5 pr-10">
        <h3 className="mb-8 text-4xl text-blue7 text-left font-montserrat font-medium">
          {heading}
        </h3>
        <ol className="mx-0 px-0">
          {content.map((item, i) => (
            <li key={i} className="flex flex-col">
              <h3
                className={
                  "w-fit mb-3 text-2xl font-semibold font-montserrat bg-contain "
                }
                style={
                  item.highlighted
                    ? { backgroundImage: "url(/images/highlight.webp)" }
                    : {}
                }
              >
                {item.heading}
              </h3>
              <p className="mb-10 grow text-xl">{item.content}</p>
            </li>
          ))}
        </ol>
      </article>
      <div className="hidden md:block absolute top-0 right-0 md:w-2/5 h-full">
        <Image
          className="object-cover rounded-lg "
          src={readImage(image)}
          alt={image.description || image.alt}
          fill
        />
      </div>
    </section>
  )
}
