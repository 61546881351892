import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import Link from "next/link"
import { useRouter } from "next/router"
import Markdown from "../../../components/content/Markdown"
import PrimaryCard from "../../../components/cards/PrimaryCard"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { BlokImage } from "../.."
import PageNav, { NavigationType } from "../PageNav"
import useBusinessHours from "../../../util/useBusinessHours"
import Breadcrumb, { BreadcrumbItem } from "../../../components/breadcrumbs"
import { readImage } from "../../../app/utils/read-image"
interface IPetTransportationHeader extends SbBlokData {
  heading: string
  content: string
  image1: BlokImage
  image2: BlokImage
  stats: Array<
    {
      icon: BlokImage
      markdown: string
    } & SbBlokData
  >
  buttonLabel: string
  path?: string
  showButton?: boolean
  showHours?: boolean
  navItems: NavigationType["navItems"]
  categories: NavigationType["categories"]
  isNavHidden?: boolean
  mobileSubNavTitle?: NavigationType["mobileSubNavTitle"]
  externalPath?: string
  items?: BreadcrumbItem[]
}

export default function PetTransportationHeader({
  blok,
}: {
  blok: IPetTransportationHeader
}) {
  const router = useRouter()

  const {
    heading,
    content,
    image1,
    image2,
    stats,
    buttonLabel,
    path = "shipment/new",
    externalPath,
    showButton,
    showHours,
    navItems,
    categories,
    isNavHidden,
    mobileSubNavTitle,
    items,
  } = blok

  const param = router?.query?.kw || ""
  const { isInBusinessHours } = useBusinessHours()

  let className
  if (stats.length <= 3) {
    className = "flex flex-col sm:flex-row"
  } else {
    className = "grid grid-cols-1 md:grid-cols-2"
  }
  const handleCTAPath = () => {
    if (externalPath) {
      return router.push(externalPath)
    }

    return router.push(`/${path}`)
  }
  return (
    <>
      <PageNav
        noBlok
        buttonLabel={buttonLabel}
        route={path}
        navigationData={{
          navItems,
          categories,
          isNavHidden,
          mobileSubNavTitle,
        }}
        externalPath={externalPath}
      />
      <section
        className="w-full p-0 pt-20 m-0 -mb-[2px] bg-white"
        {...storyblokEditable(blok)}
      >
        <header className="relative header-padding w-full flex flex-row gap-12 lg:gap-14 xl:gap-10 mb-20 overflow-hidden">
          <article className="lg:w-2/5 m-auto">
            {items && <Breadcrumb items={items} />}
            <h1 className="text-5xl text-blue7 font-semibold font-montserrat">
              {param ? param : heading}
            </h1>
            <div>
              <Image
                className="lg:hidden float-right ml-2 mb-1 object-cover w-40 h-40 rounded-full"
                src={readImage(image2)}
                alt={image2.alt}
                width={732}
                height={1100}
                priority
              />
              <Markdown className="mb-8 text-2xl text-blue5 font-normal">
                {content}
              </Markdown>
            </div>
            <aside className={"my-4 " + className + " gap-4"}>
              {stats &&
                stats.map(({ icon, markdown }, i) => (
                  <PrimaryCard
                    icon={icon}
                    markdown={markdown}
                    key={`petHeader/${i}`}
                    className="prose-h4:text-blue7 prose-h4:text-2xl prose-h4:mb-1 prose-p:text-base bg-white sm:p-5"
                  />
                ))}
            </aside>
            {showButton && (
              <PrimaryButton onClick={handleCTAPath} className="my-4 w-full">
                {buttonLabel}
              </PrimaryButton>
            )}
            {showHours && isInBusinessHours && (
              <p className="text-gray7 text-center text-base font-roboto">
                or Call us on <Link href="tel:9034768036">(903) 476-8036</Link>{" "}
                to get a Quote
              </p>
            )}
          </article>

          <div className="relative hidden lg:flex items-center w-2/5 mt-4">
            <Image
              className="absolute w-4/5 max-h-full h-auto object-contain animate-oddStack"
              src={readImage(image1)}
              alt={image1.alt}
              width={732}
              height={1100}
              priority
            />
            <Image
              className="absolute w-4/5 max-h-full h-auto object-contain animate-evenStack"
              src={readImage(image2)}
              alt={image2.alt}
              width={1182}
              height={1776}
              priority
            />
          </div>
        </header>
      </section>
    </>
  )
}
