import { FC } from "react"
import Image from "next/image"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

export interface IPremiumAsFeaturedIn {
  blok: {
    header: string
    headerClass?: string
    logos?: {
      image: BlokImage
      width: number
      height: number
    }[]
  }
}
const ProductAsFeaturedIn: FC<IPremiumAsFeaturedIn> = (props) => {
  const { logos, header = "As featured in", headerClass } = props.blok
  return (
    <section id="as-featured-in" className="w-full mx-auto mb-4 py-12 md:pt-12">
      <div className="max-w-[1400px] mx-auto">
        <h2
          className={`mb-16 font-merriweather font-normal text-center uppercase ${headerClass}`}
        >
          <div className="flex items-center justify-center">
            <div className="border-t border-gold w-16 mr-2"></div>
            <span className="text-gold text-2xl uppercase font-merriweather">
              {header}
            </span>
            <div className="border-t border-gold w-16 ml-2"></div>
          </div>
        </h2>
        <div className="flex flex-col md:flex-row items-center px-12 md:px-2 md:justify-center gap-12 md:gap-10 lg:gap-x-24 xl:flex-nowrap">
          {logos?.map((logo) => (
            <Image
              src={readImage(logo.image)}
              alt={logo.image.alt}
              height={Number(logo.height) || 38}
              width={Number(logo.width) || 172}
              key={logo.image.id}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
export default ProductAsFeaturedIn
