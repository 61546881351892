import React, { useState, useEffect } from "react"
import axios from "axios"
import { createUseStyles } from "react-jss"
import pick from "lodash/pick"
import pickBy from "lodash/pickBy"
import identity from "lodash/identity"
import get from "lodash/get"
import uniq from "lodash/uniq"
import { isEmpty as isEmptyObject } from "lodash"
import BounceLoader from "../components/loader"

import ErrorMessageWrapper from "../components/form/error-message-wrapper"

import Input from "../components/form/cs-input"
import Label from "../components/form/label"
import Textarea from "../components/form/textarea"
import { mediaQueries } from "../styles/breakpoints"
import { validateEmail } from "../util/validation"

const useStyles = createUseStyles({
  root: {
    width: "100%",
    maxWidth: 809,
    paddingTop: 50,
    paddingBottom: 60,
    [mediaQueries.tablet]: {
      paddingTop: 60,
      paddingBottom: 120,
    },
  },
  input: {
    background: "white",
    width: "100%",
    marginBottom: 24,
    height: 55,
  },
  heading: {
    width: "100%",
    fontSize: 35,
    fontWeight: "bold",
    [mediaQueries.tablet]: {
      textAlign: "center",
      fontSize: 30,
    },
  },
  form: {
    paddingTop: 40,
    width: "100%",
    [mediaQueries.tablet]: {
      paddingTop: 30,
    },
  },
  halfContainer: {
    display: "inline-flex",
    flexDirection: "column",
    width: "100%",
    [mediaQueries.tablet]: {
      width: "50%",
    },
  },
  button: {
    background: "#1B64E1",
    boxShadow: "0px -15px 65px rgba(111, 111, 111, 0.05)",
    borderRadius: 4,
    height: 65,
    color: "white",
    width: "100%",
    fontSize: 20,
    fontWeight: "semibold",
    border: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  inputWrapper: {
    [mediaQueries.tablet]: {
      maxWidth: 400,
      paddingRight: 8,
    },
  },
  leftHalfContainer: {
    extend: "halfContainer",
    [mediaQueries.tablet]: {
      paddingRight: 12,
    },
  },
  rightHalfContainer: {
    extend: "halfContainer",
    [mediaQueries.tablet]: {
      paddingLeft: 12,
    },
  },
  errorWrapper: {
    alignSelf: "center",
  },
  buttonWrapper: {
    marginTop: 20,
    display: "flex",
    marginBottom: 20,
    flexDirection: "column",
  },
  bussinesProblemErrorMessage: {
    marginBottom: 32,
  },
})

const isEmpty = (field, fieldText) => {
  return field === "" || field == null
    ? `The ${fieldText} field is required.`
    : null
}

const validate = (data) => {
  return {
    first_name: isEmpty(data.first_name, "first name"),
    last_name: isEmpty(data.last_name, "last name"),
    email: validateEmail(data.email),
    // phone: isEmpty(data.phone, "phone"),
    business_name: isEmpty(data.business_name, "business name"),
    business_description: isEmpty(
      data.business_description,
      "business description",
    ),
    website: isEmpty(data.website, "website"),
    type_of_partnership: isEmpty(
      data.type_of_partnership,
      "type of partnership",
    ),
  }
}

const PartnershipForm = ({ header = "Partnership Inquiry Form" }) => {
  const classes = useStyles()
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    business_name: "",
    business_description: "",
    website: "",
    type_of_partnership: "",
  })

  const [showForm, setShowForm] = useState(true)
  const [errors, setErrors] = useState({})
  const [touched, setTouched] = useState([])
  const [serverError, setServerError] = useState()
  const validationErrors = validate(data)
  const pickErrors = pick(validationErrors, touched)
  const [isSaving, setIsSaving] = useState()

  useEffect(() => {
    setErrors(pickBy(pickErrors, identity))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, touched])

  const handleBlur = (field, _event) => setTouched(uniq([...touched, field]))

  const handleChange = (fieldName, value) =>
    setData({ ...data, [fieldName]: value })
  const url =
    "https://n8n.usecitizenshipper.com/webhook/partnership-inquiry-form"
  const auth = "Basic Y2l0aXplbnNoaXBwZXI6S3VSN04uZ3VhLmlF"
  const handleForm = (e) => {
    setIsSaving(true)
    if (!isEmptyObject(pickBy(validationErrors, identity))) {
      setTouched([touched, ...Object.keys(validationErrors)])
      setErrors(pickBy(validationErrors))
      setIsSaving(false)
    } else {
      e.preventDefault()
      return (
        axios
          .post(url, data, {
            headers: {
              Authorization: auth,
              "Content-Type": "application/json",
            },
          })

          /* eslint-disable */
          .then((response) => {
            setIsSaving(false)
            setShowForm(false)
          })
          .catch((error) => {
            setIsSaving(false)
            setServerError("Something went wrong. Try again later.")
          })
        /* eslint-enable */
      )
    }
  }

  return (
    <div className={classes.root}>
      <h4 className={"font-merriweather text-2xl md:text-4xl font-medium"}>
        {header}
      </h4>
      {!showForm ? (
        <div className="border mt-8 bg-white py-8 px-6 border-1 border-gray-100 rounded-lg shadow-md">
          <p className="text-lg">
            Thank You! We appreciate your interest in working with
            CitizenShipper.
          </p>
          <p className="text-lg">
            {" "}
            Our Partnerships team is looking forward to speaking with you, and
            will be in touch very soon.
          </p>
        </div>
      ) : (
        <div className={classes.form}>
          <div className="text-gray mb-4">
            <b>Note:</b> If you’d wish to become a transporter with us, please
            register as a transporter{" "}
            <a href="https://citizenshipper.com/new-drivers">here</a>.
          </div>
          <div className={classes.leftHalfContainer}>
            <Label>
              First name<span className="text-red-500 pl-1">*</span>
            </Label>
            <Input
              placeholder="Enter your first name"
              className={classes.input}
              onChange={(value) => handleChange("first_name", value)}
              value={data.first_name}
              onBlur={(e) => handleBlur("first_name", e)}
              error={errors.first_name != null && true}
            />
            {get(errors, "first_name", null) != null && (
              <ErrorMessageWrapper>{errors.first_name}</ErrorMessageWrapper>
            )}
          </div>
          <div className={classes.rightHalfContainer}>
            <Label>
              Last name <span className="text-red-500 pl-1">*</span>
            </Label>
            <Input
              placeholder="Enter your last name"
              className={classes.input}
              onChange={(value) => handleChange("last_name", value)}
              value={data.last_name}
              onBlur={(e) => handleBlur("last_name", e)}
              error={errors.last_name != null && true}
            />
            {get(errors, "last_name", null) != null && (
              <ErrorMessageWrapper>{errors.last_name}</ErrorMessageWrapper>
            )}
          </div>
          <div className={classes.inputWrapper}>
            <Label>
              Email<span className="text-red-500 pl-1">*</span>
            </Label>
            <Input
              className={classes.input}
              placeholder="Enter your email"
              onChange={(value) => handleChange("email", value)}
              value={data.email}
              error={errors.email != null && true}
            />
            {get(errors, "email", null) != null && (
              <ErrorMessageWrapper>{errors.email}</ErrorMessageWrapper>
            )}
          </div>
          <div className={classes.inputWrapper}>
            <Label>Phone number</Label>
            <Input
              className={classes.input}
              placeholder="(000) 000-0000"
              onBlur={(e) => handleBlur("phone", e)}
              onChange={(value) => handleChange("phone", value)}
              value={data.phone}
              error={errors.phone != null && true}
            />
            {get(errors, "phone", null) != null && (
              <ErrorMessageWrapper>{errors.phone}</ErrorMessageWrapper>
            )}
          </div>

          <div className={classes.leftHalfContainer}>
            <Label>
              Business Name<span className="text-red-500 pl-1">*</span>
            </Label>
            <Input
              className={classes.input}
              placeholder="Enter your business name"
              onChange={(value) => handleChange("business_name", value)}
              error={errors.business_name != null && true}
              value={data.business_name}
              onBlur={(e) => handleBlur("business_name", e)}
            />
            {get(errors, "business_name", null) != null && (
              <ErrorMessageWrapper>{errors.business_name}</ErrorMessageWrapper>
            )}
          </div>
          <div className="inputWrapper">
            <Label>
              Business Description<span className="text-red-500 pl-1">*</span>
            </Label>
            <Textarea
              placeholder="Type here..."
              onChange={(value) => handleChange("business_description", value)}
              value={data.business_description}
              onBlur={(e) => handleBlur("business_description", e)}
              error={errors.business_description != null && true}
            />
            {get(errors, "business_description", null) != null && (
              <ErrorMessageWrapper
                className={classes.bussinesProblemErrorMessage}
              >
                {errors.business_description}
              </ErrorMessageWrapper>
            )}
          </div>
          <div className="mt-4">
            <div className={classes.leftHalfContainer}>
              <Label>
                Website or Social Media URL{" "}
                <span className="text-red-500 pl-1">*</span>
              </Label>
              <Input
                className={classes.input}
                placeholder="Enter your website link"
                onChange={(value) => handleChange("website", value)}
                value={data.website}
              />
            </div>
          </div>
          <Label>
            What type of partnership do you foresee?
            <span className="text-red-500 pl-1">*</span>
          </Label>
          <Textarea
            placeholder="Type here..."
            onChange={(value) => handleChange("type_of_partnership", value)}
            value={data.type_of_partnership}
            onBlur={(e) => handleBlur("type_of_partnership", e)}
            error={errors.type_of_partnership != null && true}
          />
          {get(errors, "type_of_partnership", null) != null && (
            <ErrorMessageWrapper
              className={classes.bussinesProblemErrorMessage}
            >
              {errors.type_of_partnership}
            </ErrorMessageWrapper>
          )}
          <div className={classes.buttonWrapper}>
            <button
              onClick={(e) => handleForm(e)}
              className="inline-block mt-5 md:w-auto px-10 md:px-12 rounded-md py-3 md:py-4 text-center bg-blue7 hover:bg-blue-700 text-white hover:text-white text-md md:text-lg hover:no-underline"
              disabled={isSaving}
            >
              {isSaving ? (
                <BounceLoader backgroundColor={"white"} size={8} />
              ) : (
                <>Submit</>
              )}
            </button>
            <ErrorMessageWrapper className={classes.errorWrapper}>
              {serverError}
            </ErrorMessageWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default PartnershipForm
