import { RefObject, useEffect } from "react"

export default function useOnOutsideCLick(
  ref: RefObject<HTMLElement>,
  action: (e: MouseEvent) => void,
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !event.composedPath().includes(ref.current)) {
        event.stopPropagation()
        // event.preventDefault()
        action(event)
      }
    }

    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [ref, action])
}
