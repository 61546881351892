import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from ".."
import { AiOutlineCheckCircle } from "react-icons/ai"
import Markdown from "../../components/content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IPreparation extends SbBlokData {
  heading: string
  content: string
  checkList: Array<
    {
      content: string
    } & SbBlokData
  >
  image: BlokImage
}

export default function Preparation({ blok }: { blok: IPreparation }) {
  const { heading, content, checkList, image } = blok

  return (
    <section
      className="relative page-padding w-full grid grid-cols-1 md:grid-cols-2 gap-20 bg-gray11"
      {...storyblokEditable(blok)}
    >
      <article className="w-full">
        <h3 className="mb-5 font-montserrat font-medium text-4xl text-blue7 ">
          {heading}
        </h3>

        <Markdown className="prose-p:text-xl prose-p:font-normal prose-p:font-roboto">
          {content}
        </Markdown>

        <ul className="my-10 px-0">
          {checkList.map((item, i) => (
            <li key={i} className="flex">
              <AiOutlineCheckCircle className="h-8 w-8 min-w-[2rem] mr-5 fill-green5" />
              <p className="grow text-xl">{item.content}</p>
            </li>
          ))}
        </ul>
      </article>
      <div className="relative w-full">
        <Image
          className="object-contain rounded-lg"
          src={readImage(image)}
          alt={image.description || image.alt}
          fill
        />
      </div>
    </section>
  )
}
