import React from "react"
import { createUseStyles } from "react-jss"
import classNames from "classnames"

const unit = 8

const useStyles = createUseStyles({
  root: {
    color: "#D0021B",
    margin: `${unit * 1}px 0 ${unit * 2}px`,
    lineHeight: 1.4,
    fontSize: 14,
  },
})

const ErrorMessageWrapper = ({ children, className }) => {
  const classes = useStyles()
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

export default ErrorMessageWrapper
