import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Markdown from "../../components/content/Markdown"

export interface IShipSteps extends SbBlokData {
  heading: string
  content: string
  blocks: Array<
    {
      header: string
      content: string
    } & SbBlokData
  >
}

export default function ShipSteps({ blok }: { blok: IShipSteps }) {
  const { heading, content, blocks } = blok

  return (
    <article
      className="relative mx-auto page-padding w-full flex flex-col md:items-center bg-white"
      {...storyblokEditable(blok)}
    >
      <h3 className="mb-8 text-4xl text-blue7 text-left md:text-center font-montserrat font-medium">
        {heading}
      </h3>
      <Markdown className="md:px-32 md:text-center text-2xl mb-16">
        {content}
      </Markdown>
      <section className="w-full relative grid grid-cols-1 gap-8 mb-8">
        {blocks.map((block, i) => (
          <div
            key={block.header}
            className="w-full flex flex-col md:flex-row justify-between p-8 md:space-x-8 rounded-xl bg-white border-2 border-zinc-100"
          >
            <div className="w-10 h-10 flex justify-center items-center bg-gray12 relative rounded-full mb-10 font-semibold text-lg">
              {i + 1}
            </div>
            <div className="w-full space-y-4">
              <h4 className="text-xl">{block.header}</h4>
              <Markdown className="w-full max-w-none text-lg">
                {block.content}
              </Markdown>
            </div>
          </div>
        ))}
      </section>
    </article>
  )
}
