export function PawIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      stroke="currentColor"
    >
      <path d="M15.857 14.643C15.214 14 13.93 11.428 12 11.428c-1.928 0-3.214 2.572-3.857 3.214-.643.643-3.214 1.286-3.214 3.215 0 1.928 1.285 2.571 3.214 2.571s2.571-1.286 3.857-1.286 1.929 1.286 3.857 1.286c1.929 0 3.215-.643 3.215-2.571 0-1.929-2.572-2.572-3.215-3.214ZM16.393 6.928c0 .957-.228 1.786-.557 2.35-.34.583-.7.758-.943.758-.244 0-.603-.175-.943-.758-.33-.564-.557-1.393-.557-2.35 0-.956.228-1.785.557-2.35.34-.582.7-.757.943-.757.244 0 .603.175.943.758.33.564.557 1.393.557 2.35ZM10.607 6.928c0 .957-.228 1.786-.557 2.35-.34.583-.7.758-.943.758-.244 0-.603-.175-.943-.758-.33-.564-.557-1.393-.557-2.35 0-.956.228-1.785.557-2.35.34-.582.7-.757.943-.757.244 0 .603.175.943.758.33.564.557 1.393.557 2.35ZM6.107 10.786c0 .563-.172 1.045-.415 1.368-.242.323-.52.453-.763.453-.244 0-.522-.13-.764-.453-.243-.323-.415-.805-.415-1.368 0-.564.172-1.045.415-1.368.242-.324.52-.454.764-.454.243 0 .52.13.763.454.243.323.415.804.415 1.368ZM20.25 10.786c0 .563-.172 1.045-.415 1.368-.242.323-.52.453-.763.453-.244 0-.522-.13-.764-.453-.243-.323-.415-.805-.415-1.368 0-.564.172-1.045.415-1.368.242-.324.52-.454.764-.454.243 0 .52.13.763.454.243.323.415.804.415 1.368Z" />
    </svg>
  )
}
