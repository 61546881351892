import { SbBlokData } from "@storyblok/js"
import Image from "next/image"
import { BlokImage } from ".."
import { readImage } from "../../app/utils/read-image"

export interface IHeaderDescImage extends SbBlokData {
  header: string
  description: string
  image: BlokImage
  bgColor: "blue16" | "white"
}

export default function HeaderDescImage({ blok }: { blok: IHeaderDescImage }) {
  const { header, description, image, bgColor } = blok

  return (
    <section
      className={
        "page-padding relative w-full flex flex-col items-center " +
        `bg-${bgColor}`
      }
    >
      <h4 className="text-4xl text-center ">{header}</h4>
      <p className="w-full text-center text-xl max-w-2xl">{description}</p>
      <div className="relative">
        <Image
          className="object-contain"
          src={readImage(image)}
          alt={image.alt}
          fill
        />
      </div>
    </section>
  )
}
