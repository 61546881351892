import { SbBlokData } from "@storyblok/react"
import { marked } from "marked"
import { createUseStyles } from "react-jss"
import { Container, Row, Col } from "../../../components/layout/grid"
import { mediaQueries } from "../../../styles/breakpoints"
import colors from "../../../styles/colors"

export interface IPillarsOurPromise extends SbBlokData {
  id?: string
  title?: string
  description?: string
  blocks: Array<{
    id?: string
    markdown: string
  }> &
    SbBlokData
}

const useStyles = createUseStyles(
  {
    root: {
      background: colors.gray3,
      padding: "20px 16px",
      width: "100%",
      [mediaQueries.tablet]: {
        padding: "24px 20px",
      },
      [mediaQueries.desktop]: {
        padding: "36px 64px",
      },
    },
    petCoverageWrapper: {
      marginBottom: 40,
      marginTop: 30,

      [mediaQueries.desktop]: {
        marginTop: 100,
      },
    },
    mainTitle: {
      color: colors.blue5,
      fontSize: 24,
      textAlign: "left",
      fontFamily: "var(--font-montserrat), sans-serif",
      fontWeight: "600",

      [mediaQueries.tablet]: {
        padding: "30px 30px",
        fontSize: 34,
        textAlign: "center",
        lineHeight: "44px",
        fontFamily: "var(--font-montserrat), sans-serif",
        fontWeight: "600",
      },
    },
    article: {
      "& h4": {
        color: colors.blue5,
        fontSize: 20,
        fontFamily: "var(--font-montserrat), sans-serif",
        fontWeight: "bold",
        [mediaQueries.tablet]: {
          fontSize: 24,
          lineHeight: "32px",
        },
      },
      "& p ": {
        width: "95%",
      },
    },
    coverages: {
      width: "100%",
      margin: "auto",
      display: "flex",
      marginTop: 20,
      background: colors.gray3,

      flexWrap: "wrap",
      justifyContent: "space-between",
      [mediaQueries.tablet]: {
        flexDirection: "column",
        marginTop: 10,
        width: "100%",
      },
      [mediaQueries.desktop]: {
        flexDirection: "row",
      },
    },
    coverage: {
      width: "100%",
      marginTop: "20px",

      [mediaQueries.desktop]: {
        width: "45%",
      },
    },

    petProtectionWrapper: {
      [mediaQueries.tablet]: {
        width: "90%",
      },
      [mediaQueries.desktop]: {
        width: "95%",
      },
      [mediaQueries.largeDesktop]: {
        paddingLeft: 30,
        paddingRight: 30,
      },
    },

    description: {
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      fontSize: 16,
      lineHeight: "32px",
      [mediaQueries.tablet]: {
        width: "100%",
        textAlign: "center",
        fontSize: 20,
      },
    },
  },
  { link: true },
)
export default function PillarsOurPromise({
  blok,
}: {
  blok: IPillarsOurPromise
}) {
  const { id, title, description, blocks } = blok
  const classes = useStyles()
  return (
    <section id={id} className={classes.petCoverageWrapper}>
      <Container className={classes.petProtectionWrapper}>
        <Row>
          <Col sm={12} className={classes.root}>
            <h3 className={classes.mainTitle}>{title}</h3>

            <p className={classes.description}>{description}</p>

            <div className={classes.coverages}>
              {blocks &&
                blocks.map((blockCoverage) => {
                  const text = marked.parse(blockCoverage.markdown)
                  return (
                    <div className={classes.coverage} key={blockCoverage.id}>
                      <article
                        className={classes.article}
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    </div>
                  )
                })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
