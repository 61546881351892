export function TractorIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="currentColor"
      stroke="currentColor"
    >
      <circle r="2.75" fill="none" transform="matrix(-1 0 0 1 17.5 15.5)" />
      <circle r="3.25" fill="none" transform="matrix(-1 0 0 1 7 15)" />
      <path d="M12.53 16.03a.75.75 0 0 1-1.06-1.06l1.06 1.06zM3 12l.624.416A.75.75 0 0 1 2.249 12H3zm3.5-5-.728-.182.142-.568h.585V7zm6 0v-.75h.635l.104.626L12.5 7zm1 6 .74-.124a.75.75 0 0 1-.74.874V13zm-1.7.085-.698.274.698-.274zM7 10.75c-.397 0-1.05.103-1.712.38-.66.275-1.27.695-1.664 1.286l-1.249-.832c.607-.91 1.496-1.49 2.336-1.84C5.548 9.397 6.396 9.25 7 9.25v1.5zM2.25 12V10.5h1.5V12h-1.5zm0-1.5c0-.915.835-2.25 2.75-2.25v1.5c-1.086 0-1.25.664-1.25.75h-1.5zm3.305-2.816.217-.866 1.455.364-.216.866-1.456-.364zM6.5 6.25h6v1.5h-6v-1.5zm6.74.626 1 6-1.48.247-1-6 1.48-.247zm-1.77 8.093c-.118.118-.106.21-.085.09.014-.078.024-.208.015-.386a4.289 4.289 0 0 0-.297-1.314l1.397-.548c.252.643.373 1.272.398 1.789a3.28 3.28 0 0 1-.036.721c-.031.177-.105.483-.331.709l-1.06-1.06zm-.367-1.61c-.25-.64-.67-1.288-1.31-1.774-.634-.48-1.527-.835-2.792-.835v-1.5c1.566 0 2.783.447 3.697 1.139.906.686 1.472 1.582 1.802 2.423l-1.397.547zm2.397.39c-.368 0-1.239.055-1.645.084l-.107-1.496c.393-.028 1.32-.087 1.752-.087v1.5zM5 8.25c.06 0 .172-.03.302-.15.129-.12.22-.278.254-.415l1.456.363c-.11.44-.364.849-.692 1.152-.327.303-.786.55-1.32.55v-1.5z" />
      <path
        fill="none"
        d="M12 15.5h2.5M18.5 12.5c0-1-1-2.315-2.5-2.315h-2.5M6.5 7H5V5h7.2c.6 0 1.8 0 1.8 2h-1.8M6.5 7 6 10"
      />
    </svg>
  )
}
