import { SbBlokData, storyblokEditable } from "@storyblok/react"

import colors from "../../styles/colors"
import { createUseStyles } from "react-jss"
import { useRouter } from "next/router"

export interface IButtonProps {
  width: number | string
  bgColor: "primary"
  borderRadius?: "md" | "lg"
  lineHeight: number | string
  fontSize: number
  color: string
  fontWeight: 400 | 500 | 600 | 700
  margin: number | string
  display: "block" | "inline" | "inline-block" | "none" | "flex" | "inline-flex"
}
export interface IButtonBlok extends SbBlokData, IButtonProps {
  text: string
  url: string
}

const useStyles = createUseStyles<string, IButtonProps>(() => ({
  root: {
    padding: "14px 77px",
    borderRadius: ({ borderRadius }) => (borderRadius == "md" ? 6 : "50%"),
    width: ({ width }) => width,
    color: ({ color }) => (color == "white" ? colors.white1 : color),
    fontWeight: ({ fontWeight }) => fontWeight,
    fontSize: ({ fontSize }) => `${fontSize}px`,
    lineHeight: ({ lineHeight }) => lineHeight,
    margin: ({ margin }) => margin,
    display: ({ display }) => display,
    background: ({ bgColor }) =>
      bgColor === "primary" ? colors.blue8 : bgColor,
  },
}))

export default function Button({ blok }: { blok: IButtonBlok }) {
  const router = useRouter()
  const {
    text,
    bgColor,
    color,
    width,
    borderRadius,
    fontSize,
    margin,
    lineHeight,
    fontWeight,
    display,
    url,
  } = blok

  const classes = useStyles({
    bgColor,
    color,
    width,
    borderRadius,
    fontSize,
    margin,
    lineHeight,
    fontWeight,
    display,
  })

  return (
    <button
      className={classes.root}
      onClick={() => router.push(url)}
      {...storyblokEditable(blok)}
    >
      {text}
    </button>
  )
}
