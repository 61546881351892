import { BlokImage } from "../../storyblok"
import { HTMLAttributes } from "react"
import Image from "next/image"
import Markdown from "../content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IPrimaryCardProps extends HTMLAttributes<HTMLElement> {
  markdown: string
  icon?: BlokImage
  component?: "primaryCard"
  header?: string
}

export default function PrimaryCard({
  markdown,
  icon,
  className,
  header,
  ...props
}: IPrimaryCardProps) {
  return (
    <article
      className={
        "p-6 sm:p-8 md:p-6 rounded-xl bg-white text-black text-sm drop-shadow-[0_2px_8px_rgba(0,0,0,0.05)] " +
        className
      }
      {...props}
    >
      {icon?.id ? (
        <Image
          className="w-[72px] h-[72px] mb-4 p-6 rounded-full bg-gray12"
          src={readImage(icon)}
          alt={icon.name}
          height={72}
          width={72}
        />
      ) : null}
      {header && <h3 className="text-2xl text-inherit mb-1.5">{header}</h3>}
      <Markdown className="max-w-none prose-headings:font-montserrat prose-h4:text-xl prose-h4:text-black prose-h4:font-normal prose-p:text-[16px]">
        {markdown}
      </Markdown>
    </article>
  )
}
