import Image from "next/image"
import { FC } from "react"
import { marked } from "marked"
import { storyblokEditable } from "@storyblok/react"
import classNames from "classnames"
import { useRouter } from "next/router"

import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"
import ShippingForm from "../../components/form/ShippingForm"
import PageNav, { NavigationType } from "../../storyblok/sections/PageNav"
import Breadcrumb, { BreadcrumbItem } from "../../components/breadcrumbs"
import { env } from "../../config"

export interface IProductHero {
  blok: {
    title: string
    description: string
    buttonLink?: string
    buttonLabel?: string
    bgImage?: BlokImage
    bgSmImage?: BlokImage
    hasNoBg: boolean
    hasPickupLocation: boolean
    hasDeliveryLocation: boolean
    hasSmBgImage: boolean
    hideFieldsOnSm?: boolean

    heading: string
    details: string
    hasSelectCategory?: boolean
    hasPickupInput?: boolean
    hasDeliveryInput?: boolean
    hasEmailField?: boolean
    selectCategoryPlaceholder?: string
    categoryOptions: { label: string; value: string }[]
    unsubscribeText?: string
    caption1?: string
    caption2?: string
    navItems: NavigationType["navItems"]
    categories: NavigationType["categories"]
    isNavHidden?: boolean
    ctaRoute?: string
    items?: BreadcrumbItem[]
  }
}

const ProductHero: FC<IProductHero> = (props) => {
  const { query } = useRouter()

  const APP_HOST = env("APP_HOST")

  const {
    description = "Transport your pet safely with quality reliable drivers using CitizenShipper",
    title = "#1 Pet Transport Service in the USA",
    buttonLabel = "Get Quotes Now",
    buttonLink = "/shipments/new/",
    hasEmailField = false,
    bgImage,
    hideFieldsOnSm = false,
    hasPickupLocation,
    hasDeliveryLocation,
    hasSmBgImage,
    hasSelectCategory,
    categoryOptions = [],
    selectCategoryPlaceholder = "What are you shipping",

    unsubscribeText = "",
    navItems,
    categories,
    isNavHidden,
    ctaRoute,
    items,
  } = props.blok
  const LINK = buttonLink ?? `${APP_HOST}/shipments/new`
  const param = (query?.kw as string) || ""
  const handleClick = () => {
    return window.location.assign(LINK)
  }
  return (
    <>
      <PageNav
        noBlok
        route={ctaRoute || buttonLink}
        buttonLabel={buttonLabel}
        hideNavLinks
        navigationData={{ navItems, categories, isNavHidden }}
      />
      <section className="relative hero overflow-x-hidden lg:-mt-10">
        <div
          className={classNames(
            "relative lg:absolute top-0 right-0 lg:w-[40%] xl:w-[50%] lg:h-full w-full h-[320px] overflow-hidden lg:overflow-visible",
            hasSmBgImage ? "block" : "hidden md:block",
          )}
        >
          {bgImage && (
            <Image
              className="scale-110 lg:scale-100 object-cover object-[-18px_36%] lg:object-center overflow-x-visible"
              src={readImage(bgImage)}
              alt={bgImage?.alt || "ship pets"}
              fill
              priority={true}
            />
          )}
        </div>
        <header
          {...storyblokEditable(props.blok)}
          className="relative header-padding xl:min-h-[540px] xl:max-h-[600px] 2xl:pb-8 lg:pt-16 pt-0 w-full flex flex-col lg:flex-row lg:gap-14 xl:gap-28 overflow-hidden"
        >
          <article className="relative w-full lg:w-2/5 z-10">
            {items && <Breadcrumb items={items} />}
            <h1 className="text-4xl md:text-5xl text-blue7 font-semibold font-montserrat">
              {param ? param : title}
            </h1>
            <p
              className="mb-8 text-xl text-blue5 font-normal"
              dangerouslySetInnerHTML={{ __html: marked(description) }}
            />

            <button
              onClick={handleClick}
              className="w-full py-[15px] text-center bg-blue7 hover:bg-blue-700 text-white text-2xl rounded-lg md:rounded-2xl sm:hidden"
            >
              {buttonLabel}
            </button>
            <div
              className={classNames(
                hideFieldsOnSm ? "hidden sm:block" : "block",
              )}
            >
              <ShippingForm
                section="pageHeader"
                hasSelectCategory={hasSelectCategory}
                hasPickupInput={hasPickupLocation}
                hasDeliveryInput={hasDeliveryLocation}
                hasEmailField={hasEmailField}
                categoryOptions={categoryOptions}
                selectCategoryPlaceholder={selectCategoryPlaceholder}
                buttonLink={buttonLink}
                buttonLabel={buttonLabel}
                unsubscribeText={unsubscribeText}
                formClassName="flex md:flex-row gap-4 between w-full group mb-2"
              />
            </div>
          </article>
        </header>
      </section>
    </>
  )
}
export default ProductHero
