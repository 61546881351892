import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Link from "next/link"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import useCTARoute from "../../util/useCTARoute"

export interface INoHassleCTA extends SbBlokData {
  heading: string
  details: string
  buttonLabel: string
  bgColor: "white" | "orange1"
  path?: string
}

export default function NoHassleCTA({
  blok,
  host,
}: {
  blok: INoHassleCTA
  host?: Record<string, string>
}) {
  const { heading, details, buttonLabel, bgColor, path } = blok

  const { route } = useCTARoute()

  return (
    <section
      className={`relative page-padding w-full flex flex-col items-center text-center bg-${bgColor}`}
      {...storyblokEditable(blok)}
    >
      <h3 className="max-w-prose mb-6 font-montserrat text-blue7 text-4xl">
        {heading}
      </h3>
      <p className="max-w-prose mb-12 font-base text-xl">{details}</p>
      <Link
        className="max-w-prose w-full text-white hover:text-white no-underline hover:no-underline"
        href={path ? `/${path}` : route}
      >
        <PrimaryButton className="w-full rounded-lg">
          {buttonLabel}
        </PrimaryButton>
      </Link>
    </section>
  )
}
