import { SbBlokData, storyblokEditable } from "@storyblok/react"
import Image from "next/image"
import { BlokImage } from ".."
import Markdown from "../../components/content/Markdown"
import classNames from "classnames"
import { readImage } from "../../app/utils/read-image"

export interface IFactors extends SbBlokData {
  heading?: string
  markdown?: string
  content: Array<{
    image: BlokImage
    heading: string
    details: string
  }>
  type?: string
}

export default function Factors({ blok }: { blok: IFactors }) {
  const { heading, content, markdown, type } = blok

  return (
    <section
      className="relative page-padding w-full flex flex-col bg-white"
      {...storyblokEditable(blok)}
    >
      {heading && (
        <h3 className="mb-16 font-montserrat text-blue7 text-4xl text-center">
          {heading}
        </h3>
      )}

      {markdown && <Markdown>{markdown}</Markdown>}

      <article
        className={classNames(
          "mt-8 flex flex-col md:flex-row gap-8 lg:gap-x-16 basis-0",
          { "lg:gap-x-14": type },
        )}
      >
        {content.map((factor, i) => (
          <section
            className="md:max-w-prose w-full flex flex-col"
            key={`Factors/${i}`}
          >
            <Image
              className={classNames("mb-12", {
                "w-full h-[250px]  shadow-sm border border-black object-top object-cover rounded-lg": type,
              })}
              src={readImage(factor.image)}
              alt={factor.image.description || factor.image.alt}
              width={180}
              height={180}
            />
            <h4
              className={classNames(
                "mb-3 font-montserrat text-2xl font-semibold",
                { "text-xl": type },
              )}
            >
              {factor.heading}
            </h4>
            <Markdown className={classNames({ "prose-p:text-[15px]": type })}>
              {factor.details}
            </Markdown>
          </section>
        ))}
      </article>
    </section>
  )
}
