import { SbBlokData } from "@storyblok/react"
import { HTMLAttributes, FC } from "react"
import { BlokImage } from ".."
import MeetDrivers from "../../sections/homepage-meet-drivers"

export type TestimonyType = {
  text: string
  author: string
  origin: string
}
export type HomeTestimonyProps = TestimonyType & HTMLAttributes<HTMLElement>

export const HomeTestimony: FC<HomeTestimonyProps> = (props) => {
  return <div {...props} />
}

export type HomeMeetDriversCardType = {
  id: number
  shipments: string
  image: BlokImage
  alt?: string
  testimony: TestimonyType[]
  ratingCompany: string
  ratingValue: number
}
export type HomeMeetDriversCardProps = TestimonyType &
  HTMLAttributes<HTMLElement>

export const HomeMeetDriversCard: FC<HomeMeetDriversCardProps> = (props) => {
  return <div {...props} />
}

export interface IHomeMeetTheDrivers extends SbBlokData {
  title?: string
  headline?: string
  drivers: HomeMeetDriversCardType[]
}
const HomeMeetTheDrivers = ({ blok }: { blok: IHomeMeetTheDrivers }) => {
  const { drivers, title, headline } = blok

  const driversData =
    drivers?.map((item) => ({
      ...item,
      testimony: item.testimony[0],
      image: {
        ...item.image,
        src: item.image?.filename,
      },
      rating: { company: item.ratingCompany, value: Number(item.ratingValue) },
    })) ?? []

  return (
    <MeetDrivers driversData={driversData} title={title} headline={headline} />
  )
}

export default HomeMeetTheDrivers
