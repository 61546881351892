import { SbBlokData, storyblokEditable } from "@storyblok/react"
import { BlockList } from "net"
import { BlokImage } from ".."
import Markdown from "../../components/content/Markdown"
import TableSubsection, { ITableSubsection } from "./layout/TableSubsection"
import Image from "next/image"

export interface ITableColumnSection extends SbBlokData {
  header: string
  details: string
  image: BlokImage
  column1: string
  column2: ITableSubsection[]
}

export default function TableColumnSection({
  blok,
}: {
  blok: ITableColumnSection
}) {
  const { header, details, image, column1, column2 } = blok

  const section = column2[0]

  return (
    <section className="w-full relative page-padding space-y-8">
      {header && (
        <h3 className="font-montserrat text-4xl text-blue7 text-center">
          {header}
        </h3>
      )}
      {details && (
        <Markdown className="prose-p:text-xl text-center">{details}</Markdown>
      )}
      <section className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <Markdown className="prose-li:text-xl">{column1}</Markdown>
        {section && (
          <TableSubsection
            blok={{
              header: section.header,
              markdown: section.markdown,
              table: section.table,
              _uid: section._uid,
              component: section.component,
            }}
          />
        )}
      </section>
    </section>
  )
}
