import { createUseStyles } from "react-jss"
import { SbBlokData } from "@storyblok/react"
import Link from "next/link"

import { FiveStarRatingIcon } from "../../../components/svgs/FiveStarRating"

import colors from "../../../styles/colors"

const useStyles = createUseStyles({
  row: {
    margin: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& small": {
      fontSize: 13,
      lineHeight: "20px",
    },
  },
  rowRatings: {
    marginBottom: 5,
  },
  rowReviews: {
    color: colors.blue7,
  },
})
export interface IPillarsSubReview extends SbBlokData {
  reviews: string
  ratings: string
  url?: string
}
const PillarsSubReview = ({ blok }: { blok: IPillarsSubReview }) => {
  const { rating, reviews, url = "https://citizenshipper.com/reviews" } = blok
  const classes = useStyles()

  return (
    <section className={classes.row}>
      <FiveStarRatingIcon />
      <p className={classes.rowRatings}>
        {`Customer satisfaction rating: ${rating}`}
      </p>
      <small className={classes.rowReviews}>
        <Link target={"_blank"} href={url}>
          {reviews} reviews
        </Link>
      </small>
    </section>
  )
}

export default PillarsSubReview
