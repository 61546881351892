export const generateFormQueryParams = (
  options: {
    query?: Record<string, string | number>
  } = {},
) => {
  const queryParams = new URLSearchParams()
  if (options.query) {
    for (const [key, value] of Object.entries(options.query)) {
      if (value != "") {
        queryParams.set(key, String(value))
      }
    }
  }

  const queryParamsAsString = queryParams.toString()

  return queryParamsAsString.length > 0 ? `?${queryParamsAsString}` : ""
}
