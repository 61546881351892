import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { env } from "../../config"
import storyBlokClient from "../../util/storyblokClient"
import { BlokReviewsType, ReviewsContextType } from "./types"

const ReviewsContext = createContext<ReviewsContextType | undefined>(undefined)

ReviewsContext.displayName = "ReviewsContext"

export const ReviewsContextProvider = ({
  preview = false,
  children,
}: PropsWithChildren<{ preview?: boolean; host?: Record<string, string> }>) => {
  const [blokReviews, setBlokReviews] = useState<BlokReviewsType | null>(null)
  const token = env("STORYBLOK_API_KEY") || "22kQyyrYwHyhjxA6JTmYmAtt"

  useEffect(() => {
    const fetchData = async () => {
      if (!token) return
      try {
        const { data } = await storyBlokClient().get(
          `datasource_entries?datasource=reviews-data&token=${token}`,
        )

        const reviews = data.datasource_entries.reduce(
          (acc: Record<string, string>, curr: Record<string, string>) => {
            acc[curr.name.trim()] = curr.value
            return acc
          },
          {},
        )

        setBlokReviews(reviews)
      } catch (error) {
        console.error(`Fetch Reviews failed: ${error}`)
      }
    }

    fetchData()
  }, [preview, token])

  const value = useMemo(
    () => ({
      blokReviews,
    }),
    [blokReviews],
  )

  return (
    <ReviewsContext.Provider value={value}>{children}</ReviewsContext.Provider>
  )
}

export const useReviewsContext = () => {
  const context = useContext(ReviewsContext)

  if (context === undefined) {
    throw new Error(
      `useReviewsContext must be used within a ReviewsContextProvider`,
    )
  }

  return context
}

export default ReviewsContextProvider
