import Image from "next/image"
import { HTMLAttributes } from "react"
import { BlokImage } from "../../storyblok"
import Markdown from "../content/Markdown"
import { readImage } from "../../app/utils/read-image"

export interface IIconCardProps extends HTMLAttributes<HTMLElement> {
  heading: string
  markdown: string
  icon: BlokImage
}

export default function IconCard({
  heading,
  markdown,
  icon,
  className,
  ...props
}: IIconCardProps) {
  return (
    <article
      className={
        "w-full flex flex-col md:flex-row justify-between p-8 md:space-x-8 rounded-xl bg-gray12 text-black text-lg " +
        className
      }
      {...props}
    >
      <Image
        className="w-[72px] h-[72px] p-6 rounded-full bg-white mb-5"
        src={readImage(icon)}
        alt="icon"
        height={72}
        width={72}
      />
      <div className="w-full space-y-4">
        <h4 className="text-roboto text-lg font-semibold">{heading}</h4>
        <Markdown className="max-w-none pt-4 sm:pt-0  prose-p:text-lg">
          {markdown}
        </Markdown>
      </div>
    </article>
  )
}
