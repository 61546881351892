import React from "react"
import PropTypes from "prop-types"
import { createUseStyles } from "react-jss"

import classNames from "classnames"

import { noop } from "../../util/fp"

const unit = 8

const useStyles = createUseStyles({
  root: {
    fontSize: 16,
    fontWeight: 300,
    color: "black",
    overflowX: "hidden",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    resize: "none",
    width: "100%",
    lineHeight: 1.6,
    padding: unit * 2,
    height: 166,
    boxSizing: "border-box",
    borderRadius: 2,
    border: `1px solid #7D7D7D`,
    WebkitAppearance: "none",
    "&:focus": {
      outline: `2px solid #1B53E1`,
    },
  },
  error: {
    extend: "root",
    border: `solid 1px #D0021B`,
  },
})

const Textarea = ({
  height = 102,
  placeholder,
  className,
  onChange = noop,
  error,
  onBlur = noop,
  value = "",
}) => {
  const classes = useStyles()
  return (
    <textarea
      className={classNames({
        [classes.root]: true,
        [classes.error]: error && true,
        [className]: className,
      })}
      placeholder={placeholder}
      value={value}
      style={{ height }}
      onChange={(e) => {
        e.preventDefault()
        onChange(e.target.value)
      }}
      onBlur={(e) => {
        e.preventDefault()
        onBlur(e)
      }}
    />
  )
}

Textarea.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  height: PropTypes.number,
}

export default Textarea
