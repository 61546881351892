import { AiOutlineCheckCircle } from "react-icons/ai"
import { HTMLAttributes } from "react"
import Markdown from "./Markdown"
import Image from "next/image"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

interface IListItem extends HTMLAttributes<HTMLDetailsElement> {
  icon: BlokImage
  content: string
}

export default function ListItem({ icon, content, className }: IListItem) {
  return (
    <li className={"flex space-x-4 text-xl mb-4 " + className}>
      {icon.filename && (
        <Image
          className="object-contain mr-3"
          src={readImage(icon)}
          alt={icon.alt}
          height={24}
          width={24}
        />
      )}
      <Markdown className="prose-p:text-lg">{content}</Markdown>
    </li>
  )
}
