import dynamic from "next/dynamic"

import { DriverIcon } from "./svgs"

const FastStopwatchIcon = dynamic(() => import("./svgs/FastStopwatchIcon"))
const TransportIcon = dynamic(() => import("./svgs/TransportIcon"))

export type IHeroStatsProps = {
  stats: { name: string; title: string; description: string }[]
}

type IconType = "driver" | "platform" | "years"

const icons: Record<IconType, JSX.Element> = {
  driver: <DriverIcon className="fill-blue7" />,
  platform: <TransportIcon className="fill-blue7" />,
  years: <FastStopwatchIcon className="fill-blue7" />,
}

function HeroStats(props: IHeroStatsProps) {
  const { stats } = props

  return (
    <section className="md:flex md:gap-6 lg:gap-12">
      {stats?.map((item) => (
        <aside
          className="max-w-full w-80 mx-auto flex flex-row mb-12  lg:min-w-[327px] "
          key={item.name}
        >
          <div className="h-20 w-20 mr-4 sm:mr-0 mb-6 p-4 rounded-full flex items-center justify-center bg-white drop-shadow-md">
            {icons[item?.name as IconType]}
          </div>
          <div className="ml-8 md:ml-4 lg:ml-8">
            <p className="mb-1 mt-0 text-blue7 text-lg font-montserrat">
              {item.title}
            </p>
            <p className="m-0 text-base text-gray14">{item.description}</p>
          </div>
        </aside>
      ))}
    </section>
  )
}
export default HeroStats
