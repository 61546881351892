import { HTMLAttributes, useState } from "react"

import Markdown from "./Markdown"
import MinusIcon from "../svgs/MinusIcon"
import PlusIcon from "../svgs/PlusIcon"

export interface IDetailProps extends HTMLAttributes<HTMLDetailsElement> {
  summary: string
  children: string
  open?: boolean
}

export default function Details({ summary, children, ...props }: IDetailProps) {
  const [open, setOpen] = useState(props.open ?? true)
  return (
    <details
      {...props}
      className="w-full px-2 py-4 md:p-6 border border-gray-200 rounded-xl"
      open={open}
      onClick={(e) => e.preventDefault()}
    >
      <summary
        className="flex items-center font-montserrat font-semibold text-lg md:text-2xl list-none "
        onClick={() => setOpen(!open)}
      >
        {summary}
        {open && <MinusIcon className="ml-auto" />}
        {!open && <PlusIcon className="h-5 w-5 ml-auto" />}
      </summary>
      <Markdown className="mt-4 text-base">{children}</Markdown>
    </details>
  )
}
