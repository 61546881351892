import { FC } from "react"
import Image from "next/image"
import { BlokImage } from "../../storyblok"
import { readImage } from "../../app/utils/read-image"

export interface Props {
  blok: {
    headerText: string
    subText: string
    features: {
      icon: BlokImage
      text: string
    }[]
    image: BlokImage
  }
}
const PremiumPriceTag: FC<Props> = (props) => {
  const { headerText, subText, features, image } = props.blok
  return (
    <div className="bg-white p-8 max-w-7xl mx-auto w-full mb-14">
      <div className="flex flex-col md:flex-row justify-center mx-auto">
        <div className="md:w-1/2">
          <Image
            src={readImage(image)}
            alt="Man with dog"
            className="rounded-lg object-cover w-full md:w-[452px] md:h-[551px] "
            width={452}
            height={551}
            loading="lazy"
          />
        </div>
        <div className="mt-10 md:mt-0 md:w-1/2 md:pl-10 lg:pl-4">
          <h2 className="text-3xl xl:text-[36px] font-merriweather max-w-md mb-6 leading-[54px] -mt-2">
            {headerText}
          </h2>
          <p className="text-xl xl:text-2xl mb-12 pb-6 max-w-xl">{subText}</p>
          <div className="grid grid-cols-2 justify-center md:justify-between gap-4 md:gap-6">
            {features.map((feature) => (
              <div key={feature.icon.alt} className="mb-4 md:mb-0">
                <Image
                  src={readImage(feature.icon)}
                  alt={feature.icon.alt}
                  className="w-16 h-16 mb-6"
                  width={64}
                  height={64}
                />

                <span>{feature.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PremiumPriceTag
