import { FC } from "react"
import PartnershipForm from "../../sections/partnership-form"

export interface IBusinessOpportunityForm {
  blok: {
    header?: string
  }
}
const BusinessOpportunityForm: FC<IBusinessOpportunityForm> = ({
  blok: { header },
}) => {
  return (
    <section
      className="mx-auto flex justify-center -mb-16 px-4 "
      id="business-opportunity-form"
    >
      <PartnershipForm header={header} />
    </section>
  )
}
export default BusinessOpportunityForm
