export const scase = (cases, val) => {
  if (val in cases) {
    return cases[val]
  } else if ("default" in cases) {
    return cases.default
  } else {
    return null
  }
}

export const scaseOr = (def, cases, val) => {
  if (val in cases) {
    return cases[val]
  } else {
    return def
  }
}

export const lcase = (predicates, _default = null) => {
  for (let i = 0; i < predicates.length; i++) {
    if (predicates[i][0]) {
      return predicates[i][1]
    }
  }

  return _default
}

export const id = (x) => x

export const noop = () => {}
