import { Fragment } from "react"
import { marked } from "marked"
import Image from "next/legacy/image"
import star from "../../../assets/vector/star-new.svg"
import fbook from "../../../assets/vector/facebook-vector-logo.svg"
import tpilot from "../../../assets/vector/trustpilot-vector-logo.svg"
import { BlokImage } from "../.."
import { readImage } from "../../../app/utils/read-image"
type Props = {
  name: string
  reviewerImage: BlokImage
  rating: number
  platformIcon: "facebook" | "trustpilot"
  title: string
  description: string
  className?: string
}

const PillarSingleReviewCard = ({ blok }: { blok: Props }) => {
  const {
    name,
    reviewerImage,
    rating,
    platformIcon,
    title,
    description,
    className,
  } = blok

  const content = marked.parse(description)
  const applyRating = (rating: number, star: any) => {
    return Array(Number(rating))
      .fill(
        <Image
          height={star.height}
          width={star.width}
          src={star.src}
          alt="star"
          loading="lazy"
        />,
      )
      .map((star, id) => <Fragment key={id}>{star}</Fragment>)
  }

  const logoList = { facebook: fbook, trustpilot: tpilot }

  return (
    <div
      className={`my-5 bg-blue11 p-5 w-full rounded-md md:my-8 md:max-w-[460px] lg:h-auto lg:my-10 lg:max-w-[540px] mx-auto ${
        className ?? ""
      }`}
    >
      <div className="flex justify-between items-center mb-5 max-w-[992px]">
        <div className="flex items-center h-[75px] md:flex-row">
          <img
            className="rounded-full h-12 w-12 md:h-16 md:w-16"
            src={readImage(reviewerImage)}
            alt={reviewerImage?.alt || "reviewer"}
          />
          <div className="flex flex-col ml-1.5">
            <div className="font-roboto font-bold text-sm h-7 overflow-hidden px-1.5 py-1 md:px-3">
              {name}
            </div>
            <div className="flex p-1 px-1.5 md:p-3 md:px-3">
              {applyRating(rating, star)}
            </div>
          </div>
        </div>
        <div>
          <img src={logoList[platformIcon].src} alt="media logo" />
        </div>
      </div>
      <div className="flex flex-col">
        {title ? (
          <div className="font-montserrat text-lg text-blue7 mb-3.5">
            {title}
          </div>
        ) : null}
        {content && (
          <article
            className="prose-headings:font-montserrat prose-h4:text-lg prose-h4:text-blue7 prose-h4:font-normal prose-p:text-[16px] prose-p:m-0"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </div>
    </div>
  )
}

export default PillarSingleReviewCard
