import { createUseStyles } from "react-jss"
import { SbBlokData } from "@storyblok/react"

import TrustPilotReviewsIcon from "../../../components/svgs/TrustPilotReviews"
import { mediaQueries } from "../../../styles/breakpoints"
import { useReviewsContext } from "../../../context/ReviewsContext"

const useStyles = createUseStyles(
  {
    trustpilot: {
      margin: "0 auto 40px",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      [mediaQueries.tablet]: {
        flexDirection: "row",
      },
    },
  },
  { link: true },
)
export interface IPillarsTrustPilot extends SbBlokData {
  reviews: string
}
const PillarsSubReview = ({ blok }: { blok: IPillarsTrustPilot }) => {
  const { reviews } = blok
  const { blokReviews } = useReviewsContext()
  const classes = useStyles()

  return (
    <section className={classes.trustpilot}>
      <TrustPilotReviewsIcon />
      Based on {blokReviews?.trustpilotReviews ?? reviews} reviews
    </section>
  )
}

export default PillarsSubReview
